import { forwardRef, ReactNode } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDButton
import MDButtonRoot from "../../components/MDButton/MDButtonRoot";

//  contexts
import { useMaterialUIController } from "../../context";
import { useTheme } from "@mui/material";
import { CustomTheme } from "../../assets/theme/types/CustomTheme";

// Define prop types for the MDButton component
interface MDButtonProps {
  color?: 'white' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'light' | 'dark';
  variant?: 'text' | 'contained' | 'outlined' | 'gradient';
  size?: 'small' | 'medium' | 'large';
  circular?: boolean;
  iconOnly?: boolean;
  children: ReactNode;
  [key: string]: any; // For rest props
}

const MDButton = forwardRef<HTMLButtonElement, MDButtonProps>(
  ({ color = "white", variant = "contained", size = "medium", circular = false, iconOnly = false, children, ...rest }, ref) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const theme = useTheme() as CustomTheme;

    return (
      <MDButtonRoot
        {...rest}
        ref={ref}
        theme={theme}
        color="primary"
        variant={variant === "gradient" ? "contained" : variant}
        size={size}
        ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
      >
        {children}
      </MDButtonRoot>
    );
  }
);

// Setting default display name
MDButton.displayName = "MDButton";

// Typechecking props for the MDButton
// Note: Using PropTypes for runtime checking in addition to TypeScript for compatibility
MDButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  color: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default MDButton;