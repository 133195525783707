import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MDTypography from "../../../components/MDTypography";
import { Comment } from "../types";
import CommentForm from "./CommentForm";
import CommentItem from "./CommentItem";
import { getTotalCommentCount } from "../utils/commentHelpers";
import { Icon } from "@mui/material";

interface CommentsSectionProps {
    comments: Comment[];
    sortOrder: string;
    onSortChange: (newSortOrder: string) => void;
    newComment: string;
    setNewComment: (comment: string) => void;
    handleAddComment: () => Promise<void>;
    handleReply: (parentCommentId: string, content: string) => Promise<void>;
    commenting: boolean;
    darkMode: boolean;
}

/**
 * Component for the entire comments section including form and list
 */
const CommentsSection: React.FC<CommentsSectionProps> = ({
    comments,
    sortOrder,
    onSortChange,
    newComment,
    setNewComment,
    handleAddComment,
    handleReply,
    commenting,
    darkMode,
}) => {
    const handleSortOrderChange = (event: SelectChangeEvent) => {
        onSortChange(event.target.value);
    };

    return (
        <Box>
            <Box display="flex" alignItems="center" gap={2} mb={3}>
                <MDTypography variant="h5">Yorumlar</MDTypography>
                <MDTypography variant="body2" color="text">
                    ({comments.length} yorum, {getTotalCommentCount(comments)} toplam yanıt)
                </MDTypography>
            </Box>

            {/* Comment Form */}
            <CommentForm
                newComment={newComment}
                setNewComment={setNewComment}
                handleAddComment={handleAddComment}
                commenting={commenting}
            />

            {/* Sort Options */}
            <Box display="flex" justifyContent="flex-end" mb={2}>
                <FormControl size="small" sx={{ minWidth: 200 }}>
                    <InputLabel>Yorumları Sırala</InputLabel>
                    <Select
                        value={sortOrder}
                        onChange={handleSortOrderChange}
                        label="Yorumları Sırala"
                        MenuProps={{
                            disableScrollLock: true,
                        }}
                        sx={{
                            height: "36px",
                            "& .MuiSelect-select": {
                                paddingTop: "5px",
                                paddingBottom: "5px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            },
                        }}
                    >
                        <MenuItem value="newest">
                            <Box display="flex" alignItems="center" gap={1}>
                                <Icon>update</Icon>
                                <MDTypography variant="button">En Yeni</MDTypography>
                            </Box>
                        </MenuItem>
                        <MenuItem value="oldest">
                            <Box display="flex" alignItems="center" gap={1}>
                                <Icon>history</Icon>
                                <MDTypography variant="button">En Eski</MDTypography>
                            </Box>
                        </MenuItem>
                    </Select>
                </FormControl>
            </Box>

            {/* Comments List */}
            <Box>
                {comments.map((comment) => (
                    <CommentItem
                        key={comment.id}
                        comment={comment}
                        onReply={handleReply}
                        darkMode={darkMode}
                    />
                ))}

                {/* No comments message */}
                {comments.length === 0 && (
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gap={2}
                        py={4}
                    >
                        <MDTypography variant="body1" textAlign="center">
                            Henüz yorum yapılmamış.
                        </MDTypography>
                        <MDTypography variant="caption" color="text" textAlign="center">
                            İlk yorumu siz yapın ve tartışmayı başlatın!
                        </MDTypography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default CommentsSection;