// @mui material components
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import { CustomTheme } from "../../assets/theme/types/CustomTheme"; // Dosya yolunu projenize göre ayarlayın

interface OwnerState {
  shadow: "none" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "inset";
  bgcolor: "transparent" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
  size: "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
}

// MUI styled API'yi doğru şekilde kullanmak için tipi düzenliyoruz
export default styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'ownerState'
})<{ ownerState: OwnerState }>(({ theme, ownerState }) => {
  // Custom theme tipini kullanıyoruz
  const customTheme = theme as unknown as CustomTheme;
  const { palette, functions, typography, boxShadows } = customTheme;
  const { shadow, bgcolor, size } = ownerState;

  const { gradients, transparent, white } = palette;
  const { pxToRem, linearGradient } = functions;
  const { size: fontSize, fontWeightRegular } = typography;

  // backgroundImage value
  const backgroundValue =
    bgcolor === "transparent"
      ? transparent.main
      : linearGradient(gradients[bgcolor].main, gradients[bgcolor].state);

  // size value
  let sizeValue;

  switch (size) {
    case "xs":
      sizeValue = {
        width: pxToRem(24),
        height: pxToRem(24),
        fontSize: fontSize.xs,
      };
      break;
    case "sm":
      sizeValue = {
        width: pxToRem(36),
        height: pxToRem(36),
        fontSize: fontSize.sm,
      };
      break;
    case "lg":
      sizeValue = {
        width: pxToRem(58),
        height: pxToRem(58),
        fontSize: fontSize.sm,
      };
      break;
    case "xl":
      sizeValue = {
        width: pxToRem(74),
        height: pxToRem(74),
        fontSize: fontSize.md,
      };
      break;
    case "xxl":
      sizeValue = {
        width: pxToRem(110),
        height: pxToRem(110),
        fontSize: fontSize.md,
      };
      break;
    default: {
      sizeValue = {
        width: pxToRem(48),
        height: pxToRem(48),
        fontSize: fontSize.md,
      };
    }
  }

  return {
    background: backgroundValue,
    color: white.main,
    fontWeight: fontWeightRegular,
    boxShadow: boxShadows[shadow],
    ...sizeValue,
  };
});