// src/store/store.tsx
import { configureStore } from "@reduxjs/toolkit";
import { postApi } from "../services/postApiService";
import { pageApi } from "../services/pageApiService";
import { userApi } from "../services/userApiService";

export const store = configureStore({
    reducer: {
        [postApi.reducerPath]: postApi.reducer,
        [pageApi.reducerPath]: pageApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware()
            .concat(postApi.middleware)
            .concat(pageApi.middleware)
            .concat(userApi.middleware),
});

// RootState ve AppDispatch tipleri
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;