import { TextField } from "@mui/material";
import { useMaterialUIController } from "../../../context";
import { darkModeStyles } from "./styles/darkModeStyles";

interface MDTextFieldForDarkModeProps {
  [key: string]: any;
}

const MDTextFieldForDarkMode = ({ ...props }: MDTextFieldForDarkModeProps) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <TextField
      {...props}
      sx={{
        ...darkModeStyles(darkMode),
        ...(props.sx || {}),
      }}
    />
  );
};

export default MDTextFieldForDarkMode;
