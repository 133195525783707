import { useEffect, ReactNode } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// components
import { Box } from "@mui/material";

// context
import { useMaterialUIController, setLayout } from "../../../context";

// Define the type for the props
interface PageLayoutProps {
  background?: "white" | "light" | "default";
  children: ReactNode;
}

function PageLayout({ background = "default", children }: PageLayoutProps) {
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname, dispatch]);

  return (
    <Box
      width="100vw"
      height="100%"
      minHeight="100vh"
      bgcolor={background}
      sx={{ overflowX: "hidden" }}
    >
      {children}
    </Box>
  );
}

export default PageLayout;