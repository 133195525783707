import React from "react";
import Box from "@mui/material/Box";
import MDTypography from "../../../components/MDTypography";
import MDAvatar from "../../../components/MDAvatar";
import MDSocialShare from "../../../components/MDSocialShare";
import { Post, Author } from "../types";

interface PostHeaderProps {
    post: Post;
    author: Author | null;
    fullUrl: string;
}

/**
 * Component for the post header including title, author info and social share
 */
const PostHeader: React.FC<PostHeaderProps> = ({ post, author, fullUrl }) => {
    const formatDate = (timestamp: any) => {
        if (!timestamp) return "Tarih belirtilmemiş";

        const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
        return date.toLocaleDateString("tr-TR");
    };

    return (
        <Box>
            {/* Title */}
            <MDTypography variant="h4" component="h1" gutterBottom>
                {post.title}
            </MDTypography>

            {/* Author Info */}
            <Box
                display="flex"
                alignItems="center"
                mb={2}
                sx={{
                    borderRadius: "8px",
                    backgroundColor: (theme) =>
                        theme.palette.mode === "dark"
                            ? "rgba(0, 0, 0, 0.2)"
                            : "#f5f5f5",
                    p: 1,
                }}
            >
                <MDAvatar
                    src={author?.avatarUrl}
                    alt={author?.fullName || post.authorName || ""}
                    sx={{ width: 40, height: 40, mr: 2 }}
                />
                <Box display="flex" alignItems="center">
                    <MDTypography variant="subtitle2" fontWeight="medium">
                        Yazar: {author?.fullName || post.authorName || "Anonim"}
                    </MDTypography>

                    {author?.role && (
                        <MDTypography
                            variant="caption"
                            sx={{
                                textTransform: "capitalize",
                                color: "primary",
                                marginLeft: 0.5,
                                marginTop: 0.2,
                            }}
                        >
                            ({author.role})
                        </MDTypography>
                    )}
                </Box>
            </Box>

            {/* Date */}
            <MDTypography variant="subtitle2" gutterBottom>
                {formatDate(post.publishedAt || post.createdAt)}
            </MDTypography>

            {/* Share Buttons */}
            <Box display="flex" alignItems="center" my={1}>
                <MDTypography variant="subtitle2" pt={0.8} gutterBottom>
                    Paylaş:
                </MDTypography>
                <MDSocialShare url={fullUrl} title={post.title} />
            </Box>
        </Box>
    );
};

export default PostHeader;