import React from "react";
import GoogleIcon from "@mui/icons-material/Google";
import MDButton from "../../../../components/MDButton";

interface GoogleAuthButtonProps {
    onGoogleSignIn: () => Promise<void>;
}

/**
 * Button component for Google Authentication
 */
const GoogleAuthButton: React.FC<GoogleAuthButtonProps> = ({ onGoogleSignIn }) => {
    return (
        <MDButton
            variant="contained"
            color="error"
            fullWidth
            startIcon={<GoogleIcon />}
            onClick={onGoogleSignIn}
        >
            Google ile Devam Et
        </MDButton>
    );
};

export default GoogleAuthButton;