import Grid from "@mui/material/Grid";
import Carousel from "react-material-ui-carousel";
import { Box } from "@mui/material";
import MDLoadingSkeleton from "../../components/MDLoadingSkeleton";
import DefaultProjectCard from "../../examples/Cards/ProjectCards/DefaultProjectCard";
import MDTypography from "../../components/MDTypography";
import { useNavigate } from "react-router-dom";
import MDAlert from "../../components/MDAlert";
import { useState } from 'react';
import MDPagination from "../../components/MDPagination";
import MDBox from "../../components/MDBox";
import { useThemeSettings } from "../../hooks/useThemeSettings";
import MDErrorDisplay from "../../components/MDErrorDisplay";
import { Post, useGetPostsQuery } from "../../services/postApiService";
import { useGetFeaturedPostsQuery } from "../../services/postApiService";
import { PostWithAuthor } from "../../services/postApiService";
import { formatDateFromDate } from "../../utils";

// Props tipi tanımı
export interface AnaSayfaLayoutProps {
  transparent?: boolean;
  light?: boolean;
}

/**
 * Ana sayfa layout'unu oluşturur.
 *  - Giriş yapmamışsa loading skeleton'lar gösterir.
 *  - Giriş yapmışsa:
 *    - Öne çıkan post'ları bir carusel'da gösterir.
 *    - Son post'ları 12'si bir sayfada gösterir.
 *    - Sayfalama için pagination bileşenini gösterir.
 *  - Footer bileseninde şirket bilgisi ve link'ler bulunur.
 * @param {AnaSayfaLayoutProps} props
 * @returns {JSX.Element} Ana sayfa layout'u.
 */
function AnaSayfaLayout({ transparent = false, light = false }: AnaSayfaLayoutProps): JSX.Element {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const POSTS_PER_PAGE = useThemeSettings().themeSettings?.postPerPage ?? 16; // Her sayfada gösterilecek post sayısı

  // Define a more specific error type
  interface ApiError {
    message?: string;
    status?: number;
    data?: any;
  }


  const {
    data: posts = [] as Post[],
    isLoading: postsLoading,
    error: postsError,
  } = useGetPostsQuery() as {
    data: Post[];
    isLoading: boolean;
    error: ApiError | undefined;
  };

  const {
    data: featuredPosts = [] as Post[],
    isLoading: featuredLoading,
    error: featuredError,
  } = useGetFeaturedPostsQuery() as {
    data: Post[];
    isLoading: boolean;
    error: ApiError | undefined;
  };;

  const navigate = useNavigate();

  if (postsLoading || featuredLoading) {
    return (
      <Box px={{ xs: 1, sm: transparent ? 0 : 1 }} my={1} mx={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box height="400px">
              <MDLoadingSkeleton />
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }

  const error = postsError || featuredError;
  if (error) {
    return (
      <Box px={{ xs: 1, sm: transparent ? 0 : 1 }} my={1} mx={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box height="400px">
              <MDAlert color="error">
                {error.message || "Bir hata oluştu"}
              </MDAlert>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }

  const renderCarousel = (): JSX.Element | null => {
    if (!featuredPosts || featuredPosts.length === 0) {
      return null;
    }

    return (
      <Box mb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ borderRadius: "15px", overflow: "hidden" }}>
              <Carousel
                animation="slide"
                autoPlay={true}
                indicators={true}
                navButtonsAlwaysVisible={true}
                cycleNavigation={true}
                interval={5000}
              >
                {featuredPosts.map((post: Post) => (
                  <MDBox
                    key={post.id}
                    sx={{
                      cursor: "pointer",
                      position: "relative",
                      height: { xs: "300px", sm: "350px", md: "400px" },
                      width: "100%",
                      borderRadius: "lg",
                      overflow: "hidden",
                    }}
                    onClick={() => navigate(`/post/${post.slug}`)}
                  >
                    <img
                      src={post.featuredImageUrl}
                      alt={post.title}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: 2,
                        background: "rgba(0,0,0,0.5)",
                        color: "white",
                      }}
                    >
                      <MDTypography variant="h5" color="white">
                        {post.title}
                      </MDTypography>
                      <MDTypography variant="body2" color="white">
                        {post.excerpt}
                      </MDTypography>
                    </Box>
                  </MDBox>
                ))}
              </Carousel>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderPosts = (): JSX.Element[] | null => {
    if (!posts) return null;

    // Sayfalama için post'ları böl
    const indexOfLastPost: number = currentPage * POSTS_PER_PAGE;
    const indexOfFirstPost: number = indexOfLastPost - POSTS_PER_PAGE;
    const currentPosts: PostWithAuthor[] | Post[] = posts.slice(indexOfFirstPost, indexOfLastPost);

    return currentPosts.map((post: Post) => (
      <Grid item key={post.id} xs={12} md={6} lg={4} xl={3}>
        <DefaultProjectCard
          image={
            post.featuredImageUrl ||
            "https://images.unsplash.com/photo-1497294815431-9365093b7371?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80"
          }
          label={post.categoryName || "Genel"}
          title={post.title}
          description={post.excerpt ? post.excerpt : ""}
          action={{
            route: `/post/${post.slug}`,
            label: "detay",
            color: "info",
            type: "internal",
          }}
          author={{
            avatar: post.authorAvatar,
            name: post.authorName,
          }}
          date={post.publishedAt ? formatDateFromDate(post.publishedAt) : formatDateFromDate(new Date())}
        />
      </Grid>
    ));
  };

  const renderPagination = (): JSX.Element => {
    if (!posts) return <MDErrorDisplay message="Bir hata oluştu" ></MDErrorDisplay>;

    const totalPages: number = Math.ceil(posts.length / POSTS_PER_PAGE);

    return (
      <Box mt={4} display="flex" justifyContent="center">
        <MDPagination>
          {/* İlk sayfa butonu */}
          <MDPagination item
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}>
            <span>&laquo;</span>
          </MDPagination>

          {/* Önceki sayfa butonu */}
          <MDPagination item
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}>
            <span>&lsaquo;</span>
          </MDPagination>

          {/* Sayfa numaraları */}
          {[...Array(totalPages)].map((_, index) => (
            <MDPagination item
              key={index + 1}
              onClick={() => setCurrentPage(index + 1)}
              active={currentPage === index + 1}>
              {index + 1}
            </MDPagination>
          ))}

          {/* Sonraki sayfa butonu */}
          <MDPagination item
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}>
            <span>&rsaquo;</span>
          </MDPagination>

          {/* Son sayfa butonu */}
          <MDPagination item
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}>
            <span>&raquo;</span>
          </MDPagination>
        </MDPagination>
      </Box>
    );
  };

  return (
    <Box
      px={{
        xs: 1,
        sm: transparent ? 0 : 1,
        md: transparent ? 0 : 1,
        xl: transparent ? 0 : 1,
        lg: transparent ? 0 : 1,
      }}
      my={1}
      mx={2}
    >
      {renderCarousel()}
      <Box mb={3}>
        <Grid container spacing={2}>
          {renderPosts()}
        </Grid>
        {renderPagination()}
      </Box>
    </Box>
  );
}

export default AnaSayfaLayout;