import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../../lib/firebase";
import { getRedirectResult } from "firebase/auth";

// Material UI Imports
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/material";

// Custom Components
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDBox from "../../../components/MDBox";
import BasicLayout from "../../../layouts/authenticationLayouts/components/BasicLayout";
import GoogleAuthButton from "./components/GoogleAuthButton";
import EmailVerification from "./components/EmailVerification";
import SocialMediaLinks from "./components/SocialMediaLinks";
import PasswordResetDialog from "./components/PasswordResetDialog";

// Hooks
import { useAuth } from "../../../hooks/useAuth";

// Assets
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
/**
 * LoginPage component that provides user interface for authentication
 */
function LoginPage(): JSX.Element {
    // State management
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [resetEmail, setResetEmail] = useState<string>("");
    const [resetDialogOpen, setResetDialogOpen] = useState<boolean>(false);

    // Custom auth hook
    const {
        error,
        setError,
        verificationSent,
        showResendButton,
        resetSuccess,
        resetError,
        loginWithEmail,
        loginWithGoogle,
        resendVerificationEmail,
        resetPassword,
        navigate,
    } = useAuth();

    const handleSetRememberMe = (): void => setRememberMe(!rememberMe);

    // Handle redirect result from Google auth
    useEffect(() => {
        const checkRedirectResult = async (): Promise<void> => {
            try {
                const result = await getRedirectResult(auth);
                if (result?.user) {
                    navigate("/");
                }
            } catch (error) {
                console.error("Redirect result error:", error);
                setError("Google ile giriş yapılırken bir hata oluştu.");
            }
        };

        checkRedirectResult();
    }, [navigate, setError]);

    // Handle form submission
    const handleLogin = async (e: React.FormEvent): Promise<void> => {
        e.preventDefault();
        const success = await loginWithEmail(email, password);
        if (success) {
            navigate("/");
        }
    };

    // Handle Google sign-in
    const handleGoogleSignIn = async (): Promise<void> => {
        const success = await loginWithGoogle();
        if (success) {
            navigate("/");
        }
    };

    // Handle verification email resend
    const handleResendVerification = async (): Promise<void> => {
        await resendVerificationEmail(email, password);
    };

    // Handle password reset
    const handlePasswordReset = async (): Promise<void> => {
        const success = await resetPassword(resetEmail);
        if (success) {
            // Auto-close after 3 seconds on success
            setTimeout(() => {
                setResetDialogOpen(false);
                setResetEmail("");
            }, 3000);
        }
    };

    // Close password reset dialog
    const handleCloseDialog = (): void => {
        setResetDialogOpen(false);
        setResetEmail("");
    };

    return (
        <BasicLayout image={bgImage}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgcolor="info"
                    borderRadius="lg"
                    coloredshadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Gkhnmr.com Blog
                    </MDTypography>
                    <SocialMediaLinks />
                </MDBox>

                <Box pt={1} pb={1} px={3}>
                    <Box>
                        <GoogleAuthButton onGoogleSignIn={handleGoogleSignIn} />
                    </Box>

                    <Box mt={1} mb={1} textAlign="center">
                        <MDTypography variant="button" color="text">
                            veya e-posta ile giriş yap
                        </MDTypography>
                    </Box>

                    <Divider />

                    <Box component="form" role="form" onSubmit={handleLogin} mt={2}>
                        <Box mb={2}>
                            <MDInput
                                type="email"
                                label="E-Posta"
                                fullWidth
                                value={email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                            />
                        </Box>

                        <Box mb={2}>
                            <MDInput
                                type="password"
                                label="Şifre"
                                fullWidth
                                value={password}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                            />
                        </Box>

                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Box display="flex" alignItems="center">
                                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                                <MDTypography
                                    variant="button"
                                    fontWeight="regular"
                                    color="text"
                                    onClick={handleSetRememberMe}
                                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                                >
                                    &nbsp;&nbsp;Beni Hatırla
                                </MDTypography>
                            </Box>

                            <MDTypography
                                variant="button"
                                color="info"
                                fontWeight="medium"
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                    setResetDialogOpen(true);
                                    setResetEmail(email);
                                }}
                            >
                                Şifremi Unuttum
                            </MDTypography>

                            <PasswordResetDialog
                                open={resetDialogOpen}
                                handleClose={handleCloseDialog}
                                resetEmail={resetEmail}
                                setResetEmail={setResetEmail}
                                handlePasswordReset={handlePasswordReset}
                                resetError={resetError || ""}
                                resetSuccess={resetSuccess}
                            />
                        </Box>

                        <EmailVerification
                            error={error}
                            showResendButton={showResendButton}
                            verificationSent={verificationSent}
                            onResendVerification={handleResendVerification}
                        />

                        <Box mt={4} mb={1}>
                            <MDButton type="submit" variant="gradient" color="info" fullWidth>
                                Giriş Yap
                            </MDButton>
                        </Box>

                        <Box textAlign="center">
                            <MDTypography variant="button" color="text">
                                Bir hesabın yok mu?{" "}
                                <MDTypography
                                    component={Link}
                                    to="/auth/kaydol"
                                    variant="button"
                                    color="info"
                                    fontWeight="medium"
                                    textGradient
                                >
                                    Kaydol
                                </MDTypography>
                            </MDTypography>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </BasicLayout>
    );
}

export default LoginPage;