// base styles
import typography from "../../../../assets/theme/base/typography";

// helper functions
import pxToRem from "../../../../assets/theme/functions/pxToRem";

const { size } = typography;

// TypeScript için interface tanımı
interface DialogTitleStyleOverrides {
  root: {
    padding: string;
    fontSize: string | number;
  };
}

interface DialogTitleStyles {
  styleOverrides: DialogTitleStyleOverrides;
}

const dialogTitle: DialogTitleStyles = {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
      fontSize: size.xl,
    },
  },
};

export default dialogTitle;