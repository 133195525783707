//  Base Styles
import colors from "../../../assets/theme/base/colors";

const { info, dark } = colors;

interface GlobalsType {
  html: {
    scrollBehavior: string;
  };
  "*, *::before, *::after": {
    margin: number;
    padding: number;
  };
  "a, a:link, a:visited": {
    textDecoration: string;
  };
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: string;
    transition: string;
  };
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: string;
  };
  [key: string]: any;
}

const globals: GlobalsType = {
  html: {
    scrollBehavior: "smooth",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
};

export default globals;