//  helper functions
import pxToRem from "../../../../assets/theme/functions/pxToRem";

interface StepStyleOverrides {
  root: {
    padding: string;
  };
}

const step: {
  styleOverrides: StepStyleOverrides;
} = {
  styleOverrides: {
    root: {
      padding: `0 ${pxToRem(6)}`,
    },
  },
};

export default step;