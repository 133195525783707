//  base styles
import colors from "../../../../assets/theme/base/colors";

//  helper functions
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import boxShadow from "../../../../assets/theme/functions/boxShadow";

interface StepIconStyleOverrides {
  root: {
    background: string;
    fill: string;
    stroke: string;
    strokeWidth: string;
    width: string;
    height: string;
    borderRadius: string;
    zIndex: number;
    transition: string;
    "&.Mui-active": {
      background: string;
      fill: string;
      stroke: string;
      borderColor: string;
      boxShadow: string;
    };
    "&.Mui-completed": {
      background: string;
      fill: string;
      stroke: string;
      borderColor: string;
      boxShadow: string;
    };
  };
}

const { white } = colors;

const stepIcon: {
  styleOverrides: StepIconStyleOverrides;
} = {
  styleOverrides: {
    root: {
      background: "#9fc9ff",
      fill: "#9fc9ff",
      stroke: "#9fc9ff",
      strokeWidth: pxToRem(10),
      width: pxToRem(13),
      height: pxToRem(13),
      borderRadius: "50%",
      zIndex: 99,
      transition: "all 200ms linear",

      "&.Mui-active": {
        background: white.main,
        fill: white.main,
        stroke: white.main,
        borderColor: white.main,
        boxShadow: boxShadow([0, 0], [0, 2], white.main, 1),
      },

      "&.Mui-completed": {
        background: white.main,
        fill: white.main,
        stroke: white.main,
        borderColor: white.main,
        boxShadow: boxShadow([0, 0], [0, 2], white.main, 1),
      },
    },
  },
};

export default stepIcon;