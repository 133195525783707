import { AnaSayfaLayoutProps } from "../../layouts/AnaSayfaLayout";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";
import MDErrorDisplay from "../../components/MDErrorDisplay";

const UserSettingsLayout: React.FC<AnaSayfaLayoutProps> = ({ transparent = false, light = false }) => {


    return (
        <MDBox
            px={{
                xs: 1,
                sm: transparent ? 0 : 1,
                md: transparent ? 0 : 1,
                xl: transparent ? 0 : 1,
                lg: transparent ? 0 : 1,
            }}
            my={1}
            mx={2}
        >
            <MDTypography variant="h3" fontWeight="medium">
                <MDErrorDisplay message="Bu sayfa şu anda yapım aşamasında!" />
            </MDTypography>
        </MDBox>
    );
};

export default UserSettingsLayout;
