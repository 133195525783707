import './App.css';
import useSessionManager from './hooks/useSessionManager';
import { useFirestoreRoutes } from './hooks/useFirestoreRoutes';
import { setOpenConfigurator, useMaterialUIController } from './context';
import { useAuth } from './AuthContext';
import { useLoading } from './context/LoadingContext';
import themeDark from './assets/theme-dark';
import theme from './assets/theme';
import { lazy, Suspense, useEffect, useMemo, useRef, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Icon from '@mui/material/Icon';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MySEOHelmet from './utils/components/MySEOHelmet';
import CookieConsent from './layouts/AnaSayfaLayout/components/CookieConsent';
import { HelmetProvider } from 'react-helmet-async';
import MDBox from './components/MDBox';
import Cookies from 'js-cookie';
import { RouteItem, RouteType, SidenavProps } from './types';
import MDLoadingScreen from './components/MDLoadingScreen';
import StaticPage from './layouts/StaticPage';
import KaydolLayout from './layouts/authenticationLayouts/kaydolLayout';
import LoginPage from './layouts/authenticationLayouts/LoginPage';
import Configurator from './examples/Configurator';
import { Grid } from '@mui/material';
import MDErrorDisplay from './components/MDErrorDisplay';
import MDProtectedRoute from './components/MDProtectedRoute';
import ProfileEdit from './admin/layout/sidebarLayouts/ProfilLayout/components/ProfileEdit';
import UserSettingsLayout from './layouts/UserSettingsLayout';

// Lazy loaded components
const AnaSayfaLayout = lazy(() => import("./layouts/AnaSayfaLayout"));
const MainLayout = lazy(() => import("./layouts/MainLayout"));
const AdminBaseLayout = lazy(() => import("./admin/layout/AdminBaseLayout"));

// Constants
const SESSION_TIMEOUT = 30 * 60 * 1000; // 30 minutes

// 404 component - display error instead of direct redirection
function NotFound() {
  return <MDBox px={1} my={1} mx={2}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MDErrorDisplay />
      </Grid>
    </Grid>
  </MDBox>;
}

// SuspenseWithLoading component
const SuspenseWithLoading = ({ children }: { children: React.ReactNode }) => {
  const { startLoading, stopLoading } = useLoading();
  const suspenseRef = useRef<HTMLDivElement>(null);
  const loadingId = useRef('suspense:loading:' + Math.random().toString(36).substr(2, 9));

  useEffect(() => {
    // Start loading
    const currentLoadingId = loadingId.current;
    startLoading(currentLoadingId, 'ui');

    // Function to check if content is loaded
    const checkIfContentLoaded = () => {
      if (suspenseRef.current && suspenseRef.current.childElementCount > 0) {
        // Content exists, stop loading
        return true;
      }
      return false;
    };

    // Check initially (content might be loaded immediately in some cases)
    if (checkIfContentLoaded()) {
      stopLoading(loadingId.current);
      return;
    }

    // Use MutationObserver to monitor DOM changes
    const observer = new MutationObserver((mutations) => {
      if (checkIfContentLoaded()) {
        stopLoading(loadingId.current);
        observer.disconnect();
      }
    });

    if (suspenseRef.current) {
      observer.observe(suspenseRef.current, {
        childList: true,
        subtree: true
      });
    }

    return () => {
      stopLoading(currentLoadingId);
      observer.disconnect();
    };
  }, [startLoading, stopLoading]);

  return (
    <div ref={suspenseRef}>
      <Suspense fallback={null}>{children}</Suspense>
    </div>
  );
};

function AppContent() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    direction,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user, loading: authLoading } = useAuth();
  const [showCookieConsent, setShowCookieConsent] = useState(true);
  const { routes: dynamicRoutes, categoryRoutes: dynamicCategoryRoutes, loading: routesLoading, error } = useFirestoreRoutes();
  const { startLoading, stopLoading, isLoading } = useLoading();
  const [initialAuthCheckComplete, setInitialAuthCheckComplete] = useState(false);
  const isAdminPanel = pathname.startsWith("/admin");
  const isAuthPage = pathname.startsWith("/auth");

  // Initial auth check
  useEffect(() => {
    if (!authLoading) {
      setInitialAuthCheckComplete(true);
    }
  }, [authLoading]);

  // Admin page session control
  useEffect(() => {
    // Check when auth loading is complete and in admin panel
    if (initialAuthCheckComplete && isAdminPanel) {
      // Redirect to login page if not logged in or email not verified
      if (!user || !user.emailVerified) {
        // Save admin page in session storage
        sessionStorage.setItem('redirectAfterLogin', pathname);
        navigate('/auth/giris-yap', { replace: true });
      }
    }

    // If on login page and user is logged in, redirect to previous admin page or home
    if (initialAuthCheckComplete && !authLoading && user && isAuthPage) {
      const redirectPath = sessionStorage.getItem('redirectAfterLogin') || '/';
      navigate(redirectPath, { replace: true });
      sessionStorage.removeItem('redirectAfterLogin');
    }
  }, [initialAuthCheckComplete, isAdminPanel, user, authLoading, pathname, navigate, isAuthPage]);

  // Manage loading state when Firestore routes are loading
  useEffect(() => {
    const routesLoadingId = 'routes:firebase';

    if (routesLoading) {
      startLoading(routesLoadingId, 'data');
    } else {
      stopLoading(routesLoadingId);
    }

    return () => {
      stopLoading(routesLoadingId);
    };
  }, [routesLoading, startLoading, stopLoading]);

  useEffect(() => {
    // Check cookie consent after main content is loaded
    const timer = setTimeout(() => {
      const cookieConsentAccepted = Cookies.get('cookieConsentAccepted');
      if (cookieConsentAccepted !== 'true') {
        setShowCookieConsent(true);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  // Cookie acceptance handling
  const handleCookieAccept = () => {
    // Mark cookie as accepted (valid for 1 year)
    Cookies.set('cookieConsentAccepted', 'true', { expires: 365 });

    // Close banner
    setShowCookieConsent(false);
  };

  // Cookie rejection handling
  const handleCookieReject = () => {
    // Mark cookie as rejected (valid for 1 year)
    Cookies.set('cookieConsentAccepted', 'false', { expires: 365 });

    // Close banner
    setShowCookieConsent(false);
  };

  useSessionManager(SESSION_TIMEOUT);

  const processedCategoryRoutes = useMemo(() => {
    if (!dynamicCategoryRoutes?.length) {
      return [
        { path: "/", element: <AnaSayfaLayout />, key: "home" },
      ];
    }
    return dynamicCategoryRoutes
      .map((route: RouteItem) => ({
        path: route.route,
        element: route.component,
        key: route.key,
      }))
      .filter(Boolean);
  }, [dynamicCategoryRoutes]);

  const processedRoutes = useMemo(() => {
    if (!dynamicRoutes?.length) {
      return [
        { path: "/", element: <AnaSayfaLayout />, key: "home" },
      ];
    }
    return dynamicRoutes
      .map((route: RouteItem) => ({
        path: route.route,
        element: route.component,
        key: route.key,
      }))
      .filter(Boolean);
  }, [dynamicRoutes]);

  // Convert RouteItem[] to RouteType[] for SidenavProps
  const sidenavRoutes: RouteType[] = useMemo(() => {
    if (!dynamicRoutes?.length) return [];

    return (dynamicRoutes || []).map(route => {
      // Create a new object that conforms to RouteType
      const sidenavRoute: RouteType = {
        ...route,
        // Make sure type is correct
        type: (route.type as "collapse" | "title" | "divider") || "collapse"
      };
      return sidenavRoute;
    });
  }, [dynamicRoutes]);

  const sidenavProps: SidenavProps = useMemo(
    () => ({
      color: sidenavColor as "error" | "info" | "primary" | "secondary" | "success" | "warning" | "dark" | undefined,
      brand:
        (transparentSidenav && !darkMode) || whiteSidenav
          ? "/images/logo-dark.png" // Moved to public folder
          : "/images/logo-white.png", // Moved to public folder
      brandName: "Kategoriler",
      routes: sidenavRoutes,
      loading: routesLoading,
    }),
    [sidenavColor, transparentSidenav, darkMode, whiteSidenav, sidenavRoutes, routesLoading]
  );

  const configsButton = useMemo(
    () => (
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="3.25rem"
        height="3.25rem"
        borderRadius="50%"
        position="fixed"
        right="2rem"
        bottom="2rem"
        zIndex={99}
        color="dark"
        sx={{ cursor: "pointer" }}
        onClick={() => setOpenConfigurator(dispatch, !openConfigurator)}
      >
        <Icon fontSize="small" color="inherit">
          settings
        </Icon>
      </MDBox>
    ),
    [openConfigurator, dispatch]
  );

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (error) {
    console.error("Error loading routes:", error);
    return <div>Error loading navigation menu</div>;
  }

  // General loading state
  const isPageLoading = authLoading || routesLoading || isLoading || !initialAuthCheckComplete;

  // Show loading if auth loading is not complete or auth check is pending in admin panel
  if (isPageLoading && (isAdminPanel || !initialAuthCheckComplete)) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <MDLoadingScreen />
        </div>
      </ThemeProvider>
    );
  }

  return (
    <>
      <HelmetProvider>
        <MySEOHelmet pathname={pathname} />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          style={{ zIndex: 9999 }}
        />
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          {/* Show cookie consent if needed */}
          {showCookieConsent && (
            <CookieConsent
              onAccept={handleCookieAccept}
              onReject={handleCookieReject}
            />
          )}
          <CssBaseline />

          {/* Blurred loading overlay - no longer used at this point for auth */}
          {isPageLoading && !isAuthPage && !isAdminPanel && (
            <div style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backdropFilter: 'blur(5px)',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              zIndex: 9999,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <MDLoadingScreen />
            </div>
          )}

          {/* Main content */}
          <MDBox>
            <SuspenseWithLoading>
              {isAdminPanel ? (
                // Admin panel check - now that auth loading is complete, we check here
                user?.emailVerified && (user?.role === "admin" || user?.role === "editor" || user?.role === "author") ? (
                  <AdminBaseLayout />
                ) : (
                  <Navigate to="/auth/giris-yap" replace />
                )
              ) : isAuthPage ? (
                // Auth pages check
                user ? (
                  <Navigate to={sessionStorage.getItem('redirectAfterLogin') || "/"} replace />
                ) : (
                  <Routes>
                    <Route path="/auth/giris-yap" element={<LoginPage />} />
                    <Route path="/auth/kaydol" element={<KaydolLayout />} />
                    <Route
                      path="/auth/*"
                      element={<Navigate to="/" replace />}
                    />
                  </Routes>
                )
              ) : (
                // Normal site routes
                <MainLayout sidenavProps={sidenavProps}>
                  <Routes>
                    {/* IMPORTANT: Show normal loading screen while routes are loading, not redirecting to NotFound */}
                    {routesLoading ? (
                      // Preserve current URL during loading
                      <Route path="*" element={null} />
                    ) : (
                      <>
                        {/* Dynamic routes */}
                        {processedRoutes.map((route: any) => (
                          <Route
                            key={route.key}
                            path={route.path}
                            element={route.element}
                          />
                        ))}

                        {/* Dynamic Category Routes */}
                        {processedCategoryRoutes.map((route: any) => (
                          <Route
                            key={route.key}
                            path={route.path}
                            element={route.element}
                          />
                        ))}

                        <Route path="/sayfa/:slug" element={<StaticPage />} />
                        <Route path='/profile' element={<MDProtectedRoute><ProfileEdit /></MDProtectedRoute>} />
                        <Route path='/settings' element={<MDProtectedRoute><UserSettingsLayout /></MDProtectedRoute>} />
                        <Route path="/" element={<AnaSayfaLayout />} />
                        {/* Use NotFound component as the LAST in sequence without modifying it */}
                        <Route path="*" element={<NotFound />} />
                      </>
                    )}
                  </Routes>
                </MainLayout>
              )}

              {!isAuthPage && (
                <>
                  <Configurator />
                  {configsButton}
                </>
              )}
            </SuspenseWithLoading>
          </MDBox>
        </ThemeProvider>
      </HelmetProvider>
    </>
  );
}

function App() {
  return (
    <AppContent />
  );
}

export default App;