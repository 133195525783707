import React, { useState, ChangeEvent, FormEvent } from 'react';
import {
    TextField,
    CircularProgress,
    Grid,
} from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import MDBox from '../../components/MDBox';

// Form veri tipi tanımı
interface FormData {
    name: string;
    email: string;
    subject: string;
    message: string;
}

// Form hata tipi tanımı
interface FormErrors {
    name?: string;
    email?: string;
    subject?: string;
    message?: string;
}

// Form doğrulama şeması
const validationSchema = yup.object({
    name: yup
        .string()
        .required('İsim alanı zorunludur')
        .min(2, 'İsim en az 2 karakter olmalıdır'),
    email: yup
        .string()
        .email('Geçerli bir e-posta adresi giriniz')
        .required('E-posta alanı zorunludur'),
    subject: yup
        .string()
        .required('Konu alanı zorunludur')
        .min(5, 'Konu en az 5 karakter olmalıdır'),
    message: yup
        .string()
        .required('Mesaj alanı zorunludur')
        .min(10, 'Mesaj en az 10 karakter olmalıdır')
});

// reCAPTCHA tiplemeleri
// declare global {
//     interface Window {
//         grecaptcha: {
//             ready: (callback: () => void) => void;
//             execute: (
//                 siteKey: string,
//                 options: { action: string }
//             ) => Promise<string>;
//         };
//     }
// }

const IletisimLayout: React.FC = () => {
    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [errors, setErrors] = useState<FormErrors>({});
    const [loading, setLoading] = useState<boolean>(false);
    // const [recaptchaLoaded, setRecaptchaLoaded] = useState < boolean > (false);

    // reCAPTCHA'nın yüklenip yüklenmediğini kontrol et
    // useEffect(() => {
    //     // Önce mevcut scripti kontrol et
    //     const existingScript = document.querySelector(`script[src*="recaptcha"]`);
    //     if (existingScript) {
    //         document.head.removeChild(existingScript);
    //     }

    //     // Yeni script ekle
    //     const script = document.createElement('script');
    //     script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    //     script.async = true;
    //     script.defer = true;
    //     // Google botları için script'e data-purpose ekleyin
    //     script.setAttribute('data-purpose', 'security');

    //     // Script yükleme başarılı olduğunda
    //     script.onload = () => {
    //         // reCAPTCHA'nın hazır olması için bekle
    //         window.grecaptcha?.ready(() => {
    //             //console.log('reCAPTCHA hazır');
    //             setRecaptchaLoaded(true);
    //         });
    //     };

    //     // Script yükleme başarısız olduğunda
    //     script.onerror = (error: Event | string) => {
    //         console.error('reCAPTCHA script yükleme hatası:', error);
    //     };

    //     document.head.appendChild(script);

    //     return () => {
    //         // Cleanup
    //         const script = document.querySelector(`script[src*="recaptcha"]`);
    //         if (script) {
    //             document.head.removeChild(script);
    //         }
    //     };
    // }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        if (errors[name as keyof FormErrors]) {
            setErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    // Geliştirilmiş reCAPTCHA token alma fonksiyonu
    // const executeRecaptcha = async (): Promise<string> => {
    //     try {
    //         // reCAPTCHA'nın yüklendiğinden emin ol
    //         if (!window.grecaptcha) {
    //             throw new Error('reCAPTCHA yüklenmedi');
    //         }

    //         // reCAPTCHA'nın hazır olmasını bekle
    //         await new Promise < void> ((resolve) => {
    //             window.grecaptcha.ready(resolve);
    //         });

    //         const token = await window.grecaptcha.execute(
    //             process.env.REACT_APP_RECAPTCHA_SITE_KEY as string,
    //             { action: 'submit_contact_form' }
    //         );

    //         return token;
    //     } catch (error) {
    //         console.error('reCAPTCHA hatası:', error);
    //         throw new Error('Güvenlik doğrulaması başarısız oldu');
    //     }
    // };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        try {
            // Form doğrulama
            await validationSchema.validate(formData, { abortEarly: false });

            // if (!recaptchaLoaded) {
            //     throw new Error('reCAPTCHA henüz hazır değil. Lütfen birkaç saniye bekleyin.');
            // }

            // reCAPTCHA token'ı al
            // const recaptchaToken = await executeRecaptcha();

            // Firebase'e kaydet
            await addDoc(collection(db, 'contacts'), {
                ...formData,
                // recaptchaToken,
                createdAt: Timestamp.now()
            });

            toast.success('Mesajınız başarıyla gönderildi!');

            // Formu temizle
            setFormData({
                name: '',
                email: '',
                subject: '',
                message: ''
            });

        } catch (error: any) {
            if (error.inner) {
                // Yup doğrulama hataları
                const newErrors: FormErrors = {};
                error.inner.forEach((err: { path: string; message: string }) => {
                    newErrors[err.path as keyof FormErrors] = err.message;
                });
                setErrors(newErrors);
            } else {
                toast.error(`Hata: ${error.message}`);
                console.error('Form gönderme hatası:', error);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid item xs={12} px={3}>
            <MDBox my={4}>
                {/* <Paper elevation={3} sx={{ p: 4 }}> */}
                <MDTypography variant="h4" component="h1" gutterBottom align="center">
                    İletişim
                </MDTypography>

                <MDTypography variant="body1" gutterBottom align="center" sx={{ mb: 4 }}>
                    Benimle iletişime geçmek için aşağıdaki formu doldurun.
                </MDTypography>

                <form onSubmit={handleSubmit}>
                    <MDBox display="flex" flexDirection="column" gap={2}>
                        <MDBox display="flex" justifyContent="space-between">
                            <TextField
                                label="İsim"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                error={!!errors.name}
                                helperText={errors.name}
                                disabled={loading}
                                size="small"
                                sx={{ width: '48%' }}
                            />

                            <TextField
                                label="E-posta"
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                error={!!errors.email}
                                helperText={errors.email}
                                disabled={loading}
                                size="small"
                                sx={{ width: '48%' }}
                            />
                        </MDBox>

                        <TextField
                            label="Konu"
                            name="subject"
                            value={formData.subject}
                            onChange={handleChange}
                            error={!!errors.subject}
                            helperText={errors.subject}
                            disabled={loading}
                            size="small"
                            sx={{ width: '48%' }}
                        />
                        <TextField
                            fullWidth
                            label="Mesaj"
                            name="message"
                            multiline
                            rows={4}
                            value={formData.message}
                            onChange={handleChange}
                            error={!!errors.message}
                            helperText={errors.message}
                            disabled={loading}
                            size="small"
                        />

                        <MDButton
                            type="submit"
                            variant="contained"
                            color="info"
                            size="large"
                            disabled={loading}
                            endIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
                        >
                            {loading ? 'Gönderiliyor...' : 'Gönder'}
                        </MDButton>
                    </MDBox>
                </form>
                {/* </Paper> */}
            </MDBox>
            <MDBox>
                {/* <PostSearch /> */}
            </MDBox>
        </Grid>
    );
};

export default IletisimLayout;