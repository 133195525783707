//  base styles
import typography from "../../../../assets/theme/base/typography";
import colors from "../../../../assets/theme/base/colors";

//  helper functions
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import rgba from "../../../../assets/theme/functions/rgba";

interface StepLabelStyleOverrides {
  label: {
    marginTop: string;
    fontWeight: number;
    fontSize: string;
    color: string;
    textTransform: string;
    "&.Mui-active": {
      fontWeight: string;
      color: string;
    };
    "&.Mui-completed": {
      fontWeight: string;
      color: string;
    };
  };
}

const { size, fontWeightRegular } = typography;
const { white } = colors;

const stepLabel: {
  styleOverrides: StepLabelStyleOverrides;
} = {
  styleOverrides: {
    label: {
      marginTop: `${pxToRem(8)} !important`,
      fontWeight: fontWeightRegular,
      fontSize: size.xs,
      color: "#9fc9ff",
      textTransform: "uppercase",

      "&.Mui-active": {
        fontWeight: `${fontWeightRegular} !important`,
        color: `${rgba(white.main, 0.8)} !important`,
      },

      "&.Mui-completed": {
        fontWeight: `${fontWeightRegular} !important`,
        color: `${rgba(white.main, 0.8)} !important`,
      },
    },
  },
};

export default stepLabel;
