// @mui material components
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { CustomTheme } from "../../assets/theme/types/CustomTheme"; // Adjust path accordingly

interface MDBoxRootOwnerState {
  variant?: "contained" | "gradient";
  bgcolor?: string;
  color?: string;
  opacity?: number;
  borderRadius?: string;
  shadow?: string;
  coloredshadow?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "none";
}

// Create a more flexible styled props interface
interface MDBoxStyledProps {
  theme: CustomTheme;
  ownerState: MDBoxRootOwnerState;
}

export default styled(Box)<MDBoxStyledProps>(({ theme, ownerState }) => {
  const { palette, functions, borders, boxShadows } = theme as CustomTheme;
  const { variant, bgcolor, color, opacity, borderRadius, shadow, coloredshadow } = ownerState as MDBoxRootOwnerState;

  const { gradients, grey, white } = palette;
  const { linearGradient } = functions;
  const { borderRadius: radius } = borders;
  const { colored } = boxShadows;

  const greyColors: { [key: string]: string } = {
    "grey-100": grey[100],
    "grey-200": grey[200],
    "grey-300": grey[300],
    "grey-400": grey[400],
    "grey-500": grey[500],
    "grey-600": grey[600],
    "grey-700": grey[700],
    "grey-800": grey[800],
    "grey-900": grey[900],
  };

  const validGradients: string[] = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ];

  const validColors: string[] = [
    "transparent",
    "white",
    "black",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "text",
    "grey-100",
    "grey-200",
    "grey-300",
    "grey-400",
    "grey-500",
    "grey-600",
    "grey-700",
    "grey-800",
    "grey-900",
  ];

  const validBorderRadius: string[] = ["xs", "sm", "md", "lg", "xl", "xxl", "section"];
  const validBoxShadows: string[] = ["xs", "sm", "md", "lg", "xl", "xxl", "inset"];

  // background value
  let backgroundValue: string = bgcolor || "";

  if (variant === "gradient") {
    backgroundValue = validGradients.find((el) => el === bgcolor)
      ? linearGradient(gradients[bgcolor || ""]?.main || "", gradients[bgcolor || ""]?.state || "")
      : white.main;
  } else if (validColors.find((el) => el === bgcolor)) {
    backgroundValue = palette[bgcolor as keyof typeof palette]
      ? (palette[bgcolor as keyof typeof palette] as any).main
      : greyColors[bgcolor || ""];
  } else {
    backgroundValue = bgcolor || "";
  }

  // color value
  let colorValue: string = color || "";

  if (validColors.find((el) => el === color)) {
    colorValue = palette[color as keyof typeof palette]
      ? (palette[color as keyof typeof palette] as any).main
      : greyColors[color || ""];
  }

  // borderRadius value
  let borderRadiusValue: string = borderRadius || "";

  if (validBorderRadius.find((el) => el === borderRadius)) {
    borderRadiusValue = radius[borderRadius as keyof typeof radius];
  }

  // boxShadow value
  let boxShadowValue: string = "none";

  if (validBoxShadows.find((el) => el === shadow)) {
    boxShadowValue = boxShadows[shadow as keyof typeof boxShadows];
  } else if (coloredshadow) {
    boxShadowValue = colored[coloredshadow as keyof typeof colored] || "none";
  }

  return {
    opacity,
    background: backgroundValue,
    color: colorValue,
    borderRadius: borderRadiusValue,
    boxShadow: boxShadowValue,
  };
});