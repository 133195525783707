import { forwardRef, createContext, useContext, useMemo, ReactNode } from "react";

//  components
import { Box } from "@mui/material";

// Custom styles for MDPagination
import MDPaginationItemRoot from "../../components/MDPagination/MDPaginationItemRoot";

// Define types for context and props
type ColorType = "white" | "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
type VariantType = "gradient" | "contained";
type SizeType = "small" | "medium" | "large";

interface ContextType {
  variant: VariantType;
  color: ColorType;
  size: SizeType;
}

interface MDPaginationProps {
  item?: boolean;
  variant?: VariantType;
  color?: ColorType;
  size?: SizeType;
  active?: boolean;
  children: ReactNode;
  [key: string]: any; // For rest props
}

// The Pagination main context
const Context = createContext<ContextType | null>(null);

// The Pagination main context

const MDPagination = forwardRef<any, MDPaginationProps>(
  ({ item=false, variant="gradient", color="info", size="medium", active=false, children, ...rest }, ref) => {
    const context = useContext(Context);
    const paginationSize = context ? context.size : null;

    const value = useMemo(
      () => ({ variant, color, size }),
      [variant, color, size]
    );

    return (
      <Context.Provider value={value}>
        {item ? (
          <MDPaginationItemRoot
            {...rest}
            ref={ref}
            variant={active ? (context ?? {}).variant : "outlined"}
            color={active ? (context ?? {}).color : "secondary"}
            iconOnly
            circular
            ownerState={{ variant, active, paginationSize }}
          >
            {children}
          </MDPaginationItemRoot>
        ) : (
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ listStyle: "none" }}
          >
            {children}
          </Box>
        )}
      </Context.Provider>
    );
  }
);


export default MDPagination;
