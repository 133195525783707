// src/context/LoadingContext.tsx
import React, { createContext, useContext, useState, useCallback, ReactNode, useEffect, useRef, useMemo } from 'react';
import MDLoadingScreen from '../components/MDLoadingScreen';
import { debounce } from 'lodash';
import { LoadingContextType, LoadingSource } from '../types';

// LoadingContext oluşturuluyor
const LoadingContext = createContext<LoadingContextType | null>(null);

interface LoadingProviderProps {
    children: ReactNode;
    minimumLoadingTime?: number; // Minimum yükleme süresi (ms)
    debounceTime?: number; // Debounce süresi (ms)
}

export const LoadingProvider: React.FC<LoadingProviderProps> = ({
    children,
    minimumLoadingTime = 300,
    debounceTime = 150
}) => {
    // Map kullanarak daha verimli veri yapısı
    const [loadingSources, setLoadingSources] = useState<Map<string, LoadingSource>>(new Map());
    const [isLoadingVisible, setIsLoadingVisible] = useState(false);
    const loadingStartTimeRef = useRef<number | null>(null);

    // Yükleme durumunu güncelleme işlemini debounce ile geciktirme
    const debouncedSetLoading = useRef(
        debounce((shouldShow: boolean) => {
            if (shouldShow) {
                setIsLoadingVisible(true);
                if (loadingStartTimeRef.current === null) {
                    loadingStartTimeRef.current = Date.now();
                }
            } else {
                // Minimum yükleme süresini kontrol et
                const currentTime = Date.now();
                const loadingStartTime = loadingStartTimeRef.current || currentTime;
                const elapsedTime = currentTime - loadingStartTime;

                if (elapsedTime >= minimumLoadingTime) {
                    setIsLoadingVisible(false);
                    loadingStartTimeRef.current = null;
                } else {
                    // Minimum süre tamamlanana kadar bekle
                    setTimeout(() => {
                        setIsLoadingVisible(false);
                        loadingStartTimeRef.current = null;
                    }, minimumLoadingTime - elapsedTime);
                }
            }
        }, debounceTime)
    ).current;

    // Loading'i başlatan fonksiyon - basitleştirilmiş
    const startLoading = useCallback((
        id: string,
        type: 'auth' | 'data' | 'ui' = 'ui'
    ) => {
        setLoadingSources(prev => {
            const newSources = new Map(prev);
            newSources.set(id, { id, type });
            return newSources;
        });
    }, []);

    // Loading'i durduran fonksiyon - basitleştirilmiş
    const stopLoading = useCallback((id: string) => {
        setLoadingSources(prev => {
            const newSources = new Map(prev);
            newSources.delete(id);
            return newSources;
        });
    }, []);

    // Aktif yükleme kaynaklarını getiren fonksiyon
    const getActiveSources = useCallback(() => {
        return Array.from(loadingSources.keys());
    }, [loadingSources]);

    // Belirli bir tipin yükleme durumunu kontrol eden fonksiyon 
    const isLoadingType = useCallback((type: 'auth' | 'data' | 'ui') => {
        return Array.from(loadingSources.values()).some(source => source.type === type);
    }, [loadingSources]);

    // Context değerini memoize et
    const value = useMemo<LoadingContextType>(() => ({
        isLoading: isLoadingVisible,
        startLoading,
        stopLoading,
        getActiveSources,
        isLoadingType
    }), [isLoadingVisible, startLoading, stopLoading, getActiveSources, isLoadingType]);

    // loadingSources değiştiğinde yükleme durumunu güncelle
    useEffect(() => {
        const hasActiveSources = loadingSources.size > 0;
        debouncedSetLoading(hasActiveSources);

        return () => {
            // Component unmount olduğunda debounce'u iptal et
            debouncedSetLoading.cancel();
        };
    }, [loadingSources, debouncedSetLoading]);

    return (
        <LoadingContext.Provider value={value}>
            {isLoadingVisible && <MDLoadingScreen />}
            {children}
        </LoadingContext.Provider>
    );
};

// Hook olarak kullanım için
export const useLoading = (): LoadingContextType => {
    const context = useContext(LoadingContext);

    if (!context) {
        throw new Error('useLoading must be used within a LoadingProvider');
    }

    return context;
};