import { Link as RouterLink } from "react-router-dom";
import {
  Link,
  Stack,
  Divider,
  useTheme,
  useMediaQuery,
  Box,
} from "@mui/material";
import { GitHub, Twitter, LinkedIn } from "@mui/icons-material";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";

// SocialLink ve Link türlerini tanımlama
interface SocialLink {
  name: string;
  href: string;
  icon: JSX.Element;
}

interface LinkItem {
  href: string;
  name: string;
}

// Footer bileşeninin prop'larını tanımlama
interface FooterProps {
  company?: {
    href: string;
    name: string;
  };
  links?: LinkItem[];
  socialLinks?: SocialLink[];
  isMainLayout?: boolean;
}

// Varsayılan sosyal medya linkleri
const defaultSocialLinks: SocialLink[] = [
  {
    name: "GitHub",
    href: "https://github.com/gkhnmr",
    icon: <GitHub fontSize="small" />,
  },
  {
    name: "Twitter",
    href: "https://x.com/gkhnmr",
    icon: <Twitter fontSize="small" />,
  },
  {
    name: "LinkedIn",
    href: "https://linkedin.com/in/gkhnmr",
    icon: <LinkedIn fontSize="small" />,
  },
];

function Footer({
  company = { href: "/", name: "Your Company" },
  links = [
    { href: "/", name: "Home" },
    { href: "/about", name: "About" },
    { href: "/blog", name: "Blog" },
    { href: "/contact", name: "Contact" },
  ],
  socialLinks = defaultSocialLinks,
  isMainLayout = false,
}: FooterProps) {
  const { name } = company;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Linkin harici olup olmadığını kontrol etme
  const isExternalLink = (url: string): boolean => {
    return url.startsWith("http") || url.startsWith("https");
  };

  // Navigasyon linklerini render etme
  const renderLinks = () =>
    links.map((link) => (
      <MDBox key={link.name} component="li" sx={{ display: "inline" }}>
        {isExternalLink(link.href) ? (
          <Link
            href={link.href}
            target="_blank"
            sx={{
              textDecoration: "none",
              transition: "color 0.2s",
              "&:hover": {
                color: "primary.main",
              },
            }}
          >
            <MDTypography>{link.name}</MDTypography>
          </Link>
        ) : (
          <Link
            component={RouterLink}
            to={link.href}
            sx={{
              textDecoration: "none",
              transition: "color 0.2s",
              "&:hover": {
                color: "primary.main",
              },
            }}
          >
              <MDTypography>{link.name}</MDTypography>
          </Link>
        )}
      </MDBox>
    ));

  // Sosyal medya ikonlarını render etme
  const renderSocialLinks = () =>
    socialLinks.map((social) => (
      <Link
        key={social.name}
        href={social.href}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          transition: "color 0.2s, transform 0.2s",
          "&:hover": {
            color: "primary.main",
            transform: "translateY(-2px)",
          },
        }}
      >
        {social.icon}
      </Link>
    ));

  return (
    <Box
      component="footer"
      sx={
        isMainLayout
          ? {
            py: 1,
            px: 2,
            mt: "auto",
          }
          : {
            py: 0.5,
            px: 0.5,
            mt: "auto",
          }
      }
    >
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems={isMobile ? "center" : "flex-start"}
        spacing={4}
      >
        {/* Şirket Bilgisi */}
        {isMainLayout ? (
          <Stack spacing={2} alignItems={isMobile ? "center" : "flex-start"}>
            <MDTypography variant="h6" gutterBottom>
              {name}
            </MDTypography>
            <MDTypography variant="body2">
              Building better web experiences
            </MDTypography>
            <Stack direction="row" spacing={2}>
              {renderSocialLinks()}
            </Stack>
          </Stack>
        ) : (
          <Stack spacing={2} alignItems={isMobile ? "center" : "flex-start"}>
            <Stack direction="row" spacing={2}>
              {renderSocialLinks()}
            </Stack>
          </Stack>
        )}

        {/* Navigasyon Linkleri */}
        <Stack
          component="nav"
          direction={isMobile ? "row" : isMainLayout ? "column" : "row"}
          alignItems={isMobile ? "center" : "flex-end"}
          spacing={2}
          sx={{
            "& li": {
              listStyle: "none",
            },
          }}
        >
          {renderLinks()}
        </Stack>
      </Stack>

      <Divider sx={{ my: 2 }} />

      {/* Alt Bölüm */}
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <MDTypography variant="body2">
          © {new Date().getFullYear()} {name}. All rights reserved.
        </MDTypography>
        <Box display="flex" justifyContent="center" alignItems="bottom">
          <a
            href="//www.dmca.com/Protection/Status.aspx?ID=be625cc3-45ae-4c01-a232-59eab2bfd122"
            title="DMCA.com Protection Status"
            className="dmca-badge"
          >
            {" "}
            <img
              src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-10.png?ID=be625cc3-45ae-4c01-a232-59eab2bfd122"
              alt="DMCA.com Protection Status"
            />
          </a>{" "}
          <script src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js">
            {" "}
          </script>
        </Box>
        <MDTypography variant="caption" align="center">
          Made with Material UI
        </MDTypography>
      </Stack>
    </Box>
  );
}

export default Footer;