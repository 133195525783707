// @mui material components
import { styled } from "@mui/material/styles";
import { CustomTheme } from "../../assets/theme/types/CustomTheme";

// styled API için MUI'nin beklediği tipleri kullanıyoruz, 
// sonra içeride CustomTheme tipimize dönüştürüyoruz
export default styled("span")(({ theme }) => {
  // Theme'i CustomTheme'e dönüştürüyoruz
  const customTheme = theme as unknown as CustomTheme;
  const { palette, typography, functions } = customTheme;

  const { white } = palette;
  const { size, fontWeightMedium } = typography;
  const { pxToRem } = functions;

  return {
    color: white.main,
    fontSize: size.xl,
    padding: `${pxToRem(9)} ${pxToRem(6)} ${pxToRem(8)}`,
    marginLeft: pxToRem(40),
    fontWeight: fontWeightMedium,
    cursor: "pointer",
    lineHeight: 0,
  };
});