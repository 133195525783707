import React from "react";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import MDTypography from "../../../../components/MDTypography";
import { Instagram, LinkedIn } from "@mui/icons-material";
import GitHubIcon from "@mui/icons-material/GitHub";

/**
 * Component to display social media links
 */
const SocialMediaLinks: React.FC = () => {
    return (
        <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
                <MDTypography
                    component={MuiLink}
                    href="http://www.instagram.com/gkhnmr"
                    variant="body1"
                    color="white"
                    target="_blank"
                >
                    <Instagram color="inherit" />
                </MDTypography>
            </Grid>
            <Grid item xs={2}>
                <MDTypography
                    component={MuiLink}
                    href="http://www.github.com/gkhnmr"
                    variant="body1"
                    color="white"
                    target="_blank"
                >
                    <GitHubIcon color="inherit" />
                </MDTypography>
            </Grid>
            <Grid item xs={2}>
                <MDTypography
                    component={MuiLink}
                    href="https://www.linkedin.com/in/gkhnmr"
                    variant="body1"
                    color="white"
                    target="_blank"
                >
                    <LinkedIn color="inherit" />
                </MDTypography>
            </Grid>
        </Grid>
    );
};

export default SocialMediaLinks;