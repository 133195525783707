// @mui material components
import Grid from "@mui/material/Grid";

//  components
import { Box } from "@mui/material";

//  examples
import Footer from "../../examples/Footer";

import { useAccount } from "../../context/AccountContext";
import MDLoadingSkeleton from "../../components/MDLoadingSkeleton";
import MDErrorDisplay from "../../components/MDErrorDisplay";
import Hakkimda from "./components/Hakkimda";

/**
 * TypeScript interface for the HakkimdaLayout component props.
 */
interface HakkimdaLayoutProps {
  /**
   * Determines the transparency styling.
   * @default false
   */
  transparent?: boolean;

  /**
   * Determines the light theme styling.
   * @default false
   */
  light?: boolean;
}

/**
 * HakkimdaLayout component renders the layout for the "Hakkımda" section.
 *
 * This component handles loading and error states. When loading, it displays
 * a loading skeleton. In case of an error, it displays an error message.
 * Normally, it renders the Hakkimda component.
 *
 * @param {HakkimdaLayoutProps} props - The component props.
 * @returns {JSX.Element} The rendered HakkimdaLayout component.
 */
function HakkimdaLayout({ transparent = false, light = false }: HakkimdaLayoutProps): JSX.Element {

  const { isLoading, error }: any = useAccount();

  // Loading durumunda tüm grid için tek bir loading state
  if (isLoading) {
    return (
      <Box
        px={{
          xs: 1,
          sm: transparent ? 0 : 1,
          md: transparent ? 0 : 1,
          xl: transparent ? 0 : 1,
          lg: transparent ? 0 : 1,
        }}
        my={1}
        mx={2}
      >
        <Grid container spacing={2}>
          {[...Array(3)].map((_, index) => (
            <Grid item xs={12} lg={6} key={index}>
              <MDLoadingSkeleton />
            </Grid>
          ))}
        </Grid>
        <Footer />
      </Box>
    );
  }

  // Hata durumunda
  if (error) {
    return (
      <Box
        px={{
          xs: 1,
          sm: transparent ? 0 : 1,
          md: transparent ? 0 : 1,
          xl: transparent ? 0 : 1,
          lg: transparent ? 0 : 1,
        }}
        my={1}
        mx={2}
      >
        <MDErrorDisplay message={error} />
      </Box>
    );
  }

  return (
    <Box
      px={{
        xs: 1,
        sm: transparent ? 0 : 1,
        md: transparent ? 0 : 1,
        xl: transparent ? 0 : 1,
        lg: transparent ? 0 : 1,
      }}
      my={1}
      mx={2}
    >
      <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Hakkimda />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default HakkimdaLayout;