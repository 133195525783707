//  base styles
import colors from "../../../../assets/theme/base/colors";
import boxShadows from "../../../../assets/theme/base/boxShadows";
import borders from "../../../../assets/theme/base/borders";

const { white } = colors;
const { md } = boxShadows;
const { borderRadius } = borders;

interface TableContainerStyleOverrides {
  root: {
    backgroundColor: string;
    boxShadow: string;
    borderRadius: string | number;
  };
}

interface TableContainerStyles {
  styleOverrides: TableContainerStyleOverrides;
}

const tableContainer: TableContainerStyles = {
  styleOverrides: {
    root: {
      backgroundColor: white.main,
      boxShadow: md,
      borderRadius: borderRadius.xl,
    },
  },
};

export default tableContainer;