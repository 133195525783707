//  helper functions
import pxToRem from "../../../assets/theme/functions/pxToRem";

interface IconDefaultProps {
  baseClassName: string;
  fontSize: string;
}

interface IconStyleOverrides {
  fontSizeInherit: {
    fontSize: string;
  };
  fontSizeSmall: {
    fontSize: string;
  };
  fontSizeLarge: {
    fontSize: string;
  };
}

interface Icon {
  defaultProps: IconDefaultProps;
  styleOverrides: IconStyleOverrides;
}

const icon: Icon = {
  defaultProps: {
    baseClassName: "material-icons-round",
    fontSize: "inherit",
  },

  styleOverrides: {
    fontSizeInherit: {
      fontSize: "inherit !important",
    },

    fontSizeSmall: {
      fontSize: `${pxToRem(20)} !important`,
    },

    fontSizeLarge: {
      fontSize: `${pxToRem(36)} !important`,
    },
  },
};

export default icon;