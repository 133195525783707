import { useState, ReactNode } from "react";

// @mui material components
import Fade from "@mui/material/Fade";

//  components
import { Box } from "@mui/material";

// Custom styles for the MDAlert
import MDAlertRoot from "../../components/MDAlert/MDAlertRoot";
import MDAlertCloseIcon from "../../components/MDAlert/MDAlertCloseIcon";

// Defining the prop types interface for MDAlert
interface MDAlertProps {
  color?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark";
  dismissible?: boolean;
  children: ReactNode;
  [key: string]: any; // For rest props
}

function MDAlert({ color = "info", dismissible = false, children, ...rest }: MDAlertProps) {
  const [alertStatus, setAlertStatus] = useState<"mount" | "fadeOut" | "unmount">("mount");

  const handleAlertStatus = () => setAlertStatus("fadeOut");

  // The base template for the alert
  const alertTemplate = (mount = true) => (
    <Fade in={mount} timeout={300}>
      <MDAlertRoot ownerState={{ color }} {...rest}>
        <Box display="flex" alignItems="center" color="white">
          {children}
        </Box>
        {dismissible ? (
          <MDAlertCloseIcon onClick={mount ? handleAlertStatus : undefined}>
            &times;
          </MDAlertCloseIcon>
        ) : null}
      </MDAlertRoot>
    </Fade>
  );

  switch (true) {
    case alertStatus === "mount":
      return alertTemplate();
    case alertStatus === "fadeOut":
      setTimeout(() => setAlertStatus("unmount"), 400);
      return alertTemplate(false);
    default:
      alertTemplate();
      break;
  }

  return null;
}

export default MDAlert;