//  base styles
import colors from "../../../../assets/theme/base/colors";
import borders from "../../../../assets/theme/base/borders";
import boxShadows from "../../../../assets/theme/base/boxShadows";

//  helper functions
import pxToRem from "../../../../assets/theme/functions/pxToRem";
import linearGradient from "../../../../assets/theme/functions/linearGradient";


interface StepperStyleOverrides {
  root: {
    background: string;
    padding: string;
    borderRadius: string;
    boxShadow: string;
    "&.MuiPaper-root": {
      backgroundColor: string;
    };
  };
}

const { transparent, gradients } = colors;
const { borderRadius } = borders;
const { colored } = boxShadows;

const stepper: {
  styleOverrides: StepperStyleOverrides;
} = {
  styleOverrides: {
    root: {
      background: linearGradient(gradients.info.main, gradients.info.state),
      padding: `${pxToRem(24)} 0 ${pxToRem(16)}`,
      borderRadius: borderRadius.lg,
      boxShadow: colored.info,

      "&.MuiPaper-root": {
        backgroundColor: transparent.main,
      },
    },
  },
};

export default stepper;