// src/AppProviders.tsx
import React, { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './store/store';
import { AuthProvider } from './AuthContext';
import { AccountProvider } from './context/AccountContext';
import { MaterialUIControllerProvider } from './context';
import { LoadingProvider } from './context/LoadingContext';

interface AppProvidersProps {
    children: ReactNode;
}

// Her bir provider'ı ayrı bir bileşen olarak organize ediyoruz
const AppProviders: React.FC<AppProvidersProps> = ({ children }) => {
    return (
        <BrowserRouter>
            <MaterialUIControllerProvider>
                <LoadingProvider>
                    <AuthProvider>
                        <AccountProvider>
                            <ReduxProvider store={store}>
                                {children}
                            </ReduxProvider>
                        </AccountProvider>
                    </AuthProvider>
                </LoadingProvider>
            </MaterialUIControllerProvider>
        </BrowserRouter>
    );
};

export default AppProviders;