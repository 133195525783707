// breadcrumbs.ts
import colors from "../../../assets/theme/base/colors";
import typography from "../../../assets/theme/base/typography";

const { grey } = colors;
const { size } = typography;

interface BreadcrumbsStyleOverrides {
  li: {
    lineHeight: number;
  };
  separator: {
    fontSize: string;
    color: string;
  };
}

interface Breadcrumbs {
  styleOverrides: BreadcrumbsStyleOverrides;
}

const breadcrumbs: Breadcrumbs = {
  styleOverrides: {
    li: {
      lineHeight: 0,
    },

    separator: {
      fontSize: size.sm,
      color: grey[600],
    },
  },
};

export default breadcrumbs;