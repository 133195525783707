// styles/darkModeStyles.js
export const darkModeStyles = (darkMode: boolean) => ({
  "& .MuiInputBase-root.Mui-disabled": {
    backgroundColor: darkMode
      ? "rgba(255, 255, 255, 0.05)"
      : "rgba(0, 0, 0, 0.05)",
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: darkMode
        ? "rgba(255, 255, 255, 0.5)"
        : "rgba(0, 0, 0, 0.38)",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: darkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)",
    },
  },
  "& .MuiInputLabel-root.Mui-disabled": {
    color: darkMode ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.38)",
  },
});
