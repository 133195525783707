// @mui material components
import { createTheme, ThemeOptions } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { PaletteOptions } from "@mui/material/styles/createPalette";

//  base styles
import colors from "../../assets/theme/base/colors";
import breakpoints from "../../assets/theme/base/breakpoints";
import typography from "../../assets/theme/base/typography";
import boxShadows from "../../assets/theme/base/boxShadows";
import borders from "../../assets/theme/base/borders";
import globals from "../../assets/theme/base/globals";

//  helper functions
import boxShadow from "../../assets/theme/functions/boxShadow";
import hexToRgb from "../../assets/theme/functions/hexToRgb";
import linearGradient from "../../assets/theme/functions/linearGradient";
import pxToRem from "../../assets/theme/functions/pxToRem";
import rgba from "../../assets/theme/functions/rgba";

//  components base styles for @mui material components
import sidenav from "../../assets/theme/components/sidenav";
import list from "../../assets/theme/components/list";
import listItem from "../../assets/theme/components/list/listItem";
import listItemText from "../../assets/theme/components/list/listItemText";
import card from "../../assets/theme/components/card";
import cardMedia from "../../assets/theme/components/card/cardMedia";
import cardContent from "../../assets/theme/components/card/cardContent";
import button from "../../assets/theme/components/button";
import iconButton from "../../assets/theme/components/iconButton";
import input from "../../assets/theme/components/form/input";
import inputLabel from "../../assets/theme/components/form/inputLabel";
import inputOutlined from "../../assets/theme/components/form/inputOutlined";
import textField from "../../assets/theme/components/form/textField";
import menu from "../../assets/theme/components/menu";
import menuItem from "../../assets/theme/components/menu/menuItem";
import switchButton from "../../assets/theme/components/form/switchButton";
import divider from "../../assets/theme/components/divider";
import tableContainer from "../../assets/theme/components/table/tableContainer";
import tableHead from "../../assets/theme/components/table/tableHead";
import tableCell from "../../assets/theme/components/table/tableCell";
import linearProgress from "../../assets/theme/components/linearProgress";
import breadcrumbs from "../../assets/theme/components/breadcrumbs";
import slider from "../../assets/theme/components/slider";
import avatar from "../../assets/theme/components/avatar";
import tooltip from "../../assets/theme/components/tooltip";
import appBar from "../../assets/theme/components/appBar";
import tabs from "../../assets/theme/components/tabs";
import tab from "../../assets/theme/components/tabs/tab";
import stepper from "../../assets/theme/components/stepper";
import step from "../../assets/theme/components/stepper/step";
import stepConnector from "../../assets/theme/components/stepper/stepConnector";
import stepLabel from "../../assets/theme/components/stepper/stepLabel";
import stepIcon from "../../assets/theme/components/stepper/stepIcon";
import select from "../../assets/theme/components/form/select";
import formControlLabel from "../../assets/theme/components/form/formControlLabel";
import formLabel from "../../assets/theme/components/form/formLabel";
import checkbox from "../../assets/theme/components/form/checkbox";
import radio from "../../assets/theme/components/form/radio";
import autocomplete from "../../assets/theme/components/form/autocomplete";
import container from "../../assets/theme/components/container";
import popover from "../../assets/theme/components/popover";
import buttonBase from "../../assets/theme/components/buttonBase";
import icon from "../../assets/theme/components/icon";
import svgIcon from "../../assets/theme/components/svgIcon";
import link from "../../assets/theme/components/link";
import dialog from "../../assets/theme/components/dialog";
import dialogTitle from "../../assets/theme/components/dialog/dialogTitle";
import dialogContent from "../../assets/theme/components/dialog/dialogContent";
import dialogContentText from "../../assets/theme/components/dialog/dialogContentText";
import dialogActions from "../../assets/theme/components/dialog/dialogActions";

// Özel tema tiplerini MUI'nin tip sistemiyle uyumlu hale getirelim
declare module "@mui/material/styles" {
  interface Theme {
    boxShadows: typeof boxShadows;
    borders: typeof borders;
    functions: {
      boxShadow: typeof boxShadow;
      hexToRgb: typeof hexToRgb;
      linearGradient: typeof linearGradient;
      pxToRem: typeof pxToRem;
      rgba: typeof rgba;
    };
  }

  interface ThemeOptions {
    boxShadows?: typeof boxShadows;
    borders?: typeof borders;
    functions?: {
      boxShadow: typeof boxShadow;
      hexToRgb: typeof hexToRgb;
      linearGradient: typeof linearGradient;
      pxToRem: typeof pxToRem;
      rgba: typeof rgba;
    };
  }
}

// Komponentleri güvenli bir şekilde bağlamak için yardımcı fonksiyon
const componentOverride = (component: any) => {
  if (!component) return {};

  return {
    defaultProps: component.defaultProps || {},
    styleOverrides: component.styleOverrides ? Object.fromEntries(
      Object.entries(component.styleOverrides).map(([key, value]) => {
        // Root stil nesneleri için özel işleme
        if (key === 'root' && value) {
          return [key, (props: any) => ({
            ...(typeof value === 'function' ? value(props) : value)
          })];
        }
        return [key, value];
      })
    ) : {}
  };
};

// MUI tema oluşturma
const themeOptions: ThemeOptions = {
  breakpoints,
  typography: typography as TypographyOptions,
  palette: colors as PaletteOptions,
  boxShadows,
  borders,
  functions: {
    boxShadow,
    hexToRgb,
    linearGradient,
    pxToRem,
    rgba,
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
        ...container,
        "*": {
          scrollbarWidth: "thin" as const,
          scrollbarColor: "rgba(155, 155, 155, 0.5) transparent",

          "&::-webkit-scrollbar": {
            width: "8px",
            height: "8px",
          },

          "&::-webkit-scrollbar-track": {
            background: "transparent",
            borderRadius: "4px",
          },

          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(155, 155, 155, 0.5)",
            borderRadius: "4px",
            transition: "background-color 0.2s",

            "&:hover": {
              backgroundColor: "rgba(155, 155, 155, 0.8)",
            },
          },
        },
      },
    },
    MuiDrawer: sidenav,
    MuiList: list,
    MuiListItem: listItem,
    MuiListItemText: listItemText,
    MuiCard: card,
    MuiCardMedia: componentOverride(cardMedia),
    MuiCardContent: cardContent,
    MuiButton: componentOverride(button),
    MuiIconButton: iconButton,
    MuiInput: input,
    MuiInputLabel: inputLabel,
    MuiOutlinedInput: componentOverride(inputOutlined),
    MuiTextField: componentOverride(textField),
    MuiMenu: menu,
    MuiMenuItem: menuItem,
    MuiSwitch: componentOverride(switchButton),
    MuiDivider: divider,
    MuiTableContainer: tableContainer,
    MuiTableHead: tableHead,
    MuiTableCell: tableCell,
    MuiLinearProgress: componentOverride(linearProgress),
    MuiBreadcrumbs: breadcrumbs,
    MuiSlider: componentOverride(slider),
    MuiAvatar: avatar,
    MuiTooltip: componentOverride(tooltip),
    MuiAppBar: componentOverride(appBar),
    MuiTabs: tabs,
    MuiTab: componentOverride(tab),
    MuiStepper: stepper,
    MuiStep: step,
    MuiStepConnector: stepConnector,
    MuiStepLabel: componentOverride(stepLabel),
    MuiStepIcon: stepIcon,
    MuiSelect: componentOverride(select),
    MuiFormControlLabel: componentOverride(formControlLabel),
    MuiFormLabel: componentOverride(formLabel),
    MuiCheckbox: checkbox,
    MuiRadio: radio,
    MuiAutocomplete: componentOverride(autocomplete),
    MuiPopover: popover,
    MuiButtonBase: buttonBase,
    MuiIcon: componentOverride(icon),
    MuiSvgIcon: componentOverride(svgIcon),
    MuiLink: componentOverride(link),
    MuiDialog: dialog,
    MuiDialogTitle: dialogTitle,
    MuiDialogContent: dialogContent,
    MuiDialogContentText: dialogContentText,
    MuiDialogActions: dialogActions,
  },
};

const theme = createTheme(themeOptions);

export default theme;