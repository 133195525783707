import { useState, useEffect } from "react";
import {
    collection,
    query,
    where,
    getDocs,
    doc,
    getDoc,
} from "firebase/firestore";
import { db } from "../../../lib/firebase";
import { Post, Author, UserAccountInfo } from "../types";
import { trackPostView } from "../../../utils/tracking";

/**
 * Custom hook to fetch and manage post data
 */
export const usePost = (slug: string | undefined, userAccountInfo: UserAccountInfo) => {
    const [post, setPost] = useState<Post | null>(null);
    const [author, setAuthor] = useState<Author | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchPostAndAuthor = async (): Promise<void> => {
            try {
                setLoading(true);

                if (!slug) {
                    setError("Post slug not found");
                    return;
                }

                // Cache check
                const viewKey = `post_view_${slug}`;
                const lastView = localStorage.getItem(viewKey);
                const now = Date.now();
                const shouldTrackView =
                    !lastView || now - parseInt(lastView) > 24 * 60 * 60 * 1000;

                // First get post by slug
                const postsRef = collection(db, "posts");
                const q = query(
                    postsRef,
                    where("slug", "==", slug),
                    where("status", "==", "published")
                );
                const querySnapshot = await getDocs(q);

                if (querySnapshot.empty) {
                    setError("Yazı Bulunamadı!");
                    return;
                }

                const postDoc = querySnapshot.docs[0];
                const postData = { id: postDoc.id, ...postDoc.data() } as Post;

                // Create view record after post is found
                if (shouldTrackView) {
                    await trackPostView(postDoc.id, userAccountInfo?.uid ?? '');
                    localStorage.setItem(viewKey, now.toString());
                }

                // Get author information
                if (postData.authorId) {
                    try {
                        const authorRef = doc(db, "users", postData.authorId);
                        const authorSnapshot = await getDoc(authorRef);

                        if (authorSnapshot.exists()) {
                            const authorData = authorSnapshot.data();
                            setAuthor({
                                id: authorSnapshot.id,
                                ...authorData,
                                avatarUrl: authorData.avatarUrl || "/default-avatar.png",
                                fullName: authorData.fullName || "Anonymous Author",
                            } as Author);
                        }
                    } catch (authorError) {
                        console.warn("Could not fetch author details:", authorError);
                    }
                }

                setPost(postData);
            } catch (err) {
                console.error("Data fetch error:", err);
                setError("Content could not be loaded");
            } finally {
                setLoading(false);
            }
        };

        if (slug) {
            fetchPostAndAuthor();
        }
    }, [slug, userAccountInfo]);

    return { post, author, loading, error };
};