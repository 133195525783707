import { forwardRef, ReactNode, ElementType } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDAvatar
import MDAvatarRoot from "../../components/MDAvatar/MDAvatarRoot";

// Define types for props
type BackgroundColor =
  | "transparent"
  | "primary"
  | "secondary"
  | "info"
  | "success"
  | "warning"
  | "error"
  | "light"
  | "dark";

type Size = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
type Shadow = "none" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "inset";

interface MDAvatarProps {
  bgcolor?: BackgroundColor;
  size?: Size;
  shadow?: Shadow;
  [key: string]: any; // For additional props
  children?: ReactNode;
  component?: ElementType;
}

const MDAvatar = forwardRef<HTMLDivElement, MDAvatarProps>(
  ({ bgcolor, size, shadow, ...rest }, ref) => (
    <MDAvatarRoot ref={ref} ownerState={{ shadow, bgcolor, size }} {...rest} />
  )
);

// Setting default values for the props of MDAvatar
MDAvatar.defaultProps = {
  bgcolor: "transparent",
  size: "md",
  shadow: "none",
};

// Typechecking props for the MDAvatar
MDAvatar.propTypes = {
  bgcolor: PropTypes.oneOf([
    "transparent",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
  shadow: PropTypes.oneOf(["none", "xs", "sm", "md", "lg", "xl", "xxl", "inset"]),
};

export default MDAvatar;