import { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  CircularProgress,
  IconButton,
  Icon,
} from "@mui/material";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import MDTypography from "components/MDTypography";
import { Box } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import TextFieldForDisabledDarkMode from "../../../../../components/MDTextFieldForDarkMode";
import MDBox from "components/MDBox";
import { useAccount } from "context/AccountContext";
import { db } from "lib/firebase";
import PropTypes from "prop-types";

const ProfileEdit = ({ transparent, light }) => {
  const { userAccountInfo, isLoading, error } = useAccount();
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState({
    fullName: "",
    username: "",
    email: "",
    bio: "",
    avatarUrl: "",
  });
  const [avatarFile, setAvatarFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  // userAccountInfo değiştiğinde profileData'yı güncelle
  useEffect(() => {
    if (userAccountInfo) {
      setProfileData({
        fullName: userAccountInfo.fullName || "",
        username: userAccountInfo.username || "",
        email: userAccountInfo.email || "",
        bio: userAccountInfo.bio || "",
        avatarUrl: userAccountInfo.avatarUrl || "",
      });
      setPreviewUrl(userAccountInfo.avatarUrl || "");
    }
  }, [userAccountInfo]);

  // Avatar yükleme işleyicisi
  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAvatarFile(file);
      // Önizleme URL'i oluştur
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Form değişikliklerini işle
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Avatar yükleme fonksiyonu
  const uploadAvatar = async (file) => {
    if (!userAccountInfo || !userAccountInfo.uid) {
      throw new Error("Kullanıcı bilgisi bulunamadı");
    }

    const storage = getStorage();
    const avatarRef = ref(storage, `avatars/${userAccountInfo.uid}/${file.name}`);
    await uploadBytes(avatarRef, file);
    return getDownloadURL(avatarRef);
  };

  // Profil güncelleme işleyicisi
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!userAccountInfo || !userAccountInfo.uid) {
        throw new Error("Kullanıcı bilgisi bulunamadı");
      }

      let avatarUrl = profileData.avatarUrl;

      // Eğer yeni avatar yüklendiyse
      if (avatarFile) {
        avatarUrl = await uploadAvatar(avatarFile);
      }

      // Firestore'da kullanıcı dokümanını güncelle
      const userRef = doc(db, "users", userAccountInfo.uid);
      await updateDoc(userRef, {
        ...profileData,
        avatarUrl,
        updatedAt: new Date(),
      });

      toast.success("Profil başarıyla güncellendi");
    } catch (error) {
      console.error("Profil güncelleme hatası:", error);
      toast.error("Profil güncellenirken bir hata oluştu: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return (
      <MDBox
        px={{
          xs: 1,
          sm: transparent ? 0 : 1,
          md: transparent ? 0 : 1,
          xl: transparent ? 0 : 1,
          lg: transparent ? 0 : 1,
        }}
        my={2}
        mx={2}
      >
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={4}>
            <MDBox display="flex" justifyContent="center">
              <CircularProgress />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    );
  }

  if (error) {
    return (
      <MDBox
        px={{
          xs: 1,
          sm: transparent ? 0 : 1,
          md: transparent ? 0 : 1,
          xl: transparent ? 0 : 1,
          lg: transparent ? 0 : 1,
        }}
        my={2}
        mx={2}
      >
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={4}>
            <MDBox display="flex" justifyContent="center">
              <MDTypography variant="body1">{error}</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    );
  }

  return (
    <MDBox
      px={{
        xs: 1,
        sm: transparent ? 0 : 1,
        md: transparent ? 0 : 1,
        xl: transparent ? 0 : 1,
        lg: transparent ? 0 : 1,
      }}
      my={2}
      mx={2}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} justifyContent="center">
          {/* Avatar Bölümü */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ position: "relative", mb: 2 }}>
              <MDAvatar
                src={previewUrl}
                sx={{
                  width: 150,
                  height: 150,
                  border: "4px solid #fff",
                  boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                }}
              />
              <input
                accept="image/*"
                type="file"
                id="avatar-input"
                onChange={handleAvatarChange}
                style={{ display: "none" }}
              />
              <label htmlFor="avatar-input">
                <IconButton
                  component="span"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    backgroundColor: "primary.main",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "primary.dark",
                    },
                  }}
                >
                  <Icon>camera</Icon>
                </IconButton>
              </label>
            </Box>
            <MDTypography variant="body2" align="center" sx={{ mt: 1 }}>
              PNG, JPG veya GIF • Max 2MB
            </MDTypography>
          </Grid>

          {/* Form Alanları */}
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Ad Soyad"
                  name="fullName"
                  value={profileData.fullName}
                  onChange={handleInputChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Kullanıcı Adı"
                  name="username"
                  value={profileData.username}
                  onChange={handleInputChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldForDisabledDarkMode
                  fullWidth
                  label="E-posta"
                  name="email"
                  value={profileData.email}
                  onChange={handleInputChange}
                  variant="outlined"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Biyografi"
                  name="bio"
                  value={profileData.bio}
                  onChange={handleInputChange}
                  variant="outlined"
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Kaydet Butonu */}
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
          >
            <MDButton
              type="submit"
              variant="contained"
              color="success"
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Icon>save</Icon>
                )
              }
              disabled={loading}
            >
              {loading ? "Kaydediliyor..." : "Kaydet"}
            </MDButton>
          </Grid>
        </Grid>
      </form>
    </MDBox>
  );
};

ProfileEdit.defaultProps = {
  transparent: false,
  light: false,
};

ProfileEdit.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
};

export default ProfileEdit;