// avatar.ts
import borders from "../../../assets/theme/base/borders";

const { borderRadius } = borders;

interface AvatarStyleOverrides {
  root: {
    transition: string;
  };
  rounded: {
    borderRadius: string;
  };
  img: {
    height: string;
  };
}

interface Avatar {
  styleOverrides: AvatarStyleOverrides;
}

const avatar: Avatar = {
  styleOverrides: {
    root: {
      transition: "all 200ms ease-in-out",
    },

    rounded: {
      borderRadius: borderRadius.lg,
    },

    img: {
      height: "auto",
    },
  },
};

export default avatar;