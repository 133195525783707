// Base Styles
import typography from "../../../../assets/theme/base/typography";
import borders from "../../../../assets/theme/base/borders";

// Helper Functions
import pxToRem from "../../../../assets/theme/functions/pxToRem";

// TypeScript interfaces
interface Typography {
  fontWeightBold: number;
  size: {
    xs: string;
    [key: string]: string;
  };
}

interface Borders {
  borderRadius: {
    lg: string;
    [key: string]: string;
  };
}

const { fontWeightBold, size } = typography as Typography;
const { borderRadius } = borders as Borders;

interface RootStyles {
  display: string;
  justifyContent: string;
  alignItems: string;
  fontSize: string;
  fontWeight: number;
  borderRadius: string;
  padding: string;
  lineHeight: number;
  textAlign: string;
  textTransform: string;
  userSelect: string;
  backgroundSize: string;
  backgroundPositionX: string;
  transition: string;
  "&:disabled": {
    pointerEvent: string;
    opacity: number;
  };
  "& .material-icons": {
    fontSize: string;
    marginTop: string;
  };
}

const root: RootStyles = {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: size.xs,
  fontWeight: fontWeightBold,
  borderRadius: borderRadius.lg,
  padding: `${pxToRem(6.302)} ${pxToRem(16.604)}`,
  lineHeight: 1.4,
  textAlign: "center",
  textTransform: "uppercase",
  userSelect: "none",
  backgroundSize: "150% !important",
  backgroundPositionX: "25% !important",
  transition: "all 150ms ease-in",

  "&:disabled": {
    pointerEvent: "none",
    opacity: 0.65,
  },

  "& .material-icons": {
    fontSize: pxToRem(15),
    marginTop: pxToRem(-2),
  },
};

export default root;