// CookieConsent.tsx
import React, { useEffect, useState } from 'react';
import {
    Paper,
    Button,
    Typography,
    Box,
    Stack,
    useTheme,
    useMediaQuery,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface CookieConsentProps {
    onAccept: () => void;
    onReject: () => void;
}

const CookieConsent: React.FC<CookieConsentProps> = ({ onAccept, onReject }) => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const cookieConsent = localStorage.getItem('cookieConsent');
        if (!cookieConsent) {
            setOpen(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'accepted');
        setOpen(false);
        onAccept();
    };

    const handleReject = () => {
        localStorage.setItem('cookieConsent', 'rejected');
        setOpen(false);
        onReject();
    };

    if (!open) return null;

    return (
        <Paper
            elevation={3}
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: theme.zIndex.snackbar,
                borderRadius: 0,
                bgcolor: 'rgba(255, 255, 255, 0.1)',
                backdropFilter: 'blur(10px)',
                WebkitBackdropFilter: 'blur(10px)', // Safari için destek
                p: isMobile ? 2 : 3,
            }}
        >
            <IconButton
                onClick={() => setOpen(false)}
                sx={{
                    position: 'absolute',
                    right: -12,
                    top: -12,
                    margin: 2,
                    color: 'white',
                    '&:hover': {
                        color: 'grey.300'
                    }
                }}
            >
                <CloseIcon />
            </IconButton>
            <Box
                sx={{
                    maxWidth: '1200px',
                    margin: '0 auto',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: isMobile ? 'stretch' : 'center',
                    justifyContent: 'space-between',
                    gap: 2,
                    position: 'relative'  // Kapatma butonu için relative pozisyon
                }}
            >
                <Box sx={{ flex: 1 }}>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                        Bu web sitesi, size en iyi deneyimi sunmak için çerezleri kullanmaktadır.
                        Çerezler, web sitemizi nasıl kullandığınız hakkında bilgi toplamamıza ve
                        hizmetlerimizi geliştirmemize yardımcı olur.
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary">
                        Çerezler hakkında daha fazla bilgi için lütfen çerez politikamızı inceleyiniz.
                    </Typography>
                </Box>

                <Stack
                    direction={isMobile ? 'column' : 'row'}
                    spacing={2}
                    sx={{
                        minWidth: isMobile ? 'auto' : '300px',
                        mt: isMobile ? 2 : 0
                    }}
                >
                    <Button
                        onClick={handleReject}
                        color='secondary'
                        sx={{
                            color: '#1b22a6',  // Burada yazı rengini beyaz yaptık
                            '&:hover': {
                                color: '#fe0000'  // Hover durumunda da beyaz kalmasını sağladık
                            }
                        }}
                        variant="outlined"
                        fullWidth={isMobile}
                    >
                        Reddet
                    </Button>
                    <Button
                        onClick={handleAccept}
                        variant="contained"
                        color="primary"
                        fullWidth={isMobile}
                        sx={{
                            color: '#ffffff',  // Burada yazı rengini beyaz yaptık
                            '&:hover': {
                                color: '#00ce5d'  // Hover durumunda da beyaz kalmasını sağladık
                            }
                        }}
                    >
                        Tüm Çerezleri Kabul Et
                    </Button>
                </Stack>
            </Box>
        </Paper>
    );
};

export default CookieConsent;