import React from 'react';

// MDLoadingScreen bileşeninin prop tipleri
interface MDLoadingScreenProps {
  text?: string;
  color?: string;
  size?: 'small' | 'medium' | 'large';
  fullScreen?: boolean;
  logo?: string;
}

const MDLoadingScreen: React.FC<MDLoadingScreenProps> = ({
  text = '',
  color = '#3498db',
  size = 'medium',
  fullScreen = true,
  logo,
}) => {
  // Boyut değerlerini belirleme
  const sizeValues = {
    small: {
      spinner: 30,
      text: '0.8rem',
    },
    medium: {
      spinner: 50,
      text: '1rem',
    },
    large: {
      spinner: 70,
      text: '1.2rem',
    },
  };

  const selectedSize = sizeValues[size];

  // Stil tanımlamaları
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    ...(fullScreen
      ? {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        zIndex: 9999,
      }
      : {
        padding: '2rem',
      }),
  };

  const spinnerContainerStyle: React.CSSProperties = {
    position: 'relative',
    width: `${selectedSize.spinner}px`,
    height: `${selectedSize.spinner}px`,
  };

  const spinnerStyle: React.CSSProperties = {
    boxSizing: 'border-box',
    position: 'absolute',
    width: '100%',
    height: '100%',
    border: `4px solid transparent`,
    borderTopColor: color,
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  };

  const pulseStyle: React.CSSProperties = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    backgroundColor: `${color}20`,
    animation: 'pulse 1.5s ease-in-out infinite',
  };

  const textStyle: React.CSSProperties = {
    marginTop: '1rem',
    color: '#333',
    fontSize: selectedSize.text,
    fontWeight: 500,
    textAlign: 'center',
  };

  const logoStyle: React.CSSProperties = {
    marginBottom: '1rem',
    maxWidth: `${selectedSize.spinner * 2}px`,
    maxHeight: `${selectedSize.spinner * 2}px`,
  };

  return (
    <div style={containerStyle}>
      {logo && <img src={logo} alt="Logo" style={logoStyle} />}

      <div style={spinnerContainerStyle}>
        <div style={pulseStyle} />
        <div style={spinnerStyle} />
      </div>

      {text && <div style={textStyle}>{text}</div>}

      {/* CSS animasyonları için stil */}
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
          
          @keyframes pulse {
            0% { transform: scale(0.6); opacity: 1; }
            50% { transform: scale(1); opacity: 0.5; }
            100% { transform: scale(0.6); opacity: 1; }
          }
        `}
      </style>
    </div>
  );
};

export default MDLoadingScreen;