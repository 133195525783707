import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../AuthContext";

function MDProtectedRoute({ children }: { children: JSX.Element }) {
  const { user } = useAuth();
  const location = useLocation();

  // Kullanıcı yoksa giriş sayfasına yönlendir
  if (!user) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  // Kullanıcı var ama e-postası doğrulanmamışsa giriş sayfasına yönlendir
  if (!user.emailVerified) {
    // Önce çıkış yap ve giriş sayfasına yönlendir
    return <Navigate to="/auth/giris-yap" replace />;
  }

  return children;
}

export default MDProtectedRoute;
