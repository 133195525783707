import { useCallback, useEffect, useRef } from "react";
import { getAuth, signOut, Auth } from "firebase/auth";
import {
  getDatabase,
  ref,
  set,
  get,
  onDisconnect,
  serverTimestamp,
  remove,
  Database,
} from "firebase/database";
import { toast } from "react-toastify";
import { useAuth } from "../AuthContext";
import { AuthContextType } from "../types";


interface SessionManagerReturn {
  logout: () => Promise<void>;
}

/**
 * Oturum yöneticisi
 *
 * Bu hook, kullanıcı oturumunu takip eder ve
 * belirlenen süre zarfında kullanıcı aktivitesi
 * olmadığında, oturumu kapatır.
 *
 * @param {number} inactivityTimeout - Oturum kapatma süresi (ms cinsinden)
 * @returns {{ logout: () => Promise<void> }}
 */
const useSessionManager = (inactivityTimeout: number = 30 * 60 * 1000): SessionManagerReturn => {
  const { user, loading: authLoading } = useAuth() as AuthContextType;
  const auth: Auth = getAuth();
  const db: Database = getDatabase();

  // Ref'ler
  const lastActivityRef = useRef<number>(Date.now());
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const dbUpdateTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isUserActiveRef = useRef<boolean>(true);
  const hasShownTimeoutToast = useRef<boolean>(false);

  // Sabitler
  const DB_UPDATE_INTERVAL = 60000; // 1 dakika

  // Veritabanı referanslarını oluşturan yardımcı fonksiyon
  const createDbRefs = useCallback((userId: string) => {
    return {
      userLastActiveRef: ref(db, `userLastActive/${userId}`),
      userStatusRef: ref(db, `userStatus/${userId}`),
      userDisconnectRef: ref(db, `userDisconnectTime/${userId}`)
    };
  }, [db]);

  // Kullanıcı oturumunu kapatan ana fonksiyon
  // handleLogout fonksiyonunda düzenleme
  const handleLogout = useCallback(async (userId: string, showToast: boolean = true): Promise<void> => {
    try {
      if (!auth.currentUser) return;

      // Önce zamanlayıcıları temizle
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }

      if (dbUpdateTimeoutRef.current) {
        clearTimeout(dbUpdateTimeoutRef.current);
        dbUpdateTimeoutRef.current = null;
      }

      const { userLastActiveRef, userStatusRef, userDisconnectRef } = createDbRefs(userId);

      // Sonra durumu güncelle
      await Promise.all([
        set(userStatusRef, "logged_out"),
        set(userDisconnectRef, serverTimestamp()),
        remove(userLastActiveRef)
      ]);

      // En son oturumu kapat
      await signOut(auth);

      // Kullanıcı artık aktif olmadığını işaretle
      isUserActiveRef.current = false;

      if (showToast && !hasShownTimeoutToast.current) {
        toast.success("Başarıyla çıkış yapıldı.");
      }
    } catch (error) {
      console.error("Çıkış yapma hatası:", error);
      try {
        // Zamanlayıcıları temizle
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
          timeoutIdRef.current = null;
        }

        if (dbUpdateTimeoutRef.current) {
          clearTimeout(dbUpdateTimeoutRef.current);
          dbUpdateTimeoutRef.current = null;
        }

        isUserActiveRef.current = false;
        await signOut(auth);
      } catch (signOutError) {
        console.error("İkincil çıkış hatası:", signOutError);
      }
    }
  }, [auth, createDbRefs]);

  // Kullanıcı aktivitesini güncelleyen fonksiyon
  const updateActivity = useCallback(() => {
    lastActivityRef.current = Date.now();
    isUserActiveRef.current = true;

    // Zamanlayıcıyı yenile
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    timeoutIdRef.current = setTimeout(() => {
      if (user && isUserActiveRef.current) {
        isUserActiveRef.current = false;

        // Süre dolduğunda çıkış yap
        if (!hasShownTimeoutToast.current) {
          toast.info("Oturum süreniz doldu. Lütfen tekrar giriş yapın.");
          hasShownTimeoutToast.current = true;
        }

        handleLogout(user.uid, false);
      }
    }, inactivityTimeout);
  }, [user, inactivityTimeout, handleLogout]);

  // Veritabanını periyodik olarak güncelleyen fonksiyon
  const scheduleDbUpdate = useCallback((userId: string) => {
    if (dbUpdateTimeoutRef.current) {
      clearTimeout(dbUpdateTimeoutRef.current);
    }

    const { userLastActiveRef, userStatusRef } = createDbRefs(userId);

    const updateDb = async () => {
      // Kullanıcı aktif değilse veya oturum açık değilse güncellemeyi durdur
      if (!isUserActiveRef.current || !auth.currentUser) {
        if (dbUpdateTimeoutRef.current) {
          clearTimeout(dbUpdateTimeoutRef.current);
          dbUpdateTimeoutRef.current = null;
        }
        return;
      }

      try {
        await Promise.all([
          set(userLastActiveRef, Date.now()),
          set(userStatusRef, "online")
        ]);

        // Bir sonraki güncellemeyi zamanla
        dbUpdateTimeoutRef.current = setTimeout(() => updateDb(), DB_UPDATE_INTERVAL);
      } catch (error: any) {
        console.error("Veritabanı güncelleme hatası:", error);

        // Hata bir izin hatası ise ve kullanıcı oturumu kapalıysa, tekrar denemeyi bırak
        if (error.message && error.message.includes("PERMISSION_DENIED") && !auth.currentUser) {
          if (dbUpdateTimeoutRef.current) {
            clearTimeout(dbUpdateTimeoutRef.current);
            dbUpdateTimeoutRef.current = null;
          }
          return;
        }

        // Diğer hata durumlarında tekrar dene
        dbUpdateTimeoutRef.current = setTimeout(() => updateDb(), 5000);
      }
    };

    // İlk güncellemeyi başlat
    updateDb();
  }, [createDbRefs, auth.currentUser]);

  // Ana Effect - Kullanıcı oturumunu izleme
  useEffect(() => {
    if (!user || authLoading) return;

    let mounted = true;
    const userId = user.uid;
    const { userLastActiveRef, userStatusRef, userDisconnectRef } = createDbRefs(userId);

    // Başlangıç durumunu ayarla
    const initializeSession = async () => {
      try {
        // Oturum başlangıç bilgilerini ayarla
        await Promise.all([
          set(userLastActiveRef, Date.now()),
          set(userStatusRef, "online"),
          set(userDisconnectRef, serverTimestamp())
        ]);

        // Bağlantı kesilme durumunu ayarla
        onDisconnect(userStatusRef).set("offline");
        onDisconnect(userDisconnectRef).set(serverTimestamp());

        // Durum kontrollerini başlat
        updateActivity();
        scheduleDbUpdate(userId);
      } catch (error) {
        console.error("Oturum başlatma hatası:", error);
      }
    };

    // Oturumu başlat
    initializeSession();

    // Aktivite dinleyicilerini ekle
    const activityEvents = [
      "mousedown", "mousemove", "keypress", "scroll", "touchstart",
      "click", "focus", "input"
    ];

    // Aktivite dinleyicisi - throttle ile optimize edilmiş
    let lastEventTime = 0;
    const EVENT_THROTTLE = 1000; // 1 saniye

    const handleUserActivity = () => {
      const now = Date.now();
      if (now - lastEventTime > EVENT_THROTTLE) {
        lastEventTime = now;
        updateActivity();
      }
    };

    // Dinleyicileri ekle
    activityEvents.forEach(event => {
      window.addEventListener(event, handleUserActivity);
    });

    // Görünürlük değişikliğini izle
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "visible") {
        try {
          // Sayfa görünür olduğunda son durumu kontrol et
          const snapshot = await get(userLastActiveRef);
          const now = Date.now();

          if (snapshot.exists()) {
            const lastActivity = snapshot.val();
            if (now - lastActivity >= inactivityTimeout) {
              // Zaman aşımı oluşmuşsa
              if (!hasShownTimeoutToast.current && mounted) {
                toast.info("Oturum süreniz doldu. Lütfen tekrar giriş yapın.");
                hasShownTimeoutToast.current = true;
              }
              await handleLogout(userId, false);
            } else {
              // Hala aktifse, etkinliği güncelle
              updateActivity();
            }
          } else {
            // Veritabanında kayıt yoksa yeni oluştur
            updateActivity();
            await set(userLastActiveRef, now);
          }
        } catch (error) {
          console.error("Görünürlük kontrolü hatası:", error);
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Temizleme işlemleri
    return () => {
      mounted = false;

      // Aktivite dinleyicilerini kaldır
      activityEvents.forEach(event => {
        window.removeEventListener(event, handleUserActivity);
      });

      // Görünürlük dinleyicisini kaldır
      document.removeEventListener("visibilitychange", handleVisibilityChange);

      // Zamanlayıcıları temizle
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
      }

      if (dbUpdateTimeoutRef.current) {
        clearTimeout(dbUpdateTimeoutRef.current);
        dbUpdateTimeoutRef.current = null;
      }
    };
  }, [
    user,
    authLoading,
    createDbRefs,
    handleLogout,
    updateActivity,
    scheduleDbUpdate,
    inactivityTimeout,
    db
  ]);

  // Manuel çıkış fonksiyonu
  const logout = useCallback(async (): Promise<void> => {
    if (user) {
      hasShownTimeoutToast.current = false; // Reset toast durumu
      await handleLogout(user.uid, true);
    }
  }, [user, handleLogout]);

  return { logout };
};

export default useSessionManager;