// Base Styles
import borders from "../../../../assets/theme/base/borders";

// Helper Functions
import pxToRem from "../../../../assets/theme/functions/pxToRem";

const { borderRadius } = borders;

interface CardMediaRootStyles {
  borderRadius: string | number;
  margin: string;
}

interface CardMediaMediaStyles {
  width: string;
}

interface CardMediaStyleOverrides {
  root: CardMediaRootStyles;
  media: CardMediaMediaStyles;
}

interface CardMediaStyles {
  styleOverrides: CardMediaStyleOverrides;
}

const cardMedia: CardMediaStyles = {
  styleOverrides: {
    root: {
      borderRadius: borderRadius.xl,
      margin: `${pxToRem(16)} ${pxToRem(16)} 0`,
    },

    media: {
      width: "auto",
    },
  },
};

export default cardMedia;