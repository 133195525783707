// base styles
import borders from "../../../../assets/theme/base/borders";
import boxShadows from "../../../../assets/theme/base/boxShadows";

// TypeScript interfaces
interface BordersType {
  borderRadius: {
    lg: string | number;
  };
}

interface BoxShadowsType {
  xxl: string;
}

// Type assertions for imported variables
const { borderRadius } = borders as BordersType;
const { xxl } = boxShadows as BoxShadowsType;

// Dialog component type definition
interface DialogStyleOverrides {
  styleOverrides: {
    paper: {
      borderRadius: string | number;
      boxShadow: string;
    };
    paperFullScreen: {
      borderRadius: number;
    };
  };
}

const dialog: DialogStyleOverrides = {
  styleOverrides: {
    paper: {
      borderRadius: borderRadius.lg,
      boxShadow: xxl,
    },

    paperFullScreen: {
      borderRadius: 0,
    },
  },
};

export default dialog;