import colors from "../../../assets/theme/base/colors";

const { transparent } = colors;

interface IconButtonStyleOverrides {
  root: {
    "&:hover": {
      backgroundColor: string;
    };
  };
}

interface IconButton {
  styleOverrides: IconButtonStyleOverrides;
}

const iconButton: IconButton = {
  styleOverrides: {
    root: {
      "&:hover": {
        backgroundColor: transparent.main,
      },
    },
  },
};

export default iconButton;