// base styles
import colors from "../../../../assets/theme/base/colors";

const { text } = colors;

interface FormLabelStyleOverrides {
  root: React.CSSProperties;
}

interface FormLabelStyles {
  styleOverrides: FormLabelStyleOverrides;
}

const formLabel: FormLabelStyles = {
  styleOverrides: {
    root: {
      color: text.main,
    },
  },
};

export default formLabel;