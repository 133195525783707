import { Theme, Components } from "@mui/material/styles";

// MUI v5 formatında ListItem bileşeni
const listItem: Components<Theme>["MuiListItem"] = {
  defaultProps: {
    disableGutters: true,
  },
  styleOverrides: {
    root: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
};

export default listItem;