// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import { Box, Icon, Tooltip } from "@mui/material";

//  components
import MDTypography from "../../../../components/MDTypography";
import MDBox from "../../../../components/MDBox";
import MDAvatar from "../../../../components/MDAvatar";

// Define TypeScript interfaces for props
interface Author {
  // Define the properties of the author object based on your needs
  // For example:
  name?: string;
  avatar?: string;
  id?: string | number;
}

interface ActionProps {
  type: "external" | "internal";
  route: string;
  color: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "white";
  label: string;
}

interface DefaultProjectCardProps {
  image: string;
  label: string;
  title: string;
  description: string;
  action: ActionProps;
  author?: Author;
  date?: string;
}

function DefaultProjectCard({
  image = "",
  label = "",
  title = "",
  description = "",
  action,
  author = { name: "John Michael" }, // Default value is set here
  date = "22.04.18",
}: DefaultProjectCardProps) {

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        boxShadow: "none",
        overflow: "visible",
        padding: 1,
      }}
    >
      <Box
        component={Link}
        to={action.type === "internal" ? action.route : action.type === "external" ? action.route : "/"}
        target={action.type === "external" ? "_blank" : "_self"}
      >
        <Box
          position="relative"
          borderRadius="lg"
          mt={1}
        >
          <MDBox
            component="img"
            src={image}
            alt={title}
            borderRadius="lg"
            shadow="md"
            width="100%"
            height={{ xs: "280px", md: "240px", lg: "200px", xl: "160px" }}
            position="relative"
            zIndex={1}
          />
          <MDBox
            borderRadius={"lg"}
            shadow="md"
            width="100%"
            height="100%"
            position="absolute"
            left={0}
            top="3%"
            sx={{
              backgroundImage: `url(${image})`,
              transform: "scale(0.94)",
              filter: "blur(12px)",
              backgroundSize: "cover",
            }}
          />
        </Box>
        <Box mt={1} mx={0.5}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="left"
            mb={1}
            sx={{
              height: "20px", // Sabit yükseklik
            }}
          >
            <Icon fontSize="inherit" color="secondary" sx={{ mr: 0.5 }}>label</Icon>
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              textTransform="capitalize"
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "100%",
                lineHeight: "1.5",
              }}
            >
              {label}
            </MDTypography>
          </Box>
          <Box mb={1}>
            <MDTypography
              variant="h5"
              textTransform="capitalize"
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineHeight: "1.5",
                height: "3em", // 2 satır için sabit yükseklik
              }}
            >
              {title}
            </MDTypography>
          </Box>
          <Box
            mb={1}
            sx={{
              height: "4.1em", // 4 satır için yükseklik (1.5em x 4)
            }}
          >
            <MDTypography
              variant="button"
              fontWeight="light"
              color="text"
              textTransform="capitalize"
              sx={{
                display: "-webkit-box",
                WebkitLineClamp: "4",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineHeight: "1.5",
                height: "100%",
              }}
            >
              {description}
            </MDTypography>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
        px={1}
      >
        <MDTypography variant="button">{date}</MDTypography>
        <Box display="flex" alignItems="center" gap={1}>
          <MDTypography variant="button">Yazar:</MDTypography>
          <Tooltip title={author.name} placement="bottom" disableInteractive>
            <MDAvatar
              src={author.avatar}
              alt={author.name}
              size="sm"
              sx={() => ({
                border: `8px solid transparent`,
                cursor: "pointer",
                position: "relative",
                ml: -1.25,

                "&:hover, &:focus": {
                  zIndex: "10",
                },
              })}
            />
          </Tooltip>
        </Box>
      </Box>
    </Card>
  );
}

export default DefaultProjectCard;