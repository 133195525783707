// Base Styles
import colors from "../../../../assets/theme/base/colors";
import typography from "../../../../assets/theme/base/typography";

// Helper Functions
import pxToRem from "../../../../assets/theme/functions/pxToRem";

// TypeScript tip tanımlamaları
interface Colors {
  transparent: {
    main: string;
  };
  light: {
    main: string;
  };
  info: {
    main: string;
  };
  secondary: {
    main: string;
  };
}

interface Typography {
  size: {
    xs: string;
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
}

interface StyleDefinition {
  minHeight?: string;
  color?: string;
  borderColor?: string;
  padding?: string;
  backgroundColor?: string;
  fontSize?: string;
  "&:hover"?: {
    opacity?: number;
    backgroundColor?: string;
  };
  "& .material-icon, .material-icons-round, svg"?: {
    fontSize: string;
  };
}

interface OutlinedButtonStyles {
  base: StyleDefinition;
  small: StyleDefinition;
  large: StyleDefinition;
  primary: StyleDefinition;
  secondary: StyleDefinition;
}

const { transparent, light, info, secondary } = colors as Colors;
const { size } = typography as Typography;

const outlined: OutlinedButtonStyles = {
  base: {
    minHeight: pxToRem(40),
    color: light.main,
    borderColor: light.main,
    padding: `${pxToRem(10)} ${pxToRem(24)}`,

    "&:hover": {
      opacity: 0.75,
      backgroundColor: transparent.main,
    },

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(16)} !important`,
    },
  },

  small: {
    minHeight: pxToRem(32),
    padding: `${pxToRem(6)} ${pxToRem(16)}`,
    fontSize: size.xs,
    borderColor: light.main,

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(12)} !important`,
    },
  },

  large: {
    minHeight: pxToRem(47),
    padding: `${pxToRem(12)} ${pxToRem(28)}`,
    fontSize: size.sm,
    borderColor: light.main,

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(22)} !important`,
    },
  },

  primary: {
    backgroundColor: transparent.main,
    borderColor: info.main,
    minHeight: pxToRem(40),
    padding: `${pxToRem(10)} ${pxToRem(24)}`,

    "&:hover": {
      backgroundColor: transparent.main,
    },
  },

  secondary: {
    backgroundColor: transparent.main,
    borderColor: secondary.main,
    minHeight: pxToRem(40),
    padding: `${pxToRem(10)} ${pxToRem(24)}`,

    "&:hover": {
      backgroundColor: transparent.main,
    },
  },
};

export default outlined;