import { initializeApp } from "firebase/app";
// import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAuth } from "firebase/auth";
import { collection, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// Koleksiyon referansları
export const postsRef = collection(db, "posts");

export const commentsRef = (postId: string | undefined) =>
  collection(db, `posts/${postId}/comments`);

// if (!process.env.REACT_APP_RECAPTCHA_SITE_KEY) {
//   throw new Error("RECAPTCHA_SITE_KEY is not defined");
// } else {
//   console.log("RECAPTCHA_SITE_KEY is defined");
// }

// export const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_SITE_KEY),
//   isTokenAutoRefreshEnabled: true,
// });

export { firebaseConfig };
export default app;
