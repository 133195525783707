interface LinkDefaultProps {
  underline: string;
  color: string;
}

interface Link {
  defaultProps: LinkDefaultProps;
}

const link: Link = {
  defaultProps: {
    underline: "none",
    color: "inherit",
  },
};

export default link;