import { DocumentData, FirestoreError, QueryDocumentSnapshot, Timestamp } from "firebase/firestore";
import { StaticPage } from "../types";

export function generateSlug(title: string) {
    // Türkçe karakter eşleştirme tablosu
    const turkishChars = {
        ğ: "g",
        Ğ: "g",
        ü: "u",
        Ü: "u",
        ş: "s",
        Ş: "s",
        ı: "i",
        İ: "i",
        ö: "o",
        Ö: "o",
        ç: "c",
        Ç: "c",
    };

    return (
        title
            // Önce Türkçe karakterleri dönüştür
            .replace(/[ğĞüÜşŞıİöÖçÇ]/g, (match) => turkishChars[match as keyof typeof turkishChars])
            // Küçük harfe çevir
            .toLowerCase()
            // Başındaki ve sonundaki boşlukları temizle
            .trim()
            // Alfanumerik olmayan karakterleri temizle (tire hariç)
            .replace(/[^a-z0-9\s-]/g, "")
            // Birden fazla boşluğu tek tire ile değiştir
            .replace(/\s+/g, "-")
            // Birden fazla tireyi tek tireye dönüştür
            .replace(/-+/g, "-")
            // Başındaki ve sonundaki tireleri temizle
            .replace(/^-+|-+$/g, "")
    );
}

// Post verilerindeki Timestamp'i dönüştürmek için yardımcı fonksiyon
export function convertFirestoreTimestamps(obj: any) {
    // Null veya undefined kontrolü
    if (!obj) return obj;

    // Objemizin bir kopyasını oluşturuyoruz
    const converted = { ...obj };

    // Her bir alanı kontrol ediyoruz
    Object.keys(converted).forEach(key => {
        const value = converted[key];

        // Timestamp kontrolü ve dönüşümü
        if (value && typeof value.toDate === 'function') {
            converted[key] = value.toDate().toISOString();
        }
        // Nested objeleri recursive olarak kontrol ediyoruz
        else if (value && typeof value === 'object' && !Array.isArray(value)) {
            converted[key] = convertFirestoreTimestamps(value);
        }
        // Array'leri kontrol ediyoruz
        else if (Array.isArray(value)) {
            converted[key] = value.map(item =>
                typeof item === 'object' ? convertFirestoreTimestamps(item) : item
            );
        }
    });

    return converted;
}

export const formatDateFromTimestamp = (timestamp: Timestamp) => {
    if (!timestamp) return "-";
    return new Date(timestamp.seconds * 1000).toLocaleDateString("tr-TR");
};


// utils/index.ts içinde
export function formatDateFromDate(date: Date | string): string {
    // String ise Date nesnesine dönüştür
    const dateObj = date instanceof Date ? date : new Date(date);

    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear();

    return `${day}.${month}.${year}`;
}

export const handleFirestoreError = (error: unknown): { error: string } => {
  if (error instanceof FirestoreError) {
    console.error("Firestore error:", error);
    return { error: `Veritabanı hatası: ${error.message}` };
  }
  console.error("Beklenmeyen hata:", error);
  return { error: "Beklenmeyen bir hata oluştu" };
};

// Firestore'dan gelen veriyi StaticPage tipine dönüştüren yardımcı fonksiyon
export const convertToStaticPage = (doc: QueryDocumentSnapshot<DocumentData>): StaticPage & { id: string } => {
    const data = doc.data();
    return {
        id: doc.id,
        ...data,
        // Firestore Timestamp'ı ISO string formatına dönüştürme
        lastUpdated: data.lastUpdated instanceof Timestamp
            ? data.lastUpdated.toDate().toISOString()
            : typeof data.lastUpdated === 'object' && data.lastUpdated instanceof Date
                ? data.lastUpdated.toISOString()
                : data.lastUpdated || new Date().toISOString()
    } as StaticPage & { id: string };
};