import { useState, useCallback, useRef, useEffect } from "react";
import {
    collection,
    query,
    getDocs,
    addDoc,
    serverTimestamp,
} from "firebase/firestore";
import { db } from "../../../lib/firebase";
// import { appCheck } from "../../../lib/firebase";
// import { getToken } from "firebase/app-check";
import { Comment, UserAccountInfo } from "../types";
import { organizeComments, sortComments, generateRandomId } from "../utils/commentHelpers";

/**
 * Custom hook for handling comments functionality
 */
export const useComments = (
    postId: string | undefined,
    userAccountInfo: UserAccountInfo
) => {
    const [newComment, setNewComment] = useState<string>("");
    const [commenting, setCommenting] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [sortOrder, setSortOrder] = useState<string>("newest");

    // Store raw comments data 
    const commentsData = useRef<Comment[]>([]);
    // Store sorted comments for display
    const [sortedComments, setSortedComments] = useState<Comment[]>([]);

    /**
     * Fetches comments for a post
     */
    const fetchComments = useCallback(async (postId: string) => {
        try {
            const commentsRef = collection(db, `posts/${postId}/comments`);
            const q = query(commentsRef);
            const querySnapshot = await getDocs(q);

            const fetchedComments: Comment[] = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                content: doc.data().content,
                authorId: doc.data().authorId,
                authorName: doc.data().authorName,
                isApproved: doc.data().isApproved,
                ...doc.data(),
            }));

            // Organize comments and store the raw organized data
            const organizedComments = organizeComments(fetchedComments);
            commentsData.current = organizedComments;

            // Sort and update the display comments
            const sorted = sortComments(organizedComments, sortOrder);
            setSortedComments(sorted);
        } catch (err) {
            console.error("Error fetching comments:", err);
            setError("Failed to load comments");
        }
    }, [sortOrder]);

    /**
     * Handles comment sort order changes
     */
    const handleSortOrderChange = useCallback((newSortOrder: string) => {
        // Set a flag to temporarily disable highlighting
        const contentContainer = document.querySelector(".code-content");
        if (contentContainer) {
            // Add a class that prevents observer from triggering
            contentContainer.classList.add("sorting-in-progress");
        }

        // Change the sort order
        setSortOrder(newSortOrder);

        // After a short delay, remove the prevention class
        setTimeout(() => {
            if (contentContainer) {
                contentContainer.classList.remove("sorting-in-progress");
            }
        }, 100);
    }, []);

    /**
     * Adds a new comment to the post
     */
    const handleAddComment = useCallback(async () => {
        if (!newComment.trim() || !postId) return;

        setCommenting(true);
        try {
            // const token = await getToken(appCheck);

            // Token check
            // if (!token || !token.token) {
            //     throw new Error("Security validation failed");
            // }

            const commentsRef = collection(db, `posts/${postId}/comments`);

            await addDoc(commentsRef, {
                content: newComment,
                authorId: userAccountInfo?.uid || generateRandomId(),
                authorName: userAccountInfo?.fullName || "Ziyaretçi",
                authorAvatarUrl: userAccountInfo?.avatarUrl || "",
                isApproved:
                    userAccountInfo?.role === "admin" ||
                        userAccountInfo?.role === "editor"
                        ? true
                        : false, // Default approval status
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
            });

            // Reload comments
            await fetchComments(postId);
            setNewComment("");
        } catch (err) {
            console.error("Error adding comment:", err);
            setError("Comment could not be added");
        } finally {
            setCommenting(false);
        }
    }, [postId, newComment, userAccountInfo, fetchComments]);

    /**
     * Handles replying to a comment
     */
    const handleReply = useCallback(async (parentCommentId: string, content: string) => {
        if (!content.trim() || !postId) return;

        try {
            const commentsRef = collection(db, `posts/${postId}/comments`);
            await addDoc(commentsRef, {
                content,
                parentId: parentCommentId,
                authorId: userAccountInfo?.uid || generateRandomId(),
                authorName: userAccountInfo?.fullName || "Ziyaretçi",
                authorAvatarUrl: userAccountInfo?.avatarUrl || "",
                isApproved:
                    userAccountInfo?.role === "admin" ||
                        userAccountInfo?.role === "editor"
                        ? true
                        : false,
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
            });

            // Reload comments
            await fetchComments(postId);
        } catch (err) {
            console.error("Error adding reply:", err);
            setError("Reply could not be added");
        }
    }, [postId, userAccountInfo, fetchComments]);

    // Effect to resort comments when sort order changes
    useEffect(() => {
        if (commentsData.current.length > 0) {
            const sorted = sortComments(commentsData.current, sortOrder);
            setSortedComments(sorted);
        }
    }, [sortOrder]);

    // Initial comment fetch
    useEffect(() => {
        if (postId) {
            fetchComments(postId);
        }
    }, [postId, fetchComments]);

    return {
        newComment,
        setNewComment,
        commenting,
        error,
        sortOrder,
        sortedComments,
        handleSortOrderChange,
        handleAddComment,
        handleReply,
        fetchComments,
    };
};