interface ButtonBase {
  defaultProps: {
    disableRipple: boolean;
  };
}

const buttonBase: ButtonBase = {
  defaultProps: {
    disableRipple: false,
  },
};

export default buttonBase;