//  base styles
import borders from "../../../../assets/theme/base/borders";
import colors from "../../../../assets/theme/base/colors";

//  helper functions
import pxToRem from "../../../../assets/theme/functions/pxToRem";

const { borderWidth } = borders;
const { light } = colors;

interface TableCellStyleOverrides {
  root: {
    padding: string;
    borderBottom: string;
  };
}

interface TableCellStyles {
  styleOverrides: TableCellStyleOverrides;
}

const tableCell: TableCellStyles = {
  styleOverrides: {
    root: {
      padding: `${pxToRem(12)} ${pxToRem(16)}`,
      borderBottom: `${borderWidth[1]} solid ${light.main}`,
    },
  },
};

export default tableCell;
