import { Skeleton } from "@mui/material";
import { Box } from "@mui/material";

interface MDLoadingSkeletonProps {
    variant?: 'text' | 'rectangular' | 'rounded' | 'circular';
    width?: number | string;
    height?: number | string;
}

function MDLoadingSkeleton({ variant='text', width=50, height=50 }: MDLoadingSkeletonProps) {
    return (
        <Box>
            <Skeleton variant={variant} width={width} height={height} sx={{ mb: 2 }} />
            <Skeleton variant={variant} width={width} height={width ? width : 100} />
            <Box mt={1}>
                {[...Array(3)].map((_, index) => (
                    <Skeleton key={index} height={40} sx={{ my: 1 }} />
                ))}
            </Box>
        </Box>
    );
}

export default MDLoadingSkeleton;