import pxToRem from "../../../assets/theme/functions/pxToRem";

interface SvgIconDefaultProps {
  fontSize: string;
}

interface SvgIconStyleOverrides {
  fontSizeInherit: {
    fontSize: string;
  };
  fontSizeSmall: {
    fontSize: string;
  };
  fontSizeLarge: {
    fontSize: string;
  };
}

interface SvgIcon {
  defaultProps: SvgIconDefaultProps;
  styleOverrides: SvgIconStyleOverrides;
}

const svgIcon: SvgIcon = {
  defaultProps: {
    fontSize: "inherit",
  },

  styleOverrides: {
    fontSizeInherit: {
      fontSize: "inherit !important",
    },

    fontSizeSmall: {
      fontSize: `${pxToRem(20)} !important`,
    },

    fontSizeLarge: {
      fontSize: `${pxToRem(36)} !important`,
    },
  },
};

export default svgIcon;