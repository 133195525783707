// prop-types is not needed in TypeScript since we'll use TS interfaces
// import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// import MDTypography from "components/MDTypography";

//  example components
import PageLayout from "../../../../examples/LayoutContainers/PageLayout";

// Authentication layout components
import { Box } from "@mui/material";
import MDBox from "../../../../components/MDBox";

// Define the props interface for the CoverLayout component
interface CoverLayoutProps {
  coverHeight?: string;
  image: string;
  children: React.ReactNode;
}

// Define the expected structure of the theme functions for type safety
interface ThemeFunctions {
  linearGradient: (color1: string, color2: string) => string;
  rgba: (color: string, opacity: number) => string;
}

interface ThemePalette {
  gradients: {
    dark: {
      main: string;
      state: string;
    };
  };
}

interface ThemeProps {
  functions: ThemeFunctions;
  palette: ThemePalette;
}

function CoverLayout({ coverHeight = "35vh", image, children }: CoverLayoutProps) {
  return (
    <PageLayout>
      <MDBox
        width="calc(100% - 2rem)"
        minHeight={coverHeight}
        borderRadius="xl"
        mx={2}
        my={2}
        pt={6}
        pb={28}
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }: ThemeProps) =>
            image &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.4),
              rgba(gradients.dark.state, 0.4)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />
      <Box
        mt={{ xs: -20, lg: -18 }}
        px={1}
        mb={2}
        width="calc(100% - 2rem)"
        mx="auto"
      >
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </PageLayout>
  );
}

// No need for PropTypes or defaultProps in TypeScript
// We handle defaults in the function parameter destructuring

export default CoverLayout;