// hooks/useThemeSettings.js
import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../lib/firebase"; // Firebase yapılandırma dosyanızın yolu

export interface ThemeSettings {
  darkMode: boolean;
  direction: string;
  fixedNavbar: boolean;
  layout: string;
  miniSidenav: boolean;
  openConfigurator: boolean;
  sidenavColor: string;
  sidenavListSize: number;
  transparentNavbar: boolean;
  transparentSidenav: boolean;
  whiteSidenav: boolean;
  layoutWidth: number;
  postPerPage: number;
}

export const useThemeSettings = () => {
  const [themeSettings, setThemeSettings] = useState<ThemeSettings>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchThemeSettings = async () => {
      try {
        const templateDoc = doc(db, "blog", "template");
        const docSnap = await getDoc(templateDoc);

        if (docSnap.exists()) {
          const { startSettings } = docSnap.data();
          setThemeSettings(startSettings);
        } else {
          setError("Template document not found");
        }
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(err.message);
        }
        else {
          setError("An error occurred while fetching theme settings");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchThemeSettings();
  }, []);

  return { themeSettings, loading, error };
};
