import { forwardRef, ReactNode } from "react";

// Custom styles for MDBox
import MDBoxRoot from "../../components/MDBox/MDBoxRoot";
import { useTheme } from "@mui/material";
import { CustomTheme } from "../../assets/theme/types/CustomTheme";

// Define MDBox props interface
interface MDBoxProps {
  variant?: "contained" | "gradient";
  bgcolor?: string;
  color?: string;
  opacity?: number;
  borderRadius?: string;
  shadow?: string;
  coloredshadow?: "primary" | "secondary" | "info" | "success" | "warning" | "error" | "light" | "dark" | "none";
  children?: ReactNode;
  [key: string]: any;
}

const MDBox = forwardRef<HTMLDivElement, MDBoxProps>(
  ({ variant = "contained",
    bgcolor = "transparent",
    color = "dark",
    opacity = 1,
    borderRadius = "none",
    shadow = "none",
    coloredshadow = "none",
    ...rest
  }, ref) => {
    const theme = useTheme() as CustomTheme;
    return (
      <MDBoxRoot
        {...rest}
        ref={ref}
        theme={theme}
        ownerState={{ variant, bgcolor, color, opacity, borderRadius, shadow, coloredshadow }}
      />
    )
  }
);

export default MDBox;