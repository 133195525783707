import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Alert } from "@mui/material";

interface PasswordResetDialogProps {
    open: boolean;
    handleClose: () => void;
    resetEmail: string;
    setResetEmail: (email: string) => void;
    handlePasswordReset: () => Promise<void>;
    resetError: string;
    resetSuccess: boolean;
}

/**
 * Dialog component for password reset functionality
 */
const PasswordResetDialog: React.FC<PasswordResetDialogProps> = ({
    open,
    handleClose,
    resetEmail,
    setResetEmail,
    handlePasswordReset,
    resetError,
    resetSuccess,
}) => {
    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
            <DialogTitle>Şifre Sıfırlama</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Şifrenizi sıfırlamak için kayıtlı e-posta adresinizi girin. Size
                    şifrenizi sıfırlamanız için bir bağlantı göndereceğiz.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="E-posta Adresi"
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={resetEmail}
                    onChange={(e) => setResetEmail(e.target.value)}
                />
                {resetError && <Alert severity="error" sx={{ mt: 2 }}>{resetError}</Alert>}
                {resetSuccess && (
                    <Alert severity="success" sx={{ mt: 2 }}>
                        Şifre sıfırlama bağlantısı e-posta adresinize gönderildi.
                    </Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    İptal
                </Button>
                <Button
                    onClick={handlePasswordReset}
                    color="primary"
                    disabled={resetSuccess}
                >
                    Sıfırlama Bağlantısı Gönder
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PasswordResetDialog;