import { styled } from "@mui/material/styles";
import { CodeContentProps } from "../types";

/**
 * Styled component for code content with syntax highlighting
 */
export const CodeContent = styled("div")<CodeContentProps>(({ theme, darkMode }) => ({
    "& pre": {
        margin: "1rem 0",
        padding: "2.5rem 1rem 1rem", // Added top padding for language tag
        borderRadius: "8px",
        backgroundColor: darkMode ? "#1a1a1a" : "#f5f5f5",
        overflowX: "auto",
        position: "relative",
        fontSize: "0.9rem",
        lineHeight: "1.5",
        "&::before": {
            // Language tag
            content: "attr(data-language)",
            position: "absolute",
            top: "0.5rem",
            left: "1rem",
            color: darkMode ? "#ffffff80" : "#00000080",
            fontSize: "0.75rem",
            textTransform: "uppercase",
            fontFamily: "system-ui, -apple-system, sans-serif",
        },
    },
    "& code": {
        fontFamily: "'Fira Code', 'Consolas', monospace",
        fontSize: "0.9rem",
        wordSpacing: "normal",
        wordWrap: "normal",
        lineHeight: "1.5",
        tabSize: "4",
        hyphens: "none",
        maxWidth: "100%",
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
    },
    // Theme colors
    "& .hljs": {
        color: darkMode ? "#abb2bf" : "#383a42",
        background: "transparent",
    },
    "& .hljs-comment": {
        color: darkMode ? "#5c6370" : "#a0a1a7",
        fontStyle: "italic",
    },
    "& .hljs-keyword": {
        color: darkMode ? "#c678dd" : "#a626a4",
    },
    "& .hljs-string": {
        color: darkMode ? "#98c379" : "#50a14f",
    },
    "& .hljs-number": {
        color: darkMode ? "#d19a66" : "#986801",
    },
    "& .hljs-function": {
        color: darkMode ? "#61afef" : "#4078f2",
    },
    "& .hljs-title": {
        color: darkMode ? "#61afef" : "#4078f2",
    },
    "& .hljs-params": {
        color: darkMode ? "#abb2bf" : "#383a42",
    },
    "& .hljs-built_in": {
        color: darkMode ? "#e6c07b" : "#c18401",
    },
}));