import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css";

// Import language definitions
import bash from "highlight.js/lib/languages/bash";
import shell from "highlight.js/lib/languages/shell";
import xml from "highlight.js/lib/languages/xml";
import javascript from "highlight.js/lib/languages/javascript";
import css from "highlight.js/lib/languages/css";

// Register languages with highlight.js
export const initializeHighlightJs = (): void => {
    hljs.registerLanguage("bash", bash);
    hljs.registerLanguage("shell", shell);
    hljs.registerLanguage("html", xml);
    hljs.registerLanguage("xml", xml);
    hljs.registerLanguage("javascript", javascript);
    hljs.registerLanguage("js", javascript);
    hljs.registerLanguage("css", css);
};

/**
 * Adds a copy button to code blocks
 * @param pre The pre element containing code
 * @param darkMode Whether dark mode is enabled
 */
export const addCopyButton = (pre: HTMLElement, darkMode: boolean): void => {
    // Only add copy button if not already present
    if (pre.querySelector(".code-header")) {
        return;
    }

    // Add copy button
    const header = document.createElement("div");
    header.className = "code-header";
    header.style.cssText = `
    position: absolute;
    top: 0.5rem;
    right: 1rem;
    z-index: 1;
  `;

    const copyButton = document.createElement("button");
    copyButton.textContent = "Kopyala";
    copyButton.className = "copy-button";
    copyButton.style.cssText = `
    background: ${darkMode ? "#333" : "#e0e0e0"};
    color: ${darkMode ? "#fff" : "#666"};
    border: none;
    padding: 0.2rem 0.8rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.8rem;
    transition: all 0.2s ease;
  `;

    copyButton.onclick = async () => {
        try {
            const codeElement = pre.querySelector("code");
            await navigator.clipboard.writeText(codeElement?.textContent || "");
            copyButton.textContent = "Kopyalandı!";
            copyButton.style.backgroundColor = "#4caf50";
            setTimeout(() => {
                copyButton.textContent = "Kopyala";
                copyButton.style.backgroundColor = darkMode ? "#333" : "#e0e0e0";
            }, 2000);
        } catch (err) {
            console.error("Kopyalama hatası:", err);
        }
    };

    header.appendChild(copyButton);
    pre.appendChild(header);
};

/**
 * Highlights code blocks in the content
 * @param darkMode Whether dark mode is enabled
 * @returns Whether highlighting was performed
 */
export const highlightCodeBlocks = (darkMode: boolean): boolean => {
    // Select all code blocks
    const codeBlocks = document.querySelectorAll("pre code");
    if (!codeBlocks.length) return false;

    // Process each code block
    codeBlocks.forEach((block) => {
        // Skip already processed blocks
        if (block.classList.contains("hljs")) return;

        // Apply highlighting
        hljs.highlightElement(block as HTMLElement);

        const pre = block.parentElement;
        if (!pre || pre.tagName !== "PRE") return;

        // Get language information
        const language = block.className
            .split(" ")
            .find((cls) => cls.startsWith("language-"))
            ?.replace("language-", "") || "text";

        // Set up pre element
        pre.setAttribute("data-language", language);

        // Add copy button
        addCopyButton(pre as HTMLElement, darkMode);
    });

    return codeBlocks.length > 0;
};