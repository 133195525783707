import { Theme, Components } from "@mui/material/styles";

// MUI v5 formatında ListItemText bileşeni
const listItemText: Components<Theme>["MuiListItemText"] = {
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
};

export default listItemText;