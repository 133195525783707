//  base styles
import colors from "../../../../assets/theme/base/colors";
import borders from "../../../../assets/theme/base/borders";
import boxShadows from "../../../../assets/theme/base/boxShadows";

//  helper functions
import pxToRem from "../../../../assets/theme/functions/pxToRem";

const { grey, white } = colors;
const { borderRadius } = borders;
const { tabsBoxShadow } = boxShadows;

// TypeScript'te CSS özelliklerinin daha doğru temsili için
interface CustomCSSProperties extends React.CSSProperties {
  [key: string]: any;
}

interface TabsStyleOverrides {
  root: CustomCSSProperties;
  flexContainer: CustomCSSProperties;
  fixed: CustomCSSProperties;
  vertical: {
    "& .MuiTabs-indicator": CustomCSSProperties;
  };
  indicator: CustomCSSProperties;
}

interface TabsTheme {
  styleOverrides: TabsStyleOverrides;
}

const tabs: TabsTheme = {
  styleOverrides: {
    root: {
      position: "relative",
      backgroundColor: grey[100],
      borderRadius: borderRadius.xl,
      minHeight: "unset",
      padding: pxToRem(4),
    },

    flexContainer: {
      height: "100%",
      position: "relative",
      zIndex: 10,
    },

    fixed: {
      overflow: "unset !important",
      overflowX: "unset !important" as any, // TypeScript hatası için 'as any' kullanıyoruz
    },

    vertical: {
      "& .MuiTabs-indicator": {
        width: "100%",
      },
    },

    indicator: {
      height: "100%",
      borderRadius: borderRadius.lg,
      backgroundColor: white.main,
      boxShadow: tabsBoxShadow.indicator,
      transition: "all 500ms ease",
    },
  },
};

export default tabs;