import { Helmet } from 'react-helmet-async';

interface SEOHelmetProps {
  pathname: string;
}

const SITE_METADATA = {
  title: "Gkhnmr.com | Web Tasarım ve Yazılım",
  description:
    "Web tasarım ve yazılım dünyasında profesyonel çözümler ve güncel teknoloji bilgileri",
  description2:
    "Web tasarım ve yazılım dünyasında profesyonel çözümler, güncel teknoloji bilgileri ve yazılım geliştirme rehberleri",
  siteName: "Gkhnmr.com",
  keywords:
    "web tasarım, yazılım geliştirme, frontend, backend, react, javascript",
};

const MySEOHelmet = ({ pathname }: SEOHelmetProps) => {
  const currentUrl = `${window.location.origin}${pathname}`;
  const isContactPage = pathname.includes('/iletisim') || pathname === '/iletisim';

  return (
      <Helmet>
        <title>{SITE_METADATA.title}</title>
        <meta name="description" content={SITE_METADATA.description} />
        <meta name="keywords" content={SITE_METADATA.keywords} />
        {/* Google bot meta tag'i sadece iletişim sayfasında göster */}
        {isContactPage && (
          <meta name="googlebot" content="noindex, nofollow" data-react-helmet="true" />
        )}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:site_name" content={SITE_METADATA.siteName} />
        <meta property="og:title" content={SITE_METADATA.title} />
        <meta property="og:description" content={SITE_METADATA.description2} />
        <meta
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/myblog-3a6ee.firebasestorage.app/o/blog-images%2Flogo.svg?alt=media&token=863f3dcb-231a-4831-b62e-3790b1dd92ae"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:locale" content="tr_TR" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={SITE_METADATA.title} />
        <meta name="twitter:description" content={SITE_METADATA.description} />
        <meta
          name="twitter:image"
          content="https://firebasestorage.googleapis.com/v0/b/myblog-3a6ee.firebasestorage.app/o/blog-images%2Flogo.svg?alt=media&token=863f3dcb-231a-4831-b62e-3790b1dd92ae"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Gökhan Emre Kasapoğlu" />
        <link rel="canonical" href={currentUrl} />
      </Helmet>
  );
};

export default MySEOHelmet;
