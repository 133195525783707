// helper functions
import pxToRem from "../../../../assets/theme/functions/pxToRem";

interface DialogActionsStyleOverrides {
  root: {
    padding: string;
  };
}

interface DialogActionsConfig {
  styleOverrides: DialogActionsStyleOverrides;
}

const dialogActions: DialogActionsConfig = {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
    },
  },
};

export default dialogActions;