import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import Footer from "../../examples/Footer";
import { useAccount } from "../../context/AccountContext";
import MDLoadingSkeleton from "../../components/MDLoadingSkeleton";
import MDErrorDisplay from "../../components/MDErrorDisplay";
import DefaultProjectCard from "../../examples/Cards/ProjectCards/DefaultProjectCard";
import { useState } from 'react';
import MDPagination from "../../components/MDPagination";
import { useThemeSettings } from "../../hooks/useThemeSettings";
import { formatDateFromDate } from "../../utils";
import { PostWithAuthor, useGetPostsByCategoryQuery } from "../../services/postApiService";

interface PostsCategoryLayoutProps {
  transparent?: boolean;
  categoryId: string;
}

function PostsCategoryLayout({ transparent = false, categoryId = "" }: PostsCategoryLayoutProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const POSTS_PER_PAGE = useThemeSettings().themeSettings?.postPerPage ?? 16; // Her sayfada gösterilecek post sayısı

  const { isLoading, error }: any = useAccount();
  const postsCategory = useGetPostsByCategoryQuery(categoryId);

  // Loading durumunda tüm grid için tek bir loading state
  if (isLoading) {
    return (
      <Box
        px={{
          xs: 1,
          sm: transparent ? 0 : 1,
          md: transparent ? 0 : 1,
          xl: transparent ? 0 : 1,
          lg: transparent ? 0 : 1,
        }}
        my={1}
        mx={2}
      >
        <Grid container spacing={2}>
          {[...Array(3)].map((_, index) => (
            <Grid item xs={12} lg={6} key={index}>
              <MDLoadingSkeleton />
            </Grid>
          ))}
        </Grid>
        <Footer company={{ href: "/", name: "gkhnmr" }} />
      </Box>
    );
  }

  // Hata durumunda
  if (error) {
    return (
      <Box
        px={{
          xs: 1,
          sm: transparent ? 0 : 1,
          md: transparent ? 0 : 1,
          xl: transparent ? 0 : 1,
          lg: transparent ? 0 : 1,
        }}
        my={1}
        mx={2}
      >
        <MDErrorDisplay message={error} />
      </Box>
    );
  }

  const renderCategoryPosts = () => {
    if (!postsCategory) return null;

    // Sayfalama için postları böl
    const indexOfLastPost = currentPage * POSTS_PER_PAGE;
    const indexOfFirstPost = indexOfLastPost - POSTS_PER_PAGE;
    const currentPosts = postsCategory.data?.slice(indexOfFirstPost, indexOfLastPost) ? postsCategory.data?.slice(indexOfFirstPost, indexOfLastPost) : [];


    return currentPosts.map((post: PostWithAuthor) => (
      <Grid item key={post.id} xs={12} md={6} lg={4} xl={3} mb={3}>
        <DefaultProjectCard
          image={
            post.featuredImageUrl ||
            "https://images.unsplash.com/photo-1497294815431-9365093b7371?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80"
          }
          label={post.categoryName ? post.categoryName : "Kategori Adı Bulunamadı"}
          title={post.title}
          description={post.excerpt ? post.excerpt : ""}
          action={{
            route: `/post/${post.slug}`,
            label: "detay",
            color: "info",
            type: "internal",
          }}
          author={{
            avatar: post.authorAvatar,
            name: post.authorName,
          }}
          date={post.publishedAt ? formatDateFromDate(post.publishedAt) : formatDateFromDate(new Date())}
        />
      </Grid>
    ));
  };

  const renderPagination = () => {
    if (!postsCategory || !postsCategory.data) return null;

    const totalPages = Math.ceil(postsCategory.data.length / POSTS_PER_PAGE);

    // Eğer sadece 1 sayfa varsa pagination'ı gösterme
    if (totalPages <= 1) return null;

    return (
      <Box mt={4} display="flex" justifyContent="center">
        <MDPagination>
          {/* İlk sayfa butonu */}
          <MDPagination item
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}>
            <span>&laquo;</span>
          </MDPagination>

          {/* Önceki sayfa butonu */}
          <MDPagination item
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}>
            <span>&lsaquo;</span>
          </MDPagination>

          {/* Sayfa numaraları */}
          {[...Array(totalPages)].map((_, index) => (
            <MDPagination item
              key={index + 1}
              onClick={() => setCurrentPage(index + 1)}
              active={currentPage === index + 1}>
              {index + 1}
            </MDPagination>
          ))}

          {/* Sonraki sayfa butonu */}
          <MDPagination item
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}>
            <span>&rsaquo;</span>
          </MDPagination>

          {/* Son sayfa butonu */}
          <MDPagination item
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}>
            <span>&raquo;</span>
          </MDPagination>
        </MDPagination>
      </Box>
    );
  };

  return (
    <>
      <Box
        px={{
          xs: 1,
          sm: transparent ? 0 : 1,
          md: transparent ? 0 : 1,
          xl: transparent ? 0 : 1,
          lg: transparent ? 0 : 1,
        }}
        my={1}
        mx={2}
      >
        <Box mb={3}>
          <Grid container spacing={2}>
            {renderCategoryPosts()}
          </Grid>
          {renderPagination()}
        </Box>
      </Box>
    </>
  );
}


export default PostsCategoryLayout;