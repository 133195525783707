// Helper Functions
import pxToRem from "../../../../assets/theme/functions/pxToRem";

interface CardContentStyleOverrides {
  root: {
    marginTop: number;
    marginBottom: number;
    padding: string;
  };
}

interface CardContentStyles {
  styleOverrides: CardContentStyleOverrides;
}

const cardContent: CardContentStyles = {
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0,
      padding: `${pxToRem(8)} ${pxToRem(24)} ${pxToRem(24)}`,
    },
  },
};

export default cardContent;