//  base styles
import borders from "../../../../assets/theme/base/borders";

//  helper functions
import pxToRem from "../../../../assets/theme/functions/pxToRem";

const { borderRadius } = borders;

interface TableHeadStyleOverrides {
  root: {
    display: string;
    padding: string;
    borderRadius: string;
  };
}

interface TableHeadStyles {
  styleOverrides: TableHeadStyleOverrides;
}

const tableHead: TableHeadStyles = {
  styleOverrides: {
    root: {
      display: "block",
      padding: `${pxToRem(16)} ${pxToRem(16)} 0  ${pxToRem(16)}`,
      borderRadius: `${borderRadius.xl} ${borderRadius.xl} 0 0`,
    },
  },
};

export default tableHead;