//  base styles
import borders from "../../../../assets/theme/base/borders";
import colors from "../../../../assets/theme/base/colors";

interface StepConnectorStyleOverrides {
  root: {
    color: string;
    transition: string;
    "&.Mui-active": {
      color: string;
    };
    "&.Mui-completed": {
      color: string;
    };
  };
  alternativeLabel: {
    top: string;
    left: string;
    right: string;
  };
  line: {
    borderWidth: string;
    borderColor: string;
    opacity: number;
  };
}

const { white } = colors;
const { borderWidth } = borders;

const stepConnector: {
  styleOverrides: StepConnectorStyleOverrides;
} = {
  styleOverrides: {
    root: {
      color: "#9fc9ff",
      transition: "all 200ms linear",

      "&.Mui-active": {
        color: white.main,
      },

      "&.Mui-completed": {
        color: white.main,
      },
    },

    alternativeLabel: {
      top: "14%",
      left: "-50%",
      right: "50%",
    },

    line: {
      borderWidth: `${borderWidth[2]} !important`,
      borderColor: "currentColor",
      opacity: 0.5,
    },
  },
};

export default stepConnector;
