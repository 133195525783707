import React from "react";
import { Box } from "@mui/material";
import MDTypography from "../../../../components/MDTypography";
import MDButton from "../../../../components/MDButton";

interface EmailVerificationProps {
    error: string | null;
    showResendButton: boolean;
    verificationSent: boolean;
    onResendVerification: () => Promise<void>;
}

/**
 * Component to display email verification status and resend button
 */
const EmailVerification: React.FC<EmailVerificationProps> = ({
    error,
    showResendButton,
    verificationSent,
    onResendVerification,
}) => {
    return (
        <>
            {error && (
                <Box mt={2}>
                    <MDTypography
                        variant="caption"
                        color="error"
                        display="block"
                        textAlign="center"
                    >
                        {error}
                    </MDTypography>
                </Box>
            )}

            {showResendButton && (
                <Box mt={2}>
                    <MDButton
                        variant="text"
                        color="info"
                        fullWidth
                        onClick={onResendVerification}
                    >
                        Doğrulama e-postasını tekrar gönder
                    </MDButton>
                </Box>
            )}

            {verificationSent && (
                <Box mt={2}>
                    <MDTypography
                        variant="caption"
                        color="success"
                        display="block"
                        textAlign="center"
                    >
                        Doğrulama e-postası gönderildi. Lütfen e-posta kutunuzu kontrol edin.
                    </MDTypography>
                </Box>
            )}
        </>
    );
};

export default EmailVerification;