import React, { createContext, ReactNode, useContext, useMemo } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../lib/firebase";
import { useAuth } from "../AuthContext";
import { useLoading } from "./LoadingContext";

interface AccountContextType {
  userAccountInfo: any;
  isLoading: boolean;
  error: string | null;
}

const AccountContext = createContext<AccountContextType | null>(null);

const useFirestoreData = (userId: string, authLoading: boolean) => {
  const [state, setState] = React.useState({
    userAccountInfo: {} as any,
    isLoading: true,
    error: null as string | null,
  });

  const { startLoading, stopLoading } = useLoading();

  React.useEffect(() => {
    let mounted = true;

    // 'data' kategorisi ile yükleme başlat
    startLoading('account:fetch', 'data');

    if (authLoading) {
      setState((prev) => ({
        ...prev,
        isLoading: true,
        error: null,
      }));
      return () => {
        stopLoading('account:fetch');
      };
    }

    if (!userId) {
      setState((prev) => ({
        ...prev,
        userAccountInfo: null,
        isLoading: false,
        error: null,
      }));

      stopLoading('account:fetch');
      return () => { };
    }

    const userDocRef = doc(db, "users", userId);

    const unsubscribe = onSnapshot(
      userDocRef,
      (docSnapshot) => {
        if (!mounted) return;

        try {
          if (docSnapshot.exists()) {
            setState({
              userAccountInfo: {
                uid: docSnapshot.id,
                ...docSnapshot.data(),
              },
              isLoading: false,
              error: null,
            });
          } else {
            setState({
              userAccountInfo: null,
              isLoading: false,
              error: null,
            });
          }
        } catch (error: any) {
          console.error("Firestore error:", error);
          setState({
            userAccountInfo: null,
            isLoading: false,
            error: `Veri işlenirken hata oluştu: ${error.message}`,
          });
        } finally {
          stopLoading('account:fetch');
        }
      },
      (error) => {
        if (!mounted) return;
        console.error("Firestore error:", error);
        setState({
          userAccountInfo: null,
          isLoading: false,
          error: `Veritabanı bağlantı hatası: ${error.message}`,
        });

        stopLoading('account:fetch');
      }
    );

    return () => {
      mounted = false;
      unsubscribe();
      stopLoading('account:fetch');
    };
  }, [userId, authLoading, startLoading, stopLoading]);

  // Memoize the state to prevent unnecessary re-renders
  return useMemo(() => state, [state]);
};

export function AccountProvider({ children }: { children: ReactNode }) {
  const { user, loading: authLoading } = useAuth();
  const accountData = useFirestoreData(user?.uid || "", authLoading);

  // any yerine spesifik tipler kullanın
  const contextValue = React.useMemo(() => ({
    userAccountInfo: accountData.userAccountInfo,
    isLoading: accountData.isLoading,
    error: accountData.error
  }), [accountData]);

  return (
    <AccountContext.Provider value={contextValue}>
      {children}
    </AccountContext.Provider>
  );
}

export function useAccount() {
  const context = useContext(AccountContext);
  if (context === undefined) {
    throw new Error("useAccount must be used within an AccountProvider");
  }
  return context;
}