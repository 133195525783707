import { Comment } from "../types";

/**
 * Generates a random ID for anonymous users
 * @returns Random string ID
 */
export const generateRandomId = (): string => {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
};

/**
 * Calculates total number of replies for a comment recursively
 * @param comment Comment to count replies for
 * @returns Total number of replies
 */
export const getTotalReplies = (comment: Comment): number => {
    let count = 0;
    if (comment.replies) {
        count += comment.replies.length;
        comment.replies.forEach((reply) => {
            count += getTotalReplies(reply);
        });
    }
    return count;
};

/**
 * Organizes comments into a hierarchical structure
 * @param commentsArray Flat array of comments
 * @returns Array of top-level comments with nested replies
 */
export const organizeComments = (commentsArray: Comment[]): Comment[] => {
    const commentMap = new Map();
    const topLevelComments: Comment[] = [];

    // First, add all comments to the map
    commentsArray.forEach((comment) => {
        commentMap.set(comment.id, { ...comment, replies: [] });
    });

    // Organize replies
    commentsArray.forEach((comment) => {
        if (comment.parentId) {
            const parentComment = commentMap.get(comment.parentId);
            if (parentComment) {
                if (comment.isApproved) {
                    parentComment.replies?.push(commentMap.get(comment.id));
                }
            }
        } else {
            if (comment.isApproved) {
                topLevelComments.push(commentMap.get(comment.id));
            }
        }
    });

    return topLevelComments;
};

/**
 * Sort comments by date
 * @param commentsToSort Comments to sort
 * @param sortingOrder "newest" or "oldest"
 * @returns Sorted comments array
 */
export const sortComments = (commentsToSort: Comment[], sortingOrder: string): Comment[] => {
    // Create a copy of the comments to avoid mutating the original array
    return [...commentsToSort].sort((a, b) => {
        const dateA = a.createdAt?.toDate?.() || new Date(0);
        const dateB = b.createdAt?.toDate?.() || new Date(0);
        return sortingOrder === "newest"
            ? dateB.getTime() - dateA.getTime()
            : dateA.getTime() - dateB.getTime();
    }).map((comment) => {
        if (comment.replies?.length) {
            return {
                ...comment,
                replies: sortComments(comment.replies, sortingOrder),
            };
        }
        return comment;
    });
};

/**
 * Calculates total number of comments including all replies
 * @param comments Array of comments
 * @returns Total comment count
 */
export const getTotalCommentCount = (comments: Comment[]): number => {
    let total = comments.length;
    comments.forEach((comment) => {
        total += getTotalReplies(comment);
    });
    return total;
};