import { Alert, AlertTitle, Icon } from "@mui/material";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";
import img404 from "../../assets/images/error-404.png";

interface MDErrorDisplayProps {
    message?: string;
}

function MDErrorDisplay({ message = "Böyle bir sayfamız yok!" }: MDErrorDisplayProps) {
    return (
        <Alert severity="error" sx={{ m: 0.5, p: 2, textAlign: "center", borderRadius: "20px" }}>
            <AlertTitle fontSize={"4rem"}>Hata: 404</AlertTitle>
            {message}
            <MDTypography variant="body1">
                Sayfa kaldırılmış veya değiştirilmiş olabilir. Başka bir şey aramak ister misiniz?
            </MDTypography>

            <MDBox display="flex" alignItems="center" my={2}>
                <Icon fontSize="small">arrow_right_alt</Icon>
                <MDTypography variant="h5" fontWeight="medium" component="a" navlink="true" href="/">
                    Ana Sayfaya Git
                </MDTypography>
            </MDBox>

            <MDBox
                src={img404}
                alt="404"
                component="img"
                width="50%"
                height="50%"
                mx="auto"
                display="block" 
            />
        </Alert>
    );
}

export default MDErrorDisplay;