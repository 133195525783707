// @mui material components
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { CustomTheme } from "../../assets/theme/types/CustomTheme";

interface OwnerState {
  color: string;
}

// styled API için ikili bir yaklaşım kullanıyoruz - önce MUI'nin beklediği tipi, 
// sonra içeride CustomTheme tipimizi kullanıyoruz
export default styled(Box, {
  shouldForwardProp: (prop) => prop !== "ownerState",
})<{ ownerState: OwnerState }>(({ theme, ownerState }) => {
  // Theme'i CustomTheme'e dönüştürüyoruz
  const customTheme = theme as unknown as CustomTheme;
  const { palette, typography, borders, functions } = customTheme;
  const { color } = ownerState;

  const { white, gradients } = palette;
  const { fontSizeRegular, fontWeightMedium } = typography;
  const { borderRadius } = borders;
  const { pxToRem, linearGradient } = functions;

  // backgroundImage value
  const backgroundImageValue = gradients[color]
    ? linearGradient(gradients[color].main, gradients[color].state)
    : linearGradient(gradients.info.main, gradients.info.state);

  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: pxToRem(60),
    backgroundImage: backgroundImageValue,
    color: white.main,
    position: "relative",
    padding: pxToRem(16),
    marginBottom: pxToRem(16),
    borderRadius: borderRadius.md,
    fontSize: fontSizeRegular,
    fontWeight: fontWeightMedium,
  };
});