import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../lib/firebase";
import {
    signInWithEmailAndPassword,
    sendEmailVerification,
    sendPasswordResetEmail,
    GoogleAuthProvider,
    signInWithPopup,
    signInWithRedirect,
    UserCredential,
    AuthError,
} from "firebase/auth";
import { useCreateUserDocumentMutation, useUpdateEmailVerificationStatusMutation, useCheckUserExistsByEmailQuery } from "../services/userApiService";

export const useAuth = () => {
    const [emailToCheck, setEmailToCheck] = useState<string>("");
    const [error, setError] = useState<string | null>(null);
    const [verificationSent, setVerificationSent] = useState<boolean>(false);
    const [showResendButton, setShowResendButton] = useState<boolean>(false);
    const [resetSuccess, setResetSuccess] = useState<boolean>(false);
    const [resetError, setResetError] = useState<string | null>(null);
    const navigate = useNavigate();

    const [createUserDocument] = useCreateUserDocumentMutation();
    const [updateEmailVerificationStatus] = useUpdateEmailVerificationStatusMutation();
    const { refetch: checkUserByEmail } = useCheckUserExistsByEmailQuery(emailToCheck, {
        skip: !emailToCheck
    });

    // Regular email-password login
    const loginWithEmail = async (
        email: string,
        password: string
    ): Promise<boolean> => {
        setError(null);
        setVerificationSent(false);
        setShowResendButton(false);

        if (!email || !password) {
            setError("Lütfen e-posta ve şifrenizi girin.");
            return false;
        }

        try {
            // Attempt login
            const userCredential: UserCredential = await signInWithEmailAndPassword(
                auth,
                email,
                password
            );
            const user = userCredential.user;

            // Refresh user state
            await user.reload();

            // Check email verification
            if (!user.emailVerified) {
                setError(
                    "Giriş yapmadan önce e-posta adresinizi onaylamanız gerekmektedir. Lütfen e-posta kutunuzu kontrol ediniz."
                );
                setShowResendButton(true);
                return false;
            }

            // Update verification status in Firestore
            await updateEmailVerificationStatus({ userId: user.uid, isVerified: false }).unwrap();

            // Success, navigate to home
            return true;
        } catch (error) {
            console.error("Login error:", error);

            // Handle auth errors
            const authError = error as AuthError;
            switch (authError.code) {
                case "auth/user-not-found":
                    setError("Bu e-posta adresi ile kayıtlı kullanıcı bulunamadı.");
                    break;
                case "auth/wrong-password":
                    setError("Hatalı şifre girdiniz.");
                    break;
                case "auth/invalid-email":
                    setError("Geçersiz e-posta adresi.");
                    break;
                case "auth/too-many-requests":
                    setError(
                        "Çok fazla hatalı giriş denemesi yaptınız. Lütfen daha sonra tekrar deneyin."
                    );
                    break;
                case "auth/invalid-credential":
                    setError(
                        "Geçersiz giriş bilgileri. Lütfen e-posta ve şifrenizi kontrol edin."
                    );
                    break;
                case "auth/missing-password":
                    setError("Lütfen şifrenizi girin.");
                    break;
                default:
                    setError("Giriş yapılırken bir hata oluştu. Lütfen tekrar deneyin.");
            }
            return false;
        }
    };

    // Google sign-in
    const loginWithGoogle = async (): Promise<boolean> => {
        try {
            setError(null);

            const provider = new GoogleAuthProvider();
            provider.setCustomParameters({
                prompt: "select_account",
            });

            const result = await signInWithPopup(auth, provider);
            if (result.user) {
                await createUserDocument({ user: result.user }).unwrap();
                return true;
            }
            return false;
        } catch (error) {
            console.error("Google sign in hatası:", error);

            const authError = error as AuthError;
            switch (authError.code) {
                case "auth/popup-closed-by-user":
                    setError("Google giriş penceresi kapatıldı.");
                    break;
                case "auth/popup-blocked":
                    setError(
                        "Pop-up penceresi engellendi. Sayfayı yenileyin ve tekrar deneyin."
                    );
                    try {
                        await signInWithRedirect(auth, new GoogleAuthProvider());
                    } catch (redirectError) {
                        console.error("Redirect hatası:", redirectError);
                    }
                    break;
                case "auth/operation-not-allowed":
                    setError(
                        "Google ile giriş şu anda devre dışı. Lütfen daha sonra tekrar deneyin."
                    );
                    break;
                case "auth/network-request-failed":
                    setError("Ağ bağlantısı hatası. İnternet bağlantınızı kontrol edin.");
                    break;
                case "auth/unauthorized-domain":
                    setError("Bu domain Google Authentication için yetkilendirilmemiş.");
                    console.error(
                        "Firebase Console'da domain yetkilendirmesi yapılmamış!"
                    );
                    break;
                default:
                    const errorMessage =
                        authError?.message || "Bilinmeyen bir hata oluştu";
                    if (
                        typeof errorMessage === "string" &&
                        errorMessage.toLowerCase().includes("cross-origin")
                    ) {
                        setError(
                            "Tarayıcı güvenlik politikası nedeniyle işlem başarısız. Sayfayı yenileyin ve tekrar deneyin."
                        );
                    } else {
                        setError(
                            `Google ile giriş yapılırken bir hata oluştu: ${errorMessage}`
                        );
                    }
            }
            return false;
        }
    };

    // Resend verification email
    const resendVerificationEmail = async (
        email: string,
        password: string
    ): Promise<boolean> => {
        try {
            setError(null);
            setVerificationSent(false);

            // Re-login the user
            const userCredential: UserCredential = await signInWithEmailAndPassword(
                auth,
                email,
                password
            );

            // Send verification email
            await sendEmailVerification(userCredential.user, {
                url: window.location.origin + "/auth/giris-yap",
                handleCodeInApp: true,
            });

            setVerificationSent(true);
            setError(
                "Doğrulama e-postası gönderildi. Lütfen e-posta kutunuzu kontrol edin."
            );
            setShowResendButton(false);

            // Remove verification message after 5 seconds
            setTimeout(() => {
                setVerificationSent(false);
                setShowResendButton(true);
            }, 5000);

            return true;
        } catch (error) {
            console.error("Verification error:", error);
            setError(
                "Doğrulama e-postası gönderilirken bir hata oluştu. Lütfen tekrar deneyin."
            );
            return false;
        }
    };

    // Password reset
    const resetPassword = async (email: string): Promise<boolean> => {
        if (!email) {
            setResetError("Lütfen e-posta adresinizi girin.");
            return false;
        }

        try {
            // Check if user exists in Firestore
            setEmailToCheck(email);
            const result = await checkUserByEmail();
            const userExists = result.data;
            
            if (!userExists) {
                setResetError("Bu e-posta adresi ile kayıtlı kullanıcı bulunamadı.");
                setResetSuccess(false);
                return false;
            }

            await sendPasswordResetEmail(auth, email, {
                url: window.location.origin + "/auth/giris-yap",
            });

            setResetSuccess(true);
            setResetError(null);
            return true;
        } catch (error) {
            console.error("Password reset error:", error);
            setResetSuccess(false);

            const authError = error as AuthError;
            switch (authError.code) {
                case "auth/invalid-email":
                    setResetError("Geçersiz e-posta adresi.");
                    break;
                case "auth/missing-email":
                    setResetError("Lütfen e-posta adresinizi girin.");
                    break;
                case "auth/too-many-requests":
                    setResetError(
                        "Çok fazla deneme yaptınız. Lütfen daha sonra tekrar deneyin."
                    );
                    break;
                case "auth/network-request-failed":
                    setResetError(
                        "Ağ bağlantısı hatası. İnternet bağlantınızı kontrol edin."
                    );
                    break;
                default:
                    setResetError(
                        "Şifre sıfırlama işlemi sırasında bir hata oluştu. Lütfen daha sonra tekrar deneyin."
                    );
            }
            return false;
        }
    };

    return {
        error,
        setError,
        verificationSent,
        showResendButton,
        resetSuccess,
        resetError,
        loginWithEmail,
        loginWithGoogle,
        resendVerificationEmail,
        resetPassword,
        navigate,
    };
};