import React from "react";
import Box from "@mui/material/Box";
import MDTypography from "../../../components/MDTypography";
import MDSocialShare from "../../../components/MDSocialShare";
import { CodeContent } from "../styles/codeStyles";
import { Post } from "../types";

interface PostContentProps {
    post: Post;
    fullUrl: string;
    darkMode: boolean;
}

/**
 * Component for displaying the post content with code highlighting
 */
const PostContent: React.FC<PostContentProps> = ({ post, fullUrl, darkMode }) => {
    return (
        <Box>
            {/* Content with code highlighting */}
            <CodeContent className="code-content" darkMode={darkMode}>
                <MDTypography
                    variant="body1"
                    component="div"
                    p={0.3}
                    sx={{
                        "& img": {
                            maxWidth: "100%",
                            height: "auto",
                            borderRadius: "8px",
                        },
                        "& a": {
                            color: darkMode ? "#8ab4f8" : "#1a73e8",
                            textDecoration: "none",
                            "&:hover": {
                                textDecoration: "underline",
                            },
                        },
                        // Liste öğeleri için özel stil düzenlemeleri
                        "& ul, & ol": {
                            paddingInlineStart: "20px", // Liste içindentasyonunu azalt
                            margin: "16px 0",
                        },
                        "& li": {
                            marginBottom: "8px",
                            position: "relative",
                            paddingLeft: 0,
                            // Bullet pozisyonunu ve hizalamasını düzelt
                            "::marker": {
                                color: darkMode ? "#8ab4f8" : "#1a73e8",
                            }
                        },
                        // Başlıklar için düzenleme
                        "& h1, & h2, & h3, & h4, & h5, & h6": {
                            margin: "20px 0 16px 0", // Top, right, bottom, left
                            paddingLeft: 0,
                            paddingRight: 0,
                        },
                        // Paragraflar için düzenleme
                        "& p": {
                            margin: "16px 0",
                            paddingLeft: 0,
                            paddingRight: 0,
                        },
                        // Genel içerik kutusu için ayarlar
                        maxWidth: "100%",
                        overflow: "hidden",
                        wordWrap: "break-word",
                    }}
                    dangerouslySetInnerHTML={{ __html: post?.content || "" }}
                />
            </CodeContent>

            {/* Bottom share buttons */}
            <Box display="flex" alignItems="center" mx={1} mt={2}>
                <MDTypography variant="subtitle2" pt={0.8} gutterBottom>
                    Paylaş:
                </MDTypography>
                <MDSocialShare url={fullUrl} title={post.title} />
            </Box>
        </Box>
    );
};

export default PostContent;