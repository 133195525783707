/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/

import {
    createContext,
    useContext,
    useReducer,
    useMemo,
    useEffect,
    ReactNode,
    Dispatch
} from "react";

import { useThemeSettings } from "../hooks/useThemeSettings";

// Define types for our state
interface MaterialUIControllerState {
    miniSidenav: boolean;
    transparentSidenav: boolean;
    whiteSidenav: boolean;
    sidenavColor: string;
    transparentNavbar: boolean;
    fixedNavbar: boolean;
    openConfigurator: boolean;
    direction: "ltr" | "rtl";
    layout: string;
    darkMode: boolean;
}

// Define types for our actions
type MaterialUIControllerAction =
    | { type: "MINI_SIDENAV"; value: boolean }
    | { type: "TRANSPARENT_SIDENAV"; value: boolean }
    | { type: "WHITE_SIDENAV"; value: boolean }
    | { type: "SIDENAV_COLOR"; value: string }
    | { type: "TRANSPARENT_NAVBAR"; value: boolean }
    | { type: "FIXED_NAVBAR"; value: boolean }
    | { type: "OPEN_CONFIGURATOR"; value: boolean }
    | { type: "DIRECTION"; value: "ltr" | "rtl" }
    | { type: "LAYOUT"; value: string }
    | { type: "DARKMODE"; value: boolean };

// Type for the Theme Settings from custom hook
interface ThemeSettings extends Partial<MaterialUIControllerState> { }

// Type for the useThemeSettings hook return
interface ThemeSettingsHookReturn {
    themeSettings: ThemeSettings | null;
    loading: boolean;
    error: Error | null;
}

// Define the type for our context
type MaterialUIContextType = [
    MaterialUIControllerState,
    Dispatch<MaterialUIControllerAction>
];

// Main context
const MaterialUI = createContext<MaterialUIContextType | null>(null);

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "gkhnmr.com";

// Reducer
function reducer(
    state: MaterialUIControllerState,
    action: MaterialUIControllerAction
): MaterialUIControllerState {
    switch (action.type) {
        case "MINI_SIDENAV": {
            return { ...state, miniSidenav: action.value };
        }
        case "TRANSPARENT_SIDENAV": {
            return { ...state, transparentSidenav: action.value };
        }
        case "WHITE_SIDENAV": {
            return { ...state, whiteSidenav: action.value };
        }
        case "SIDENAV_COLOR": {
            return { ...state, sidenavColor: action.value };
        }
        case "TRANSPARENT_NAVBAR": {
            return { ...state, transparentNavbar: action.value };
        }
        case "FIXED_NAVBAR": {
            return { ...state, fixedNavbar: action.value };
        }
        case "OPEN_CONFIGURATOR": {
            return { ...state, openConfigurator: action.value };
        }
        case "DIRECTION": {
            return { ...state, direction: action.value };
        }
        case "LAYOUT": {
            return { ...state, layout: action.value };
        }
        case "DARKMODE": {
            return { ...state, darkMode: action.value };
        }
        default: {
            throw new Error(`Unhandled action type: ${(action as MaterialUIControllerAction).type}`);
        }
    }
}

// Context provider props
interface MaterialUIControllerProviderProps {
    children: ReactNode;
}

// Context provider
function MaterialUIControllerProvider({
    children,
}: MaterialUIControllerProviderProps): JSX.Element {
    const { themeSettings, loading, error }: ThemeSettingsHookReturn | any = useThemeSettings();

    const defaultInitialState: MaterialUIControllerState = {
        miniSidenav: false,
        transparentSidenav: false,
        whiteSidenav: false,
        sidenavColor: "info",
        transparentNavbar: true,
        fixedNavbar: true,
        openConfigurator: false,
        direction: "ltr",
        layout: "dashboard",
        darkMode: false,
    };

    const [controller, dispatch] = useReducer(reducer, defaultInitialState);

    // Apply settings from database
    useEffect(() => {
        // Skip while loading
        if (loading) {
            return;
        }

        // Log error in console
        if (error) {
            console.error("Error loading theme settings:", error);
            return;
        }

        if (themeSettings) {
            // Apply each setting with corresponding dispatch
            if (themeSettings.miniSidenav !== undefined) {
                setMiniSidenav(dispatch, themeSettings.miniSidenav);
            }
            if (themeSettings.transparentSidenav !== undefined) {
                setTransparentSidenav(dispatch, themeSettings.transparentSidenav);
            }
            if (themeSettings.whiteSidenav !== undefined) {
                setWhiteSidenav(dispatch, themeSettings.whiteSidenav);
            }
            if (themeSettings.sidenavColor !== undefined) {
                setSidenavColor(dispatch, themeSettings.sidenavColor);
            }
            if (themeSettings.transparentNavbar !== undefined) {
                setTransparentNavbar(dispatch, themeSettings.transparentNavbar);
            }
            if (themeSettings.fixedNavbar !== undefined) {
                setFixedNavbar(dispatch, themeSettings.fixedNavbar);
            }
            if (themeSettings.openConfigurator !== undefined) {
                setOpenConfigurator(dispatch, themeSettings.openConfigurator);
            }
            if (themeSettings.direction !== undefined) {
                setDirection(dispatch, themeSettings.direction as "ltr" | "rtl");
            }
            if (themeSettings.layout !== undefined) {
                setLayout(dispatch, themeSettings.layout);
            }
            if (themeSettings.darkMode !== undefined) {
                setDarkMode(dispatch, themeSettings.darkMode);
            }
        }
    }, [themeSettings, loading, error]);

    const value = useMemo <MaterialUIContextType> (
        () => [controller, dispatch],
        [controller, dispatch]
    );

    return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Custom hook for using context
function useMaterialUIController(): MaterialUIContextType {
    const context = useContext(MaterialUI);

    if (!context) {
        throw new Error(
            "useMaterialUIController should be used inside the MaterialUIControllerProvider."
        );
    }

    return context;
}

// Context module functions
const setMiniSidenav = (
    dispatch: Dispatch<MaterialUIControllerAction>,
    value: boolean
): void => dispatch({ type: "MINI_SIDENAV", value });

const setTransparentSidenav = (
    dispatch: Dispatch<MaterialUIControllerAction>,
    value: boolean
): void => dispatch({ type: "TRANSPARENT_SIDENAV", value });

const setWhiteSidenav = (
    dispatch: Dispatch<MaterialUIControllerAction>,
    value: boolean
): void => dispatch({ type: "WHITE_SIDENAV", value });

const setSidenavColor = (
    dispatch: Dispatch<MaterialUIControllerAction>,
    value: string
): void => dispatch({ type: "SIDENAV_COLOR", value });

const setTransparentNavbar = (
    dispatch: Dispatch<MaterialUIControllerAction>,
    value: boolean
): void => dispatch({ type: "TRANSPARENT_NAVBAR", value });

const setFixedNavbar = (
    dispatch: Dispatch<MaterialUIControllerAction>,
    value: boolean
): void => dispatch({ type: "FIXED_NAVBAR", value });

const setOpenConfigurator = (
    dispatch: Dispatch<MaterialUIControllerAction>,
    value: boolean
): void => dispatch({ type: "OPEN_CONFIGURATOR", value });

const setDirection = (
    dispatch: Dispatch<MaterialUIControllerAction>,
    value: "ltr" | "rtl"
): void => dispatch({ type: "DIRECTION", value });

const setLayout = (
    dispatch: Dispatch<MaterialUIControllerAction>,
    value: string
): void => dispatch({ type: "LAYOUT", value });

const setDarkMode = (
    dispatch: Dispatch<MaterialUIControllerAction>,
    value: boolean
): void => dispatch({ type: "DARKMODE", value });

export {
    MaterialUIControllerProvider,
    useMaterialUIController,
    setMiniSidenav,
    setTransparentSidenav,
    setWhiteSidenav,
    setSidenavColor,
    setTransparentNavbar,
    setFixedNavbar,
    setOpenConfigurator,
    setDirection,
    setLayout,
    setDarkMode,
};