// src/hooks/useFirestoreRoutes.tsx
import { useState, useEffect, useMemo } from "react";
import { collection, doc, getDoc, getDocs, QueryDocumentSnapshot } from "firebase/firestore";
import Icon from "@mui/material/Icon";
import { CategoryMenuItemProps, MenuItemProps, FirestoreRouteResult, CategoryRoute, ComponentMap } from "../types";

// Dynamic route components imports
import AnaSayfaLayout from "../layouts/AnaSayfaLayout";
import HakkimdaLayout from "../layouts/HakkimdaLayout";
import KaydolLayout from "../layouts/authenticationLayouts/kaydolLayout";
import PostDetailLayout from "../layouts/PostDetailLayout";
import PostsCategoryLayout from "../layouts/PostsCategoryLayout";
import StaticPage from "../layouts/StaticPage";
import IletisimLayout from "../layouts/IletisimLayout";
import LoginPage from "../layouts/authenticationLayouts/LoginPage";
import { db } from "../lib/firebase";
import { generateSlug } from "../utils";

// Static component mapping
const COMPONENT_MAP: ComponentMap = {
  AnaSayfaLayout: AnaSayfaLayout,
  HakkimdaLayout: HakkimdaLayout,
  LoginPage: LoginPage,
  KaydolLayout: KaydolLayout,
  PostDetailLayout: PostDetailLayout,
  PostsCategoryLayout: PostsCategoryLayout,
  StaticPage: StaticPage,
  IletisimLayout: IletisimLayout,
};

export const useFirestoreRoutes = (): FirestoreRouteResult => {
  const [menuData, setMenuData] = useState<Array<MenuItemProps | CategoryMenuItemProps> | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [categories, setCategories] = useState<Array<CategoryRoute> | null>(null);

  // Fetch categories
  useEffect(() => {
    let isSubscribed = true;
    const fetchCategoryRoutes = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "categories"));
        const categoriesRoutesData = querySnapshot.docs.map((doc: QueryDocumentSnapshot) => ({
          id: doc.id,
          createdAt: doc.data().createdAt,
          postCount: doc.data().postCount,
          slug: doc.data().slug,
          name: doc.data().name,
          description: doc.data().description,
          ...doc.data(),
        }));

        if (isSubscribed) {
          setCategories(categoriesRoutesData);
        }
      }
      catch (err: unknown) {
        if (err instanceof Error) {
          if (isSubscribed) {
            setError(err.message);
          }
        } else {
          if (isSubscribed) {
            setError("Rotalar alınırken bir hata oluştu");
          }
        }
      }
    };

    fetchCategoryRoutes();

    return () => {
      isSubscribed = false;
    };
  }, []);

  // Fetch menu routes
  useEffect(() => {
    let isSubscribed = true;

    const fetchMenuRoutes = async () => {
      try {
        const docRef = doc(db, "blog", "navbarMenu");
        const docSnap = await getDoc(docRef);

        if (!isSubscribed) return;

        if (docSnap.exists()) {
          const data = docSnap.data().menuList;
          // Type safety verification
          if (Array.isArray(data)) {
            setMenuData(
              data.sort((a, b) => a.name.localeCompare(b.name))
            );
          } else {
            setError("Navbar menu verisi doğru formatta değil");
          }
        } else {
          setError("Navbar menu bulunamadı");
        }
      } catch (err: unknown) {
        if (err instanceof Error) {
          if (isSubscribed) {
            setError(err.message);
          }
        } else {
          if (isSubscribed) {
            setError("Rotalar alınırken bir hata oluştu");
          }
        }
      } finally {
        if (isSubscribed) {
          setLoading(false);
        }
      }
    };

    fetchMenuRoutes();

    return () => {
      isSubscribed = false;
    };
  }, []);

  const isKategoriListProps = (menu: MenuItemProps | CategoryMenuItemProps): menu is CategoryMenuItemProps => {
    return 'categoryId' in menu;
  };

  // Create category routes
  const categoryRoutes = useMemo(() => {
    if (!categories) return [];

    return categories.map((category) => ({
      path: `/${category.slug}`,
      route: `/${category.slug}`, // Add both for consistency
      name: category.name,
      component: <PostsCategoryLayout categoryId={category.id} />,
      key: category.slug,
      type: "collapse" as "collapse",
      isNavBarItems: true,
      protected: false,
      icon: <Icon fontSize="small">category</Icon>, // Default icon for categories
    }));
  }, [categories]);

  // Create menu routes
  const routes = useMemo(() => {
    if (!menuData) return [];

    const menuRoutes = menuData.map((menu) => {
      const Component = COMPONENT_MAP[menu.component];
      return {
        ...menu,
        key: menu.key || generateSlug(menu.name),
        icon: <Icon fontSize="small">{menu.icon || "dashboard"}</Icon>,
        component: isKategoriListProps(menu) ? (
          <PostsCategoryLayout categoryId={menu.categoryId} />
        ) : (
          Component && <Component />
        ),
        // Ensure type is set properly for RouteType compatibility
        type: menu.type as "collapse" | "title" | "divider" || "collapse"
      };
    });

    // Add post detail route
    const postDetailRoute = {
      route: "/post/:slug", // Use route property as other routes use this property
      name: "Post Detail",
      component: <PostDetailLayout />,
      key: "post-detail",
      icon: <Icon fontSize="small">article</Icon>,
      type: "collapse" as "collapse",
      isNavBarItems: false,
    };

    return [...menuRoutes, postDetailRoute];
  }, [menuData]);

  return { routes, categoryRoutes, loading, error };
};