interface AppBarStyleOverrides {
  root: {
    boxShadow: string;
  };
}

interface AppBar {
  defaultProps: {
    color: string;
  };
  styleOverrides: AppBarStyleOverrides;
}

const appBar: AppBar = {
  defaultProps: {
    color: "transparent",
  },

  styleOverrides: {
    root: {
      boxShadow: "none",
    },
  },
};

export default appBar;