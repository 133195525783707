import React, { createContext, useState, useEffect, useContext, useCallback } from "react";
import { getAuth, onAuthStateChanged, signOut, User as FirebaseUser } from "firebase/auth";
import { doc, getDoc, getFirestore, updateDoc, setDoc } from "firebase/firestore";
import { useLoading } from "./context/LoadingContext";
import { User, AuthState, AuthContextType } from "./types";

// Context oluşturma
const AuthContext = createContext<AuthContextType | null>(null);

// Props interface
interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = React.memo(({ children }) => {
  // Tip güvenli state
  const [state, setState] = useState<AuthState>({
    user: null,
    loading: true,
    error: null,
  });

  const { startLoading, stopLoading } = useLoading();
  const auth = getAuth();
  const db = getFirestore();

  // Firestore'dan kullanıcı belgesini alıp güncelleyen fonksiyon
  const syncUserWithFirestore = useCallback(async (firebaseUser: FirebaseUser): Promise<User> => {
    try {
      const userRef = doc(db, "users", firebaseUser.uid);
      const userDoc = await getDoc(userRef);

      // Kullanıcı profili objesi
      const userData: User = {
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        displayName: firebaseUser.displayName,
        photoURL: firebaseUser.photoURL,
        emailVerified: firebaseUser.emailVerified,
        metadata: {
          lastSignInTime: firebaseUser.metadata.lastSignInTime || '',
          creationTime: firebaseUser.metadata.creationTime || '',
        }
      };

      // Firestore'da kullanıcı belgesi var mı?
      if (userDoc.exists()) {
        const firestoreData = userDoc.data();
        userData.role = firestoreData.role;

        // E-posta doğrulama durumu senkronizasyonu
        if (firebaseUser.emailVerified !== firestoreData.emailVerified) {
          await updateDoc(userRef, {
            emailVerified: firebaseUser.emailVerified,
            lastUpdated: new Date(),
          });
        }
      } else {
        // Kullanıcı Firestore'da yoksa oluştur
        await setDoc(userRef, {
          ...userData,
          role: 'user',
          isActive: true,
          createdAt: new Date(),
          lastUpdated: new Date(),
        });
      }
      return userData;
    } catch (error) {
      console.error("Firestore sync error:", error);
      // Hata durumunda da Firebase Auth verilerini döndür
      return {
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        displayName: firebaseUser.displayName,
        photoURL: firebaseUser.photoURL,
        emailVerified: firebaseUser.emailVerified,
        metadata: {
          lastSignInTime: firebaseUser.metadata.lastSignInTime || '',
          creationTime: firebaseUser.metadata.creationTime || '',
        },
      };
    }
  }, [db]);

  // Kullanıcı durumunu yenileme fonksiyonu
  const refreshUser = useCallback(async (): Promise<void> => {
    if (!auth.currentUser) return;

    try {
      startLoading('auth:refresh', 'auth');

      // Kullanıcı bilgilerini güncelle
      await auth.currentUser.reload();

      // Firestore ile senkronize et
      const userData = await syncUserWithFirestore(auth.currentUser);

      // State'i güncelle
      setState(prev => ({
        ...prev,
        user: userData,
        loading: false,
        error: null,
      }));
    } catch (error) {
      console.error("User refresh error:", error);
      // Kullanıcı bilgileri güncellenemezse mevcut durumu koru
    } finally {
      stopLoading('auth:refresh');
    }
  }, [auth, startLoading, stopLoading, syncUserWithFirestore]);

  // Auth state değişikliklerini izle
  useEffect(() => {
    let mounted = true;
    // 'auth' kategorisi ile yükleme başlat
    startLoading('auth:initialize', 'auth');

    const unsubscribe = onAuthStateChanged(
      auth,
      async (firebaseUser: FirebaseUser | null) => {
        if (!mounted) return;

        try {
          if (firebaseUser) {
            // Firestore ile senkronizasyon için ek yükleme
            startLoading('auth:emailVerification', 'auth');

            // Kullanıcı bilgilerini Firestore ile senkronize et
            const userData = await syncUserWithFirestore(firebaseUser);

            // State'i güncelle
            setState({
              user: userData,
              loading: false,
              error: null,
            });

            stopLoading('auth:emailVerification');
          } else {
            // Kullanıcı oturum açmamış
            setState({
              user: null,
              loading: false,
              error: null,
            });
          }
        } catch (error) {
          console.error("Auth processing error:", error);

          // Hataya rağmen kullanıcıya bilgi sağla (varsa)
          if (firebaseUser && mounted) {
            setState({
              user: {
                uid: firebaseUser.uid,
                email: firebaseUser.email,
                displayName: firebaseUser.displayName,
                photoURL: firebaseUser.photoURL,
                emailVerified: firebaseUser.emailVerified,
                metadata: {
                  lastSignInTime: firebaseUser.metadata.lastSignInTime || '',
                  creationTime: firebaseUser.metadata.creationTime || '',
                },
              },
              loading: false,
              error: error instanceof Error ? error.message : "Bilinmeyen bir hata oluştu",
            });
          } else {
            // Kullanıcı yoksa veya hata oluştuğunda
            setState({
              user: null,
              loading: false,
              error: error instanceof Error ? error.message : "Bilinmeyen bir hata oluştu",
            });
          }
        } finally {
          if (mounted) stopLoading('auth:initialize');
        }
      },
      (error: Error) => {
        if (!mounted) return;

        console.error("Auth error:", error);
        setState({
          user: null,
          loading: false,
          error: error.message,
        });

        // Hata durumunda yüklemeyi durdur
        if (mounted) stopLoading('auth:initialize');
      }
    );

    return () => {
      mounted = false;
      unsubscribe();
      // Cleanup yaparken loading'i durdur
      stopLoading('auth:initialize');
      stopLoading('auth:emailVerification');
    };
  }, [auth, db, startLoading, stopLoading, syncUserWithFirestore]);

  // Çıkış yapma fonksiyonu
  const logout = useCallback(async (): Promise<void> => {
    try {
      startLoading('auth:logout', 'auth');
      setState(prev => ({ ...prev, loading: true }));

      await signOut(auth);

      setState({
        user: null,
        loading: false,
        error: null,
      });
    } catch (error) {
      console.error("Logout error:", error);
      setState(prev => ({
        ...prev,
        loading: false,
        error: error instanceof Error ? error.message : "Çıkış yapılırken bilinmeyen bir hata oluştu",
      }));
      throw error;
    } finally {
      // İşlem sonrasında yüklemeyi durdur
      stopLoading('auth:logout');
    }
  }, [auth, setState, startLoading, stopLoading]);

  // useMemo ile context değerini optimize et
  const value = React.useMemo<AuthContextType>(() => ({
    user: state.user,
    loading: state.loading,
    error: state.error,
    logout,
    refreshUser,
  }), [state.user, state.loading, state.error, logout, refreshUser]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
});

// useAuth hook'u
export function useAuth(): AuthContextType {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}