import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
// Firebase importları
import {
    collection,
    deleteDoc,
    doc,
    getDocs,
    query,
    where,
    setDoc,
    updateDoc,
    serverTimestamp,
} from "firebase/firestore";
import { db } from "../lib/firebase";
import { StaticPage } from "../types";
import { convertToStaticPage, handleFirestoreError } from "../utils";

// Yeni sayfa oluşturma için gerekli veri tipi
type CreateStaticPageData = Omit<StaticPage, 'lastUpdated'>;

// Sayfa güncelleme için gerekli veri tipi
type UpdateStaticPageData = Partial<Omit<StaticPage, 'slug' | 'lastUpdated'>>;

export const pageApi = createApi({
    reducerPath: "pageApi",
    baseQuery: fakeBaseQuery(),
    tagTypes: ["StaticPages"],
    endpoints: (builder) => ({

        // Tüm statik sayfaları getirme
        createStaticPage: builder.mutation<{ pageData: CreateStaticPageData }, CreateStaticPageData>({
            async queryFn(pageData) {
                try {
                    const pageRef = doc(collection(db, "staticPages"), pageData.slug);
                    await setDoc(pageRef, {
                        ...pageData,
                        lastUpdated: new Date(),
                    });
                    return { data: { pageData } };
                } catch (error) {
                    return handleFirestoreError(error);
                }
            },
            invalidatesTags: ["StaticPages"],
        }),


        updateStaticPage: builder.mutation<{ pageData: UpdateStaticPageData }, { slug: string, pageData: UpdateStaticPageData }>({
            async queryFn({ slug, pageData }) {
                try {
                    const postRef = doc(db, "staticPages", slug);
                    await updateDoc(postRef, {
                        ...pageData,
                        updatedAt: serverTimestamp()
                    });
                    return { data: { pageData } };
                } catch (error) {
                    return handleFirestoreError(error);
                }
            },
            invalidatesTags: ["StaticPages"],
        }),

        getStaticPages: builder.query<StaticPage[], void>({
            async queryFn() {
                try {
                    const q = query(
                        collection(db, "staticPages"),
                        where("isPublished", "==", true)
                    );
                    const querySnapshot = await getDocs(q);
                    return { data: querySnapshot.docs.map(convertToStaticPage) };
                } catch (error) {
                    return handleFirestoreError(error);
                }
            },
            providesTags: ["StaticPages"],
        }),

        deleteStaticPage: builder.mutation<void, string>({
            async queryFn(slug) {
                try {
                    const pageRef = doc(db, "staticPages", slug);
                    await deleteDoc(pageRef);
                    return { data: undefined };
                } catch (error) {
                    return handleFirestoreError(error);
                }
            },
            invalidatesTags: ["StaticPages"],
        }),
    }),
});

export const {
    useCreateStaticPageMutation,
    useUpdateStaticPageMutation,
    useGetStaticPagesQuery,
    useDeleteStaticPageMutation
} = pageApi;