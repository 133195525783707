import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { User } from "firebase/auth";
import {
    doc,
    getDoc,
    setDoc,
    updateDoc,
    collection,
    query,
    where,
    getDocs,
    DocumentReference,
    DocumentSnapshot,
    QuerySnapshot,
} from "firebase/firestore";
import { db } from "../lib/firebase";
import { UserInfo } from "../types";

// RTK Query API tanımlaması
export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: fakeBaseQuery(),
    tagTypes: ['User'],
    endpoints: (builder) => ({
        // Kullanıcı belgesi oluşturma veya var olanı getirme
        createUserDocument: builder.mutation<void, { user: User; additionalData?: Record<string, any> }>({
            queryFn: async ({ user, additionalData = {} }) => {
                try {
                    if (!user) return { data: undefined };

                    const userRef: DocumentReference = doc(db, "users", user.uid);
                    const snapshot: DocumentSnapshot = await getDoc(userRef);

                    if (!snapshot.exists()) {
                        const { email, displayName, photoURL } = user;
                        const names: string[] = displayName ? displayName.split(" ") : ["", ""];
                        const createdAt: number = Date.now();

                        const userInfo: UserInfo = {
                            username: displayName || "",
                            email: email || "",
                            fullName: names[0] || "" + names.slice(1).join(" ") || "",
                            bio: "",
                            avatarUrl: photoURL || "",
                            role: "user",
                            isActive: false,
                            createdAt: createdAt,
                            updatedAt: createdAt,
                            birthday: "",
                            emailVerified: true,
                            ...additionalData,
                        };

                        await setDoc(userRef, userInfo);
                    }

                    return { data: undefined };
                } catch (error) {
                    console.error("Error creating user document", error);
                    return { error: { message: 'Failed to create user document' } };
                }
            },
            invalidatesTags: ['User'],
        }),

        // Kullanıcının e-posta doğrulama durumunu güncelleme
        updateEmailVerificationStatus: builder.mutation<void, { userId: string; isVerified: boolean }>({
            queryFn: async ({ userId, isVerified }) => {
                try {
                    const userRef = doc(db, "users", userId);
                    const userDoc = await getDoc(userRef);

                    if (userDoc.exists()) {
                        await updateDoc(userRef, {
                            emailVerified: isVerified,
                        });
                    }

                    return { data: undefined };
                } catch (error) {
                    console.error("Firestore güncelleme hatası:", error);
                    return { error: { message: 'Failed to update email verification status' } };
                }
            },
            invalidatesTags: ['User'],
        }),

        // Belirli bir e-posta ile kullanıcının var olup olmadığını kontrol etme
        checkUserExistsByEmail: builder.query<boolean, string>({
            queryFn: async (email) => {
                try {
                    const usersRef = collection(db, "users");
                    const q = query(usersRef, where("email", "==", email));
                    const querySnapshot: QuerySnapshot = await getDocs(q);

                    return { data: !querySnapshot.empty };
                } catch (error) {
                    console.error("Error checking user:", error);
                    return { error: { message: 'Failed to check user by email' } };
                }
            },
        }),

        // Kullanıcı bilgilerini ID ile getirme (Ek fonksiyon)
        getUserById: builder.query<UserInfo | null, string>({
            queryFn: async (userId) => {
                try {
                    if (!userId) return { data: null };

                    const userRef = doc(db, "users", userId);
                    const userDoc = await getDoc(userRef);

                    if (userDoc.exists()) {
                        return { data: userDoc.data() as UserInfo };
                    } else {
                        return { data: null };
                    }
                } catch (error) {
                    console.error("Error fetching user:", error);
                    return { error: { message: 'Failed to get user by ID' } };
                }
            },
            providesTags: (_result, _error, id) => [{ type: 'User', id }],
        }),

        // Kullanıcı belgesini güncelleme (Ek fonksiyon)
        updateUserProfile: builder.mutation<void, { userId: string; userData: Partial<UserInfo> }>({
            queryFn: async ({ userId, userData }) => {
                try {
                    const userRef = doc(db, "users", userId);
                    const userDoc = await getDoc(userRef);

                    if (userDoc.exists()) {
                        await updateDoc(userRef, {
                            ...userData,
                            updatedAt: Date.now(),
                        });
                    }

                    return { data: undefined };
                } catch (error) {
                    console.error("Error updating user profile:", error);
                    return { error: { message: 'Failed to update user profile' } };
                }
            },
            invalidatesTags: (_result, _error, { userId }) => [{ type: 'User', id: userId }],
        }),
    }),
});

// Export hooks for usage in components
export const {
    useCreateUserDocumentMutation,
    useUpdateEmailVerificationStatusMutation,
    useCheckUserExistsByEmailQuery,
    useGetUserByIdQuery,
    useUpdateUserProfileMutation,
} = userApi;