import React, { useState } from "react";
import Box from "@mui/material/Box";
import { CommentData } from "../types";
import MDTypography from "../../../components/MDTypography";
import MDAvatar from "../../../components/MDAvatar";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";

interface CommentItemProps {
  comment: CommentData;
  onReply: (parentCommentId: string, content: string) => Promise<void>;
  darkMode: boolean;
}

/**
 * Component for rendering an individual comment with reply functionality
 */
const CommentItem: React.FC<CommentItemProps> = ({ comment, onReply, darkMode }) => {
  const [replyContent, setReplyContent] = useState<string>("");
  const [replyOpen, setReplyOpen] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleReply = async () => {
    if (!replyContent.trim()) return;

    setSubmitting(true);
    try {
      await onReply(comment.id, replyContent);
      setReplyContent("");
      setReplyOpen(false);
    } catch (error) {
      console.error("Reply submission error:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const formatDate = (timestamp: any) => {
    if (!timestamp) return "Unknown date";

    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return date.toLocaleDateString("tr-TR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <Box
      sx={{
        mb: 2,
        p: 2,
        borderRadius: "8px",
        backgroundColor: darkMode ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.02)",
        border: darkMode ? "1px solid rgba(255, 255, 255, 0.1)" : "1px solid rgba(0, 0, 0, 0.1)",
      }}
    >
      {/* Comment header */}
      <Box display="flex" alignItems="center" mb={1}>
        <MDAvatar
          src={comment.authorAvatarUrl || ""}
          alt={comment.authorName}
          size="sm"
          sx={{ mr: 1 }}
        />
        <Box>
          <MDTypography variant="button" fontWeight="medium">
            {comment.authorName}
          </MDTypography>
          <MDTypography variant="caption" display="block" color="text">
            {formatDate(comment.createdAt)}
          </MDTypography>
        </Box>
      </Box>

      {/* Comment content */}
      <MDTypography variant="body2" component="div" sx={{ my: 1, whiteSpace: "pre-wrap" }}>
        {comment.content}
      </MDTypography>

      {/* Reply button */}
      <Box display="flex" justifyContent="flex-end">
        <MDButton
          variant="text"
          color="info"
          size="small"
          onClick={() => setReplyOpen(!replyOpen)}
        >
          {replyOpen ? "İptal" : "Yanıtla"}
        </MDButton>
      </Box>

      {/* Reply form */}
      {replyOpen && (
        <Box mt={2}>
          <MDInput
            fullWidth
            multiline
            rows={2}
            value={replyContent}
            onChange={(e) => setReplyContent(e.target.value)}
            placeholder="Yanıtınızı yazın..."
            sx={{ mb: 1 }}
          />
          <Box display="flex" justifyContent="flex-end">
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              onClick={handleReply}
              disabled={submitting || !replyContent.trim()}
            >
              {submitting ? "Gönderiliyor..." : "Yanıtla"}
            </MDButton>
          </Box>
        </Box>
      )}

      {/* Replies */}
      {comment.replies && comment.replies.length > 0 && (
        <Box mt={2} ml={4}>
          {comment.replies.map((reply) => (
            <CommentItem
              key={reply.id}
              comment={reply}
              onReply={onReply}
              darkMode={darkMode}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CommentItem;