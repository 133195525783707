import React from "react";
import Box from "@mui/material/Box";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";

interface CommentFormProps {
    newComment: string;
    setNewComment: (comment: string) => void;
    handleAddComment: () => Promise<void>;
    commenting: boolean;
}

/**
 * Component for adding a new comment
 */
const CommentForm: React.FC<CommentFormProps> = ({
    newComment,
    setNewComment,
    handleAddComment,
    commenting,
}) => {
    return (
        <Box mb={3}>
            <MDTypography variant="subtitle2" mb={1}>
                Bir yorum bırakın
            </MDTypography>
            <MDInput
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                placeholder="Yorumunuzu yazın..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                sx={{ mb: 1 }}
            />
            <MDButton
                variant="gradient"
                color="info"
                onClick={handleAddComment}
                disabled={commenting || !newComment.trim()}
            >
                {commenting ? "Gönderiliyor..." : "Yorum Yap"}
            </MDButton>
        </Box>
    );
};

export default CommentForm;