// Base Styles
import colors from "../../../../assets/theme/base/colors";

const { transparent } = colors;

interface TextFieldStyleOverrides {
  root: React.CSSProperties;
}

interface TextFieldStyles {
  styleOverrides: TextFieldStyleOverrides;
}

const textField: TextFieldStyles = {
  styleOverrides: {
    root: {
      backgroundColor: transparent.main,
    },
  },
};

export default textField;