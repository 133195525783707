import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { useParams, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import { useMaterialUIController } from "../../context";
import { useAccount } from "../../context/AccountContext";
import { PostDetailParams, UserAccountInfo } from "./types";

import MDLoadingSkeleton from "../../components/MDLoadingSkeleton";
import MDErrorDisplay from "../../components/MDErrorDisplay";

// Custom hooks
import { usePost } from "./hooks/usePost";
import { useComments } from "./hooks/useComments";
import { useCodeHighlighting } from "./hooks/useCodeHighlighting";

// Components
import PostHeader from "./components/PostHeader";
import PostContent from "./components/PostContent";
import CommentsSection from "./components/CommentsSection";

// Initialize highlight.js
import { initializeHighlightJs } from "./utils/codeHighlighting";
initializeHighlightJs();

/**
 * Main component for post detail page
 */
const PostDetailLayout: React.FC = () => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const { slug } = useParams<PostDetailParams>();
    const location = useLocation();
    const { userAccountInfo } = useAccount() as unknown as { userAccountInfo: UserAccountInfo };

    // Full URL for sharing
    const fullUrl = `${window.location.origin}${location.pathname}`;

    // Fetch post data
    const { post, author, loading, error } = usePost(slug, userAccountInfo);

    // Comments functionality
    const {
        newComment,
        setNewComment,
        commenting,
        sortOrder,
        sortedComments,
        handleSortOrderChange,
        handleAddComment,
        handleReply,
    } = useComments(post?.id, userAccountInfo);

    // Code highlighting functionality
    useCodeHighlighting(post, darkMode);

    // Loading state
    if (loading || !post) {
        return (
            <Box p={2}>
                {loading ? <MDLoadingSkeleton /> : <MDErrorDisplay message={error || "Bilinmeyen hata"} />}
            </Box>
        );
    }

    // Error state
    if (error) {
        return (
            <Box p={2}>
                <MDErrorDisplay message={error} />
            </Box>
        );
    }

    return (
        <>
            {/* SEO and meta tags */}
            {post && (
                <Helmet>
                    {/* Basic Meta Tags */}
                    <title>{post.title}</title>
                    <meta name="description" content={post.excerpt} />

                    {/* Open Graph Meta Tags */}
                    <meta property="og:title" content={post.title} />
                    <meta property="og:description" content={post.excerpt} />
                    <meta property="og:type" content="article" />
                    <meta property="og:url" content={fullUrl} />
                    {post.featuredImageUrl && (
                        <meta property="og:image" content={post.featuredImageUrl} />
                    )}

                    {/* Article Specific Meta Tags */}
                    <meta
                        property="article:published_time"
                        content={post.publishedAt?.toDate?.().toISOString()}
                    />
                    {author && <meta property="article:author" content={author.fullName} />}

                    {/* Twitter Card Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={post.title} />
                    <meta name="twitter:description" content={post.excerpt} />
                    {post.featuredImageUrl && (
                        <meta name="twitter:image" content={post.featuredImageUrl} />
                    )}
                </Helmet>
            )}

            <Box px={1} my={1} mx={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            {/* Featured Image */}
                            {post.featuredImageUrl && (
                                <Box
                                    component="img"
                                    src={post.featuredImageUrl}
                                    alt={post.title}
                                    sx={{
                                        width: "100%",
                                        height: "400px",
                                        objectFit: "cover",
                                        borderRadius: "5px 5px 0 0",
                                    }}
                                />
                            )}

                            {/* Post Header Section */}
                            <CardContent>
                                <PostHeader post={post} author={author} fullUrl={fullUrl} />
                                <Divider sx={{ my: 2 }} />

                                {/* Post Content Section */}
                                <PostContent post={post} fullUrl={fullUrl} darkMode={darkMode} />
                                <Divider sx={{ my: 2 }} />
                            </CardContent>

                            {/* Comments Section */}
                            <CardContent>
                                <CommentsSection
                                    comments={sortedComments}
                                    sortOrder={sortOrder}
                                    onSortChange={handleSortOrderChange}
                                    newComment={newComment}
                                    setNewComment={setNewComment}
                                    handleAddComment={handleAddComment}
                                    handleReply={handleReply}
                                    commenting={commenting}
                                    darkMode={darkMode}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default PostDetailLayout;