import { useState, useEffect, useCallback } from "react";
import { Post } from "../types";
import { highlightCodeBlocks, initializeHighlightJs } from "../utils/codeHighlighting";

/**
 * Custom hook for handling code highlighting
 */
export const useCodeHighlighting = (post: Post | null, darkMode: boolean) => {
    const [isHighlighted, setIsHighlighted] = useState(false);

    // Initialize highlight.js
    useEffect(() => {
        initializeHighlightJs();
    }, []);

    // Highlight code blocks
    const highlightCode = useCallback(() => {
        // Skip if already highlighted or no content
        if (isHighlighted || !post?.content) return false;

        // Apply highlighting
        const highlighted = highlightCodeBlocks(darkMode);

        if (highlighted) {
            setIsHighlighted(true);
        }

        return highlighted;
    }, [darkMode, post?.content, isHighlighted]);

    // Reset highlight status when content or darkMode changes
    useEffect(() => {
        if (post?.content) {
            setIsHighlighted(false);
        }
    }, [post?.content, darkMode]);

    // Apply highlighting after content has loaded
    useEffect(() => {
        if (post?.content) {
            const timer = setTimeout(() => {
                highlightCode();
            }, 200);
            return () => clearTimeout(timer);
        }
    }, [post?.content, highlightCode]);

    // Set up MutationObserver to watch for content changes
    useEffect(() => {
        if (!post?.content) return;

        // Create a flag for the observer
        let observerActive = true;

        // Create a throttled function for highlighting
        let isProcessing = false;
        const throttledHighlight = () => {
            if (isProcessing || !observerActive) return;

            isProcessing = true;
            requestAnimationFrame(() => {
                highlightCode();
                // Add a small delay before allowing another highlight
                setTimeout(() => {
                    isProcessing = false;
                }, 200);
            });
        };

        // Create the observer with optimized settings
        const observer = new MutationObserver((mutations) => {
            // Only trigger if we're not already processing
            if (!isProcessing && observerActive) {
                // Check if any mutations are relevant
                const hasRelevantChanges = mutations.some(mutation =>
                    mutation.type === 'childList' && mutation.addedNodes.length > 0
                );

                if (hasRelevantChanges) {
                    throttledHighlight();
                }
            }
        });

        // Apply observer only to the content container
        const contentContainer = document.querySelector(".code-content");
        if (contentContainer) {
            observer.observe(contentContainer, {
                childList: true,
                subtree: true,
                characterData: false,
            });
        }

        // Run initial highlight once content is ready
        throttledHighlight();

        // Cleanup function
        return () => {
            observerActive = false;
            observer.disconnect();
        };
    }, [post?.content, highlightCode]);

    // Listen for errors during highlighting
    useEffect(() => {
        const handleError = (error: ErrorEvent): void => {
            console.error("Highlighting error:", error);
        };

        window.addEventListener("error", handleError);
        return () => window.removeEventListener("error", handleError);
    }, []);

    return {
        isHighlighted,
        highlightCode,
    };
};