interface StyleOverrides {
  padding: {
    paddingTop: number;
    paddingBottom: number;
  };
}

interface ListConfig {
  styleOverrides: StyleOverrides;
}

const list: ListConfig = {
  styleOverrides: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
};

export default list;