//  base styles
import typography from "../../../../assets/theme/base/typography";
import colors from "../../../../assets/theme/base/colors";

//  helper functions
// import pxToRem from "assets/theme/functions/pxToRem";

const { size } = typography;
const { text } = colors;

interface DialogContentTextStyleOverrides {
  root: {
    fontSize: string;
    color: string;
  };
}

interface DialogContentTextStyles {
  styleOverrides: DialogContentTextStyleOverrides;
}

const dialogContentText: DialogContentTextStyles = {
  styleOverrides: {
    root: {
      fontSize: size.md,
      color: text.main,
    },
  },
};

export default dialogContentText;