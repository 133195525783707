import { forwardRef } from "react";
// In your MDInput component file
import { SxProps, Theme } from '@mui/material/styles';

// Custom styles for MDInput
import MDInputRoot from "../../components/MDInput/MDInputRoot";

interface MDInputProps {
  error?: boolean;
  success?: boolean;
  disabled?: boolean;
  type?: string;
  label?: string;
  fullWidth?: boolean;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
  variant?: "standard" | "outlined" | "filled";
  InputLabelProps?: { shrink?: boolean };
  sx?: SxProps<Theme>;
}

const MDInput = forwardRef<HTMLInputElement, MDInputProps>(({
  error = false,
  success = false,
  disabled = false,
  ...rest }, ref) => {
  return (
    <MDInputRoot {...rest} ref={ref} ownerState={{ error, success, disabled }} />
  )
});

export default MDInput;