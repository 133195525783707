// src/services/postService.ts
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { db } from "../lib/firebase";
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  serverTimestamp,
  limit,
  DocumentSnapshot,
  DocumentData,
} from "firebase/firestore";

import { generateSlug, convertFirestoreTimestamps, handleFirestoreError } from "../utils";

// Type tanımlamaları
export interface FirestorePostData {
  title: string;
  content: string;
  slug: string;
  status: 'draft' | 'published';
  authorId: string;
  isFeatured?: boolean;
  featuredImageUrl?: string;
  excerpt?: string;
  createdAt: Date;
  updatedAt: Date;
  publishedAt?: Date;
  categoryId?: string;
  categoryName?: string;
  authorName?: string;
  authorAvatar?: string;
}

export interface Post extends FirestorePostData {
  id: string;
}

export interface Author {
  fullName: string;
  avatarUrl?: string;
}

export interface PostWithAuthor extends Post {
  author: Author | null;
  authorName?: string;
  authorAvatar?: string;
}

export type PostWithId = Post;

export type PostCreate = Omit<FirestorePostData, "createdAt" | "updatedAt"> & {
  createdAt: any; // serverTimestamp() için
  updatedAt: any; // serverTimestamp() için
};

const DEFAULT_AVATAR = "https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80";
const DEFAULT_FEATURED_IMAGE = "https://via.placeholder.com/1200x800";

// Post verilerini hazırlayan yardımcı fonksiyon
async function preparePostData(postDoc: DocumentSnapshot<DocumentData>): Promise<PostWithAuthor> {
  const rawData = postDoc.data() as FirestorePostData | undefined;
  if (!rawData) {
    throw new Error(`Post data not found for ID: ${postDoc.id}`);
  }

  try {
    const authorRef = doc(db, "users", rawData.authorId);
    const authorSnapshot = await getDoc(authorRef);
    const authorData = authorSnapshot.data() as Author | undefined;

    // Önce Post nesnesini oluşturuyoruz
    const post: Post = {
      id: postDoc.id, // id'yi açıkça atıyoruz
      ...rawData      // diğer alanları spread ediyoruz
    };

    // Sonra PostWithAuthor nesnesini oluşturuyoruz
    const postWithAuthor: PostWithAuthor = {
      ...post,
      author: authorData ? {
        ...authorData,
        avatarUrl: authorData.avatarUrl || DEFAULT_AVATAR
      } : null,
      authorName: authorData?.fullName || "Anonim",
      authorAvatar: authorData?.avatarUrl || DEFAULT_AVATAR
    };

    return convertFirestoreTimestamps(postWithAuthor);
  } catch (error) {
    console.warn(`Author data fetch failed for post ${postDoc.id}:`, error);

    // Hata durumunda da aynı yapıyı koruyoruz
    const post: PostWithAuthor = {
      id: postDoc.id,
      ...rawData,
      author: null,
      authorName: "Anonim",
      authorAvatar: DEFAULT_AVATAR
    };
    return convertFirestoreTimestamps(post);
  }
}

// Redux Toolkit Query API
export const postApi = createApi({
  reducerPath: "postApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["Posts", "FeaturedPosts"],
  endpoints: (builder) => ({
    // Tüm postları getirme
    getPosts: builder.query<PostWithAuthor[], void>({
      async queryFn() {
        try {
          const q = query(
            collection(db, "posts"),
            where("status", "==", "published"),
            orderBy("createdAt", "desc")
          );

          const snapshot = await getDocs(q);
          const posts = await Promise.all(snapshot.docs.map(preparePostData));
          return { data: posts };
        } catch (error) {
          return handleFirestoreError(error);
        }
      },
      providesTags: ["Posts"]
    }),

    // Slug ile post getirme
    getPostBySlug: builder.query<PostWithAuthor | null, string>({
      async queryFn(slug) {
        try {
          const q = query(
            collection(db, "posts"),
            where("slug", "==", slug),
            limit(1)
          );

          const snapshot = await getDocs(q);
          if (snapshot.empty) return { data: null };

          const post = await preparePostData(snapshot.docs[0]);
          return { data: post };
        } catch (error) {
          return handleFirestoreError(error);
        }
      },
      providesTags: (result, error, slug) => [{ type: "Posts", id: slug }]
    }),

    // Öne çıkan postları getirme
    getFeaturedPosts: builder.query<PostWithAuthor[], void>({
      async queryFn() {
        try {
          const q = query(
            collection(db, "posts"),
            where("status", "==", "published"),
            where("isFeatured", "==", true),
            orderBy("publishedAt", "desc"),
            limit(4)
          );

          const snapshot = await getDocs(q);
          const posts = await Promise.all(
            snapshot.docs.map(async (doc) => {
              const post = await preparePostData(doc);
              return {
                ...post,
                featuredImageUrl: post.featuredImageUrl || DEFAULT_FEATURED_IMAGE,
                excerpt: post.excerpt || ""
              };
            })
          );

          return { data: posts.filter(Boolean) };
        } catch (error) {
          return handleFirestoreError(error);
        }
      },
      providesTags: ["FeaturedPosts"]
    }),

    // Kategori ID'ye göre post getirme
    getPostsByCategory: builder.query<PostWithAuthor[], string>({
      async queryFn(categoryId) {
        try {
          const q = query(
            collection(db, "posts"),
            where("status", "==", "published"),
            where("categoryId", "==", categoryId),
            orderBy("createdAt", "desc")
          );

          const snapshot = await getDocs(q);
          const posts = await Promise.all(snapshot.docs.map(preparePostData));
          return { data: posts };
        } catch (error) {
          return handleFirestoreError(error);
        }
      },
      providesTags: (result, error, categoryId) => [{ type: "Posts", id: `category-${categoryId}` }]
    }),

    // Post oluşturma
    createPost: builder.mutation<{ id: string }, { postData: Partial<Post>, userId: string }>({
      async queryFn({ postData, userId }) {
        try {
          const post = {
            ...postData,
            authorId: userId,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            slug: generateSlug(postData.title || "")
          };

          const docRef = await addDoc(collection(db, "posts"), post);
          return { data: { id: docRef.id } };
        } catch (error) {
          return handleFirestoreError(error);
        }
      },
      invalidatesTags: ["Posts", "FeaturedPosts"]
    }),

    // Post güncelleme
    updatePost: builder.mutation<void, { postId: string, postData: Partial<Post> }>({
      async queryFn({ postId, postData }) {
        try {
          const postRef = doc(db, "posts", postId);
          await updateDoc(postRef, {
            ...postData,
            updatedAt: serverTimestamp()
          });
          return { data: undefined };
        } catch (error) {
          return handleFirestoreError(error);
        }
      },
      invalidatesTags: (result, error, { postId }) => [
        "Posts",
        "FeaturedPosts",
        { type: "Posts", id: postId }
      ]
    }),

    // Post silme
    deletePost: builder.mutation<void, string>({
      async queryFn(postId) {
        try {
          const postRef = doc(db, "posts", postId);
          await deleteDoc(postRef);
          return { data: undefined };
        } catch (error) {
          return handleFirestoreError(error);
        }
      },
      invalidatesTags: ["Posts", "FeaturedPosts"]
    })
  })
});

// Export hooks
export const {
  useGetPostsQuery,
  useGetPostBySlugQuery,
  useGetFeaturedPostsQuery,
  useGetPostsByCategoryQuery,
  useCreatePostMutation,
  useUpdatePostMutation,
  useDeletePostMutation
} = postApi;
