import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import PageLayout from "../../../../examples/LayoutContainers/PageLayout";
import Footer from "../../../../layouts/authenticationLayouts/components/Footer";
import MDBox from "../../../../components/MDBox";

// Define the type for the theme functions and palette
interface ThemeTypes {
  functions: {
    linearGradient: (color1: string, color2: string) => string;
    rgba: (color: string, opacity: number) => string;
  };
  palette: {
    gradients: {
      dark: {
        main: string;
        state: string;
      };
    };
  };
}


// Define the type for the component props
interface BasicLayoutProps {
  image: string;
  children: React.ReactNode;
}

function BasicLayout({ image, children }: BasicLayoutProps): JSX.Element {
  return (
    <PageLayout>
      <Box position="relative" width="100%" height="100vh" overflow="hidden">
        <MDBox
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }: ThemeTypes) =>
              image &&
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />

        <Box
          position="relative"
          px={1}
          width="100%"
          height="calc(100vh - 60px)"
          mx="auto"
        >
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
          >
            <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
              {children}
            </Grid>
          </Grid>
        </Box>

        <Box position="absolute" bottom={0} width="100%" height="60px">
          <Footer light />
        </Box>
      </Box>
    </PageLayout>
  );
}

export default BasicLayout;
